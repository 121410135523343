import React from "react";
import ModalBase from "@/Components/Screens/Modals/ModalBase";
import MediaDisplay from "@/Components/Components/MediaDisplay";
import { useGlobal } from "@/Components/Context/globalContext";
import { Text } from "react-native";

const ModalPremis = ({ setModalVisible, itinerari, poi }) => {
  const { __ } = useGlobal();

  return (
    <ModalBase setModalVisible={setModalVisible}>
      {__(poi, "popup_titol") && (
        <Text
          style={{
            fontFamily: "Roboto",
            fontWeight: "700",
            fontSize: 20,
            textAlign: "center",
            marginTop: 20,
          }}
        >
          {__(poi, "popup_titol")}
        </Text>
      )}
      <Text>{__(poi, "popup_text")}</Text>

      <MediaDisplay source={__(poi, "popup_multimedia")?.url} />
    </ModalBase>
  );
};

export default ModalPremis;
