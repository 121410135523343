import React from "react";
import { Image, Modal, Pressable, Text, View } from "react-native";
import { complicesStyles } from "@/Components/Screens/Recursos/RecursosStyle";
import { useGlobal } from "@/Components/Context/globalContext";

const CompliceModal = ({
  modalCompliceVisible,
  setModalCompliceVisible,
  item,
}) => {
  const { imatgeRecurs, __ } = useGlobal();

  if (!item) {
    return;
  }

  return (
    <Modal transparent={true} visible={modalCompliceVisible}>
      <Pressable
        onTouchEnd={() => setModalCompliceVisible(false)}
        style={complicesStyles.modalContainer}
      >
        <View style={complicesStyles.modalCardContainer}>
          <Image
            source={{ uri: item.multimedia?.url }}
            style={complicesStyles.imagenModalComplice}
          />

          <View
            style={{ paddingHorizontal: 16, paddingTop: 16, paddingBottom: 24 }}
          >
            <Text style={complicesStyles.nombreCompliceModal}>
              {__(item, "nom")}
            </Text>
            {item.telefon && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: 12,
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    source={{ uri: imatgeRecurs("general_telefon") }}
                    style={complicesStyles.cardPhoneVector}
                  />
                  <Text style={complicesStyles.modalFont}>{item.telefon}</Text>
                </View>
              </View>
            )}
            {(item.direccio || item.poblacio || item.codi_postal) && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: 8,
                }}
              >
                <Image
                  style={complicesStyles.cardLocationVector}
                  source={{ uri: imatgeRecurs("general_posicio") }}
                />
                <Text
                  style={{
                    ...complicesStyles.modalFont,
                    flexWrap: "wrap",
                    width: 200,
                  }}
                  numberOfLines={2}
                >
                  {item.direccio}{" "}
                  {item.poblacio && <Text>- {item.poblacio}</Text>}
                  {item.codi_postal && <Text>({item.codi_postal})</Text>}
                </Text>
              </View>
            )}
            <Text
              style={{
                ...complicesStyles.modalFont,
                flexWrap: "wrap",
                marginTop: 10,
              }}
              numberOfLines={4}
            >
              {__(item, "descripcio")}
            </Text>
          </View>
        </View>
      </Pressable>
    </Modal>
  );
};
export default CompliceModal;
