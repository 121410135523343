import React from "react";
import {
  Image,
  Modal,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { useGlobal } from "../../Context/globalContext";

const ModalGhostEnd = (
  modalVisible: boolean,
  callback: any,
  punts: number,
  itinerari: any,
  poi: any,
) => {
  const { textRecurs, imatgeRecurs } = useGlobal();

  let imatge = punts
    ? imatgeRecurs("poi_encert_imatge")
    : imatgeRecurs("poi_error_imatge");

  const text1 = punts
    ? textRecurs("puzzle_guanyar_titol")
    : textRecurs("poi_error_fantasma_titulo");

  const text2 = punts
    ? `${textRecurs("poi_has_ganado_puntos", [punts])}`
    : textRecurs("perfil_ningun_punto");

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={callback}
    >
      <View style={styles.centeredView}>
        <LinearGradient
          colors={["#FFFFFF", "#e5e9e5", itinerari.color_fons1]}
          style={styles.modalView}
        >
          <View style={styles.closeButton}>
            <Pressable onPress={callback}>
              <Image
                source={{ uri: imatgeRecurs("boton_cerrar") }}
                style={{ width: 13, height: 13, marginRight: 10 }}
              />
            </Pressable>
          </View>

          <Image
            style={{
              flex: 1,
              width: 200,
              resizeMode: "contain",
            }}
            source={{ uri: imatge }}
          />
          <View>
            <Text style={styles.modalText}>{text1}</Text>
            <Text style={styles.modalSecondText}>{text2}</Text>
          </View>
          <TouchableOpacity
            style={{
              ...styles.buttonStartAdventure,
              backgroundColor: itinerari.color_fons2,
            }}
            onPress={callback}
          >
            <Text
              style={{
                ...styles.buttonStartAdventureText,
                color: itinerari.color_text1,
              }}
            >
              {textRecurs("onboarding_continuar")}
            </Text>
          </TouchableOpacity>
        </LinearGradient>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 22,
    gap: 24,
    isolation: "isolate",
    width: "80%",
    height: "70%",
    backgroundColor:
      "linear-gradient(180deg, #FFFFFF 100%, rgba(255, 255, 255, 1) 100%), #BBE0C4",
    border: "1px solid #BBE0C4",
    borderRadius: 24,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    // fontFamily: 'Poppins'
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 24,
    textAlign: "center",
    color: "#484848",
  },
  modalSecondText: {
    // fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    textAlign: "center",
    color: "#484848",
  },
  buttonStartAdventure: {
    backgroundColor: "#2A6438",
    borderRadius: 8,

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    gap: 10,
    width: "100%",
    height: "15%",
  },
  buttonStartAdventureText: {
    // fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    color: "#FFFFFF",
    letterSpacing: 0.4,
  },
  goBack: {
    // fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    textAlign: "center",
    color: "#484848",
    marginBottom: "10%",
  },
  closeButton: {
    position: "absolute",
    right: 23,
    top: 15.5,
  },
});

export default ModalGhostEnd;
