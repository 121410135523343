import React from "react";
import { Image, Modal, Pressable, Text, View } from "react-native";
import { useGlobal } from "../../Context/globalContext";

const ModalQRFail = (
  modalQRFailVisible: boolean,
  setModalQRFailVisible: any,
) => {
  const { textRecurs } = useGlobal();

  return (
    <>
      <Modal
        transparent={true}
        visible={modalQRFailVisible}
        onRequestClose={() => {
          setModalQRFailVisible(!modalQRFailVisible);
        }}
      >
        <Pressable
          style={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            flex: 1,
          }}
          onPress={() => {
            setModalQRFailVisible(!modalQRFailVisible);
          }}
        >
          <View
            style={{
              flexDirection: "column",
              borderRadius: 12,
              backgroundColor: "#E2E8D6",
              marginTop: 10,
              alignItems: "flex-start",
            }}
          >
            <View
              style={{
                padding: 16,
              }}
            >
              <View
                style={{
                  padding: 30,
                  borderRadius: 6,
                  alignSelf: "center",
                }}
              >
                <Image
                  source={{}}
                  style={{
                    height: 170,
                    width: 170,
                  }}
                />
              </View>
              <View
                style={{
                  borderBottomColor: "#00491F",
                  borderBottomWidth: 1,
                  marginHorizontal: 20,
                }}
              />
              <View
                style={{
                  width: "95%",
                  marginTop: 10,
                  paddingLeft: 30,
                }}
              >
                <Text
                  style={{
                    fontFamily: "GeorgiaBold",
                    fontSize: 24,

                    alignItems: "center",
                    letterSpacing: 0.02,
                    color: "#00491F",
                    marginTop: 5,
                  }}
                >
                  {textRecurs("perfil_img7_atención")}
                </Text>

                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#6D6D6D",
                  }}
                >
                  {textRecurs("perfil_img7_texto1")}
                </Text>
              </View>

              <Pressable
                style={{
                  borderColor: "#00491F",
                  borderWidth: 1,
                  alignSelf: "center",
                  borderRadius: 30,
                  paddingVertical: 15,
                  width: 200,
                  marginTop: 10,
                }}
                onPress={() => {
                  setModalQRFailVisible(!modalQRFailVisible);
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    fontSize: 16,
                    color: "#00491F",
                  }}
                >
                  {textRecurs("general_cerrar")}
                </Text>
              </Pressable>
            </View>
          </View>
        </Pressable>
      </Modal>
    </>
  );
};

export default ModalQRFail;
