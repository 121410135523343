import React, { useContext, useEffect } from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { API_URL, PROJECTE_ID } from "@/config/config";
import { useSecureStorageState } from "@/hooks/useSecureStorage";
import axios from "axios";
import { useAsyncStorage } from "@/hooks/useAsyncStorage";
import { postStadistic } from "@/Components/Functions/utils";
import { useGlobal } from "@/Components/Context/globalContext";

const AuthContext = React.createContext<any | null>(null);

type AuthError = { message: string };

type UseAuthProps = {
  isAuthenticated: boolean | null;
  user: any;
  token: string | null;
  login: (email: string, password: string) => any;
  loginSenzill: (username: string) => any;
  logout: () => void;
  savePerfil: (form: any) => Promise<any>;
  register: (username: string, email: string, password: string) => any;
  isLoading: boolean;
  error: AuthError;
  updatePuntsGastats: any;
  totalPuntsGastats: number;
};

function AuthProvider({ children }: { children: JSX.Element }) {
  const [[, user], setUser] = useAsyncStorage("user");
  const [[, token], setToken] = useSecureStorageState("token");
  const { projecte } = useGlobal();

  const login = async (email: string, password: string): Promise<void> => {
    const body = new FormData();

    body.append("identifier", email);
    body.append("password", password);

    const response = await fetch(API_URL + "/api/auth/local", {
      method: "POST",
      body,
    });

    const data = await response.json();

    if (data.error?.message) {
      return data.error.message;
    }

    postStadistic({
      projecte: projecte.nom,
      token: data.user.id,
      tipus_peticio: "Inici de sessió",
      peticio: "Inici de sessió",
      screen: "Login",
    });

    setUser(data.user);
    setToken(data.jwt);
  };

  const loginSenzill = async (nom: string): Promise<boolean> => {
    const fakeEmail = `${nom.replace(" ", "")}${Math.floor(
      Math.random() * 1000,
    )}@${PROJECTE_ID}.com`;

    const fakePassword = Math.floor(Math.random() * 1000000) + "FAKE";

    const form = {
      nom,
      email: fakeEmail,
      password: fakePassword,
      username: fakeEmail,
    };

    const response = await axios.post(
      API_URL + "/api/auth/local/register",
      form,
    );

    const data = await response.data;

    if (data.error?.message) {
      return data.error.message;
    }

    data.user.progres = {
      itineraris: {},
      favoritos: {
        recursos: [],
        tours: [],
      },
    };

    // data.user.es_admin = nom === "iternatura";

    postStadistic({
      projecte: projecte.nom,
      token: data.user.id,
      tipus_peticio: "Inici de sessió",
      peticio: "Inici de sessió",
      screen: "Login",
    });

    setUser(data.user);
    setToken(data.jwt);

    return false;
  };

  useEffect(() => {
    if (typeof token === "string") {
      getMe();
    }
  }, [token]);

  async function logout() {
    await AsyncStorage.clear();

    setUser(null);
  }

  const register = async (nom: string, email: string, password: string) => {
    const form = {
      nom,
      email,
      password,
      username: email,
    };

    const response = await axios.post(
      API_URL + "/api/auth/local/register",
      form,
    );

    const data = await response.data;

    if (data.error?.message) {
      return data.error.message;
    }

    data.user.progres = {
      itineraris: {},
      favoritos: {
        recursos: [],
        tours: [],
      },
    };

    postStadistic({
      projecte: projecte.nom,
      token: data.user.id,
      tipus_peticio: "Inici de sessió",
      peticio: "Inici de sessió",
      screen: "Login",
    });

    setUser(data.user);
    setToken(data.jwt);

    return false;
  };

  const getMe = async () => {
    const response = await axios.get(
      `${API_URL}/api/users/me?projecte=${PROJECTE_ID}&populate=avatar`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const user = response.data;

    if (user.avatar) {
      user.avatar = `${API_URL}${user.avatar.url}`;
    }

    if (user.nom === "iternatura") {
      user.es_admin = true;
    }

    user.progres.itineraris ??= {};
    user.progres.favoritos ??= {
      recursos: [],
      tours: [],
    };

    return setUser(user);
  };

  const savePerfil = async (userForm: any): Promise<any> => {
    try {
      await axios.put(
        `${API_URL}/api/users-permissions/users/me?projecte=${PROJECTE_ID}`,
        userForm,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      await getMe();
    } catch (error) {
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status,
        );
        alert("Response data:" + JSON.stringify(error.response.data));
      } else if (error.request) {
        alert("No response received:" + JSON.stringify(error.request));
      } else {
        alert("Error creating request:" + JSON.stringify(error.message));
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: user?.id,
        user,
        token,
        login,
        loginSenzill,
        logout,
        register,
        savePerfil,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = (): UseAuthProps => useContext(AuthContext);

export { AuthProvider, useAuth };
