import { useRef } from "react";

export const useDebounce = () => {
  const busy = useRef(false);

  return async (callback: Function, BOUNCE_RATE = 1000) => {
    setTimeout(() => {
      busy.current = false;
    }, BOUNCE_RATE);

    if (!busy.current) {
      busy.current = true;
      callback();
    }
  };
};
