import * as React from "react";
import { useEffect, useState } from "react";
import {
  FlatList,
  Image,
  ImageBackground,
  Modal,
  Platform,
  Pressable,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { tours360Styles } from "./Tours360Style";
import AuthAppShell from "../../Components/AuthAppShell";
import HeaderComponent from "../../Components/HeaderComponent";
import { WebView } from "react-native-webview";

import ModalMunicipis from "../Modals/ModalMunicipis";
import { useGlobal } from "../../Context/globalContext";
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";

function WebEncastada(props: { uri: any }) {
  if (Platform.OS === "web") {
    return (
      <iframe
        src={props.uri}
        height={"100%"}
        width={"100%"}
        style={{ border: "none" }}
      />
    );
  }

  return (
    <WebView
      source={{
        uri: props.uri,
      }}
      allowFullScreen={true}
      style={tours360Styles.iframe}
    />
  );
}

export default function Tours360(RouterData: any) {
  const navigation = useNavigation();
  const { textRecurs, projecte, imatgeRecurs, __ } = useGlobal();

  const vistes = projecte.vistes.filter(
    (vista: any) => vista.tipus === "Vista360",
  );

  const itinerari = RouterData.route.params?.itinerari?.id;
  const [municipiActiu, setMunicipiActiu] = useState("");
  const [municipiActiuIndex, setMunicipiActiuIndex] = useState(0);
  const [modalMunicipisVisible, setModalMunicipisVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [vistaSeleccionada, setVistaSeleccionada] = useState(
    RouterData.route.params?.vista ?? null,
  );

  const [fromSpecificScreen, setFromSpecificScreen] = useState(false);

  React.useEffect(() => {
    if (itinerari) {
      setMunicipiActiu(itinerari);
    }
  }, [itinerari]);

  const BotonGoBack = () => {
    const navigation = useNavigation<any>();
    const isFocused = useIsFocused();
    const route = useRoute();

    useEffect(() => {
      const specificScreen = "Tours360";
      setFromSpecificScreen(route.name === specificScreen);
    }, [isFocused, route]);

    if (!fromSpecificScreen) {
      return <View />;
    }

    return (
      <View style={{ padding: 10 }}>
        <Pressable onPress={() => navigation.goBack()}>
          <Image
            source={{ uri: imatgeRecurs("boton_atras") }}
            style={{ height: 30, width: 30 }}
          />
        </Pressable>
      </View>
    );
  };

  const BarraFiltos = () => {
    return (
      <View
        style={{
          width: "100%",
          maxHeight: 50,
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingLeft: 20,
          gap: 10,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            borderWidth: 1,
            borderColor: "white",
            borderRadius: 6,
            paddingVertical: 6,
            paddingHorizontal: 8,
          }}
          onTouchEnd={() => setModalMunicipisVisible(!modalMunicipisVisible)}
        >
          <Text style={{ fontFamily: "Poppins", fontSize: 14, color: "white" }}>
            {textRecurs("filtros_seleccionar_itinerario")}
          </Text>
        </View>
      </View>
    );
  };

  const Tour = (item: any) => {
    return (
      <View style={tours360Styles.container}>
        <ImageBackground
          source={{
            uri: item.miniatura?.url,
          }}
          style={tours360Styles.cardContainer}
          imageStyle={{
            borderRadius: 12,
            borderColor: "white",
            borderWidth: 1,
          }}
        >
          <View style={tours360Styles.whiteFilter}>
            <View style={{ paddingTop: 40, paddingLeft: 20 }}>
              <Text style={tours360Styles.cardName}>{__(item, "nom")}</Text>

              <TouchableOpacity
                style={{
                  ...tours360Styles.button360,
                  backgroundColor: projecte.color_principal,
                }}
                onPress={() => {
                  setModalVisible(true);
                  setVistaSeleccionada(item);
                }}
              >
                <View style={tours360Styles.buttonContainer}>
                  <Image
                    source={{ uri: imatgeRecurs("boton_360_petit") }}
                    style={tours360Styles.vrVectorStyle}
                  />
                  <Text
                    style={{ ...tours360Styles.buttonText, paddingLeft: 3 }}
                  >
                    {textRecurs("360_ver")}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </ImageBackground>
      </View>
    );
  };

  const vistesVisibles = municipiActiuIndex
    ? vistes.filter(
        (vista: { tipus: string; itinerari: number }) =>
          vista.itinerari === municipiActiuIndex,
      )
    : vistes;

  return (
    <AuthAppShell>
      <HeaderComponent title={textRecurs("general_tours_360")} />
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <BotonGoBack />
        {/*<BarraFiltos />*/}
      </View>

      <FlatList
        data={vistesVisibles}
        renderItem={({ item }) => {
          item.seleccionat = vistaSeleccionada?.id === item.id;

          return <Tour {...item} />;
        }}
        style={{
          height: 0,
        }}
        keyExtractor={(item) => item.id}
      />
      <View style={{ height: 30 }}></View>

      <Modal
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={tours360Styles.iframeContainer}>
          <WebEncastada uri={vistaSeleccionada?.url} />
          <Pressable
            onPress={() => {
              if (!fromSpecificScreen) {
                setModalVisible(false);
              } else {
                navigation.goBack();
              }
            }}
            style={{
              position: "absolute",
              top: "5%",
              left: "5%",
              zIndex: 1,
            }}
          >
            <Image
              source={{ uri: imatgeRecurs("boton_atras") }}
              style={{ height: 30, width: 30 }}
            />
          </Pressable>
        </View>
      </Modal>
      {ModalMunicipis(
        modalMunicipisVisible,
        setModalMunicipisVisible,
        setMunicipiActiu,
        setMunicipiActiuIndex,
        municipiActiuIndex,
      )}
    </AuthAppShell>
  );
}
