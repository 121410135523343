import React, { useEffect, useRef, useState } from "react";
import { Image, ScrollView, Text, View } from "react-native";
import style from "../Perfil/CarouselImagePerfilStyle";
import { useGlobal } from "../../Context/globalContext";
import Markdown from "react-native-markdown-display";
import { useAuth } from "@/Components/Context/authContext";
import {
  loadSound,
  playSoundForAWhile,
} from "@/Components/Functions/MapFunctions";

export default function CarouselImagePerfil({
  itinerari,
}: {
  itinerari: any;
  mostraGif: any;
}) {
  const { __, projecte, imatgeRecurs } = useGlobal();
  const { user } = useAuth();

  const scrollViewRef = useRef(null);

  const uri = itinerari.multimedia_recompensa?.url;
  const codiPremi = user.progres.itineraris[itinerari.id]?.codiPremi;

  useEffect(() => {
    loadSound(itinerari.so_recompensa?.url, () => {});
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <View style={style.carouselContainer}>
        <Image
          source={{
            uri,
          }}
          style={{
            flex: 1,
            width: "100%",
          }}
        />

        <ScrollView
          ref={scrollViewRef}
          pagingEnabled
          // onScroll={handleScroll}
          showsHorizontalScrollIndicator={false}
          style={style.scroll}
        >
          <View style={style.logoScroll}>
            <View style={style.cardContainer}>
              <View style={style.scrollView}>
                {__(itinerari, "subtitol") && (
                  <View style={{ flex: 1 }}>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <View
                        style={{ marginLeft: 20, alignItems: "flex-start" }}
                      >
                        <Markdown
                          style={{
                            body: {
                              fontFamily: "Roboto",
                              fontWeight: "400",
                              fontSize: 13,
                              lineHeight: 16.8,
                              color: "rgba(72, 72, 72, 1)",
                            },
                          }}
                        >
                          {__(itinerari, "subtitol")}
                        </Markdown>
                      </View>
                    </View>
                  </View>
                )}

                <View
                  style={{
                    flex: 3,
                    alignItems: "center",
                    // borderRadius: 64,
                    borderTopLeftRadius: 64,
                    borderTopRightRadius: 64,
                    backgroundColor: itinerari.color_fons1,
                    gap: 80,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <View
                      style={{
                        flex: 2,
                        gap: 10,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ScrollView
                        style={{
                          paddingHorizontal: 30,
                          marginVertical: 15,
                          gap: 4,
                        }}
                      >
                        {itinerari.cupo_final && (
                          <Text
                            style={{
                              fontFamily: "Georgia",
                              fontWeight: "400",
                              color: itinerari.color_text1,
                              textAlign: "center",
                              fontSize: 28,
                              marginBottom: 20,
                            }}
                          >
                            {codiPremi}
                          </Text>
                        )}
                        <Text
                          style={{
                            fontFamily: "Georgia",
                            fontWeight: "400",
                            color: itinerari.color_text1,
                            textAlign: "center",
                            fontSize: 24,
                          }}
                        >
                          {__(itinerari, "descripcio_completat")}
                        </Text>
                      </ScrollView>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
}
