import { useState, useEffect } from "react";

const useMediaType = (url) => {
  const [mediaType, setMediaType] = useState("loading...");

  useEffect(() => {
    if (!url) {
      return setMediaType("none");
    }

    const checkMediaType = async () => {
      try {
        const response = await fetch(url, { method: "HEAD" });
        const contentType = response.headers.get("content-type");

        if (contentType) {
          if (contentType.startsWith("image/")) {
            setMediaType("image");
          } else if (contentType.startsWith("video/")) {
            setMediaType("video");
          } else {
            setMediaType("unknown");
          }
        }
      } catch (error) {
        console.error("Error checking media type:", error);
        setMediaType("error");
      }
    };

    checkMediaType();
  }, [url]);

  return mediaType;
};

export default useMediaType;
