import React from "react";
import {
  Image,
  Linking,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import constants from "@/config/constants";
import { useGlobal } from "../../Context/globalContext";

export default function PwdRecover2(RouterData: any) {
  const { textRecurs, projecte, imatgeRecurs } = useGlobal();

  const navigateToEmail = () => {
    if (Platform.OS === "android") {
      Linking.openURL("mailto:"); // Android
    } else {
      Linking.openURL("message:0"); // iOS
    }

    RouterData.navigation.navigate("LoginScreen", {
      ...RouterData.route.params,
    });
  };
  const navigateToLogin = () => {
    RouterData.navigation.navigate("LoginScreen", {
      ...RouterData.route.params,
    });
  };

  return (
    <View style={{ backgroundColor: constants.colorBgLogin }}>
      <View style={style.mainContainer}>
        <View style={style.imageContainer}>
          <Image
            source={{ uri: imatgeRecurs("acceso_recupear_contraseña") }}
            alt="Arrow Vector"
          />
        </View>
        <Text style={{ ...style.mainTitle, color: projecte.color_principal }}>
          {textRecurs("acceso_revisa_bandeja_titulo")}
        </Text>

        <Text style={style.infoText}>
          {textRecurs("acceso_revisa_bandeja_texto")}
        </Text>

        <TouchableOpacity
          style={{
            ...style.btnLogin,
            backgroundColor: projecte.color_principal,
          }}
          onPress={navigateToEmail}
        >
          <Text
            style={{
              color: constants.colorWhite,
              letterSpacing: 0.8,
              fontSize: 16,
              fontWeight: "700",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {textRecurs("acceso_abrir_correo")}
          </Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={navigateToLogin}>
          <Text style={{ ...style.links, color: projecte.color_principal }}>
            {textRecurs("general_volver")}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const style = StyleSheet.create({
  mainContainer: {
    flex: 0,
    paddingTop: 136,
    paddingBottom: 344,
    paddingHorizontal: 24,
    // backgroundColor: constants.colorBgLogin,
    AlignItems: "center",
    alignSelf: "center",
  },

  mainTitle: {
    flex: 0,
    fontFamily: "Georgia",
    fontSize: 32,
    fontWeight: "700",
    color: constants.colorGreenDark,
    // width: 327,
    // height: 38,
    lineHeight: 38.4,
    textAlign: "center",
    //marginTop: 40,
    paddingHorizontal: 53,
  },
  infoText: {
    fontFamily: "Roboto",
    flex: 0,
    fontSize: 16,
    fontWeight: "400",
    color: constants.colorBlackText,
    marginTop: 8,
    textAlign: "center",
    // width: 327,
    height: 66,
    // marginLeft:24,
    lineHeight: 22.4,
    marginBottom: 15,
  },
  btnLogin: {
    fontFamily: "Roboto",

    // width: 327,
    height: 56,
    // marginTop:32,
    // marginHorizontal:24,
    // marginBottom:344,
    // paddingHorizontal:10,
    // paddingVertical:12,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: constants.colorGreenDarkBtn,
    borderRadius: 8,
    fontSize: 16,
    margin: 10,
  },
  imageContainer: {
    flex: 8,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginBottom: 80,
  },
  links: {
    color: constants.colorGreenDarkBtn,
    fontWeight: "700",
    margin: 5,
    textAlign: "center",
    letterSpacing: 0.64,
    paddingTop: 50,
  },
});
