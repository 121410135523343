import React, { useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import style from "./QuizzStyle";
import { IResposta } from "../../Interfices";
import { useGlobal } from "../../Context/globalContext";
import {
  checkErrors,
  fromTextToIndex,
} from "@/Components/Functions/MapFunctions";
import JocComponent from "@/Components/Components/JocComponent";

export default function Quizz(RouterData: any) {
  const { __, textRecurs, imatgeRecurs, projecte } = useGlobal();

  const [goAnswers, setGoanswers] = useState<boolean>(false);
  const [errors, setErrors] = useState(RouterData.route.params.errors || 0);

  /** Si la pregunta no és undefined, és un trivial i el poi és el trivial */
  let { poi, pregunta, itinerari, punts } = RouterData.route.params;
  poi = pregunta ?? poi;

  const validAnswerIndex = fromTextToIndex(poi.opcio_correcta);

  const [respostes, setRespostes] = useState(
    Array.from({ length: 4 }, (_, index) => ({
      id: index + 1,
      text: __(poi, `opcio_text${index + 1}`),
      valid: validAnswerIndex === index + 1,
      checked: false,
    })),
  );

  const checkAnswer = (item: IResposta) => {
    if (item.valid) {
      setGoanswers(true);
    } else {
      setErrors(errors + 1);
    }

    respostes.find((e: IResposta) => e.id == item.id).checked = true;

    setRespostes(respostes);
  };

  const renderAnswers = () => {
    return respostes.map((item: any) => (
      <TouchableOpacity
        key={item.id}
        disabled={item.checked || goAnswers}
        onPress={() => checkAnswer(item)}
        style={[
          style.answerButton,
          item.checked &&
            (item.valid ? style.answerButtonCorrect : style.answerButtonWrong),
        ]}
      >
        <Text style={style.answerText}>{item.text}</Text>
      </TouchableOpacity>
    ));
  };

  const navigateToReward = () => {
    // Es trivial?
    if (!!pregunta) {
      if (RouterData.route.params.poi.tipus === "TrivialResum") {
        return RouterData.navigation.replace("Trivial", {
          ...RouterData.route.params,
          poi: RouterData.route.params.poi,
          punts: punts + checkErrors(errors),
        });
      }

      return RouterData.navigation.replace("Reward", {
        ...RouterData.route.params,
        poi,
        poiOriginal: RouterData.route.params.poi,
        punts: checkErrors(errors),
      });
    }

    RouterData.navigation.replace("Reward", {
      ...RouterData.route.params,
      punts: checkErrors(errors),
    });
  };

  return (
    <JocComponent RouterData={RouterData} setPistesDemanades={setErrors}>
      <View style={style.quizzAnswerBackground}>
        {renderAnswers()}
        <TouchableOpacity
          onPress={() => navigateToReward()}
          style={[
            style.botoContinuar,
            !goAnswers ? { opacity: 0.5 } : { opacity: 1 },
            { backgroundColor: itinerari.color_fons2 },
          ]}
          disabled={!goAnswers}
        >
          <Text style={style.textContinuar}>
            {textRecurs("onboarding_continuar")}
          </Text>
        </TouchableOpacity>
      </View>

      <View
        style={[style.arrowBack, goAnswers ? { opacity: 0.2 } : { opacity: 1 }]}
      >
        <TouchableOpacity
          style={style.arrowBack}
          onPress={() => RouterData.navigation.goBack()}
          disabled={goAnswers}
        >
          <Image
            source={{ uri: imatgeRecurs("boton_atras") }}
            style={{ height: 30, width: 30 }}
          />
        </TouchableOpacity>
      </View>
    </JocComponent>
  );
}
