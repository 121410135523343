import { useGlobal } from "@/Components/Context/globalContext";
import { useNavigation } from "@react-navigation/native";
import {
  ImageBackground,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import Tours360Scroll from "@/Components/Components/Home/Tours360Scroll";
import RecursosScroll from "@/Components/Components/Home/RecursosScroll";
import * as React from "react";
import { ScrollView } from "react-native-gesture-handler";

const HomeOverlayInferior = () => {
  const { textRecurs, projecte } = useGlobal();

  const navigation = useNavigation<any>();

  const navigateTo360 = () => navigation.navigate("Tours360", {});

  const navigateToRecursos = (tipus) =>
    navigation.navigate("Recursos", { filtre: tipus });

  const getTextRecurs = (tipus: string) => {
    return "recursos_" + tipus.toLowerCase().replaceAll(" ", "_");
  };

  const HeaderSection = ({ titleResource, buttonPress }) => (
    <View style={style.overlayHeader}>
      <Text style={[style.textHeader, { color: projecte.color_principal }]}>
        {textRecurs(titleResource)}
      </Text>
      <Pressable style={style.buttonVerMas} onPress={buttonPress}>
        <Text style={[style.textVerMas, { color: projecte.color_principal }]}>
          {textRecurs("general_ver_mas")}
        </Text>
      </Pressable>
    </View>
  );

  return (
    <View style={style.overlayContainer}>
      <View style={style.overlaySelector} />
      <ImageBackground
        source={{ uri: projecte.fons?.url }}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <ScrollView style={style.scrollContainer}>
          {/*<HeaderSection*/}
          {/*  titleResource="general_tours_virtuales"*/}
          {/*  buttonPress={navigateTo360}*/}
          {/*/>*/}
          {/*<Tours360Scroll />*/}

          {projecte.establiments.some((e) => e.tipus === "Patrimoni") && (
            <HeaderSection
              titleResource={getTextRecurs("Patrimoni")}
              buttonPress={() => navigateToRecursos("Patrimoni")}
            />
          )}
          <RecursosScroll tipus={"Patrimoni"} />

          {projecte.establiments.some((e) => e.tipus === "Oci i Cultura") && (
            <HeaderSection
              titleResource={getTextRecurs("Oci i Cultura")}
              buttonPress={() => navigateToRecursos("Oci i Cultura")}
            />
          )}
          <RecursosScroll tipus={"Oci i Cultura"} />

          {projecte.establiments.some((e) => e.tipus === "Establiment") && (
            <HeaderSection
              titleResource={getTextRecurs("Establiment")}
              buttonPress={() => navigateToRecursos("Establiment")}
            />
          )}
          <RecursosScroll tipus={"Establiment"} />

          <View style={{ marginBottom: 300 }} />
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default HomeOverlayInferior;

const style = StyleSheet.create({
  textHeader: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "700",
  },
  buttonVerMas: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 4,
  },
  textVerMas: {
    fontFamily: "Roboto",
  },
  overlayContainer: {
    backgroundColor: "#eee",
    marginTop: 20,
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    borderWidth: 1,
    borderColor: "#CCC",
    alignItems: "center",
  },
  overlayHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 10,
    paddingTop: 20,
  },
  overlaySelector: {
    backgroundColor: "#BBB",
    width: 100,
    height: 14,
    marginVertical: 10,
    borderRadius: 20,
  },
  scrollContainer: {
    width: "100%",
  },
});
