import React, { useCallback, useState } from "react";
import { ActivityIndicator, Image, StyleSheet, Text, View } from "react-native";
import { useGlobal } from "@/Components/Context/globalContext";
import { PuzzlePieces } from "react-native-picture-puzzle";
import useJocTeTemps from "@/hooks/useJocTeTemps";
import { WebView } from "react-native-webview";
import JocComponent from "@/Components/Components/JocComponent";
import { JigsawPuzzle } from "react-jigsaw-puzzle/lib";
import "react-jigsaw-puzzle/lib/jigsaw-puzzle.css";

export default function JocPuzleJigsaw(RouterData: any) {
  const { __, imatgeRecurs } = useGlobal();
  /** Si la pregunta no és undefined, és un trivial i el poi és el trivial */
  let { poi, pregunta, itinerari } = RouterData.route.params;
  poi = pregunta ?? poi;

  const tempsRestant = useJocTeTemps(poi.segons, () => navigateToReward(0));

  const navigateToReward = (punts: number) => {
    RouterData.navigation.replace("Reward", {
      ...RouterData.route.params,
      punts,
    });
  };

  const injectedJavaScript = `
    // Save the original XMLHttpRequest.send function
    var originalSend = XMLHttpRequest.prototype.send;
    
    // Override the XMLHttpRequest.send function
    XMLHttpRequest.prototype.send = function() {
        // Add an event listener for the 'load' event
        this.addEventListener('load', function() {
            if (this.responseURL === 'https://www.jigsawplanet.com/api/game/completed') {
                window.ReactNativeWebView.postMessage('finalitzat');
            }
        });
    
        // Call the original send function
        originalSend.apply(this, arguments);
    };
    
    document.querySelector(".control").style.display = 'none'
    document.querySelector(".fa-share-alt").style.display = 'none'
    
    true; // note: this is required, or you'll sometimes get silent failures
  `;

  const onWebViewMessage = (event) => {
    navigateToReward(poi.premi.punts);
  };

  return (
    <JocComponent RouterData={RouterData} setPistesDemanades={() => null}>
      {tempsRestant > 0 && (
        <View style={styles.tempsDeJocContainer}>
          <Image
            source={{ uri: imatgeRecurs("poi_reloj") }}
            style={{
              width: 38,
              height: 38,
            }}
          />
          <Text style={{ fontSize: 20 }}>{tempsRestant}</Text>
        </View>
      )}

      <View
        style={[
          styles.guessRow,
          {
            height: 400,
          },
        ]}
      >
        {poi.url_360 ? (
          <WebView
            source={{
              uri: poi.url_360,
            }}
            injectedJavaScript={injectedJavaScript}
            onMessage={onWebViewMessage}
            allowFullScreen={true}
            style={{
              height: 400,
              width: 400,
            }}
          />
        ) : (
          <View style={{ margin: 20 }}>
            <JigsawPuzzle
              imageSrc={poi.opcio_imatge1?.url}
              rows={2}
              columns={3}
              onSolved={() => onWebViewMessage(null)}
            />
          </View>
        )}
      </View>
    </JocComponent>
  );
}

const styles = StyleSheet.create({
  guessRow: {
    alignContent: "flex-start",
    alignSelf: "flex-start",
    backgroundColor: "#f9f7e8",
    width: "100%",
    justifyContent: "flex-start",
    borderTopEndRadius: 44,
    borderTopStartRadius: 44,
    paddingVertical: 20,
    // flexGrow: 1, TODO - OJO WEB
  },
  tempsDeJocContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 12,
    gap: 12,
    margin: 10,
  },
});
