import React from "react";
import { View } from "react-native";
import globalstyle from "../../Styles/Globals/globals";
import HeaderButtons from "../Components/HeaderButtons";
import CarouselImage from "./CarouselImage";

export default function RutesImatge({ route }: any) {
  const { itinerari } = route.params;

  return (
    <View style={{ ...globalstyle.container, marginTop: -60 }}>
      <View
        style={{
          flexGrow: 1,
        }}
      >
        <View style={{ flex: 1, marginTop: 20 }}>
          <HeaderButtons {...itinerari} />
          <CarouselImage {...itinerari} />
        </View>
      </View>
    </View>
  );
}
