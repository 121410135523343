import { Platform, StyleSheet } from "react-native";
import constants from "@/config/constants";

/* Styles for the whole app */

const globalstyle = StyleSheet.create({
  /*Images*/
  backgroundImatges: {
    flexGrow: 1,
    resizeMode: "cover",
    paddingTop: Platform.OS === "ios" ? 40 : 0,
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },

  /*Buttons*/
  boto: {
    // not used
    fontFamily: "OpenSans", //=>
    fontSize: "2.5vh",
    padding: 5,
    paddingHorizontal: 10,
    alignSelf: "center",
    backgroundColor: constants.colorBotons,
    color: "white",
    fontWeight: "bold",
  },
  botoContinuar: {
    //not used
    fontFamily: "OpenSans",
    fontSize: 20,
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: 25,
    paddingRight: 25,
    paddingHorizontal: 10,
    alignSelf: "center",
    backgroundColor: constants.colorBotons,
    color: "white",
    fontWeight: "bold",
  },

  /*Containers */
  containerFull: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
  containerRow: {
    //not used
    flex: 1,
    flexDirection: "row",
    paddingVertical: 10,
  },
  mainContainer: {
    flex: 0,
    // flexDirection:'row',
    paddingTop: 80,
    paddingBottom: 124,
    paddingHorizontal: 24,
    backgroundColor: constants.colorBgLogin,
    AlignItems: "center",
    alignSelf: "center",
  },
  crossBox: {
    //not used
    position: "absolute",
    right: 6,
    top: 6,
  },
  cross: {
    // not used
    height: 30,
    width: 30,
  },
  containerBg: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
  },
  arrowPosition: {
    position: "absolute",
    top: 20,
    left: 10,
    paddingLeft: "5%",
    width: 40,
    height: 40,
    zIndex: 30000,
  },
  compassPosition: {
    position: "absolute",
    top: 80,
    left: 10,
    paddingLeft: "5%",
    width: 40,
    height: 40,
  },
  filtersContainer: {
    position: "absolute",
    bottom: 80,
    left: 0,
    right: 0,
    alignItems: "center",
    zIndex: 30000,
  },
  closeButton: {
    position: "absolute",
    right: 23,
    top: 15.5,
    zIndex: 100,
  },
  closeButtonPressable: {
    padding: 12,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalCardContainer: {
    borderRadius: 12,
    backgroundColor: "#F9F7E8",
    marginVertical: 10,
    alignItems: "flex-start",
    width: "92%",
    flex: 1,
  },
  modalContent: {
    marginTop: 32,
    padding: 16,
    flex: 1,
    width: "100%",
  },
  /** Jocs **/
  jocsBotoContinuar: {
    justifyContent: "center",
    alignSelf: "center",
    width: "90%",
    height: 40,
    marginBottom: 10,
    backgroundColor: "#00491F",
    borderRadius: 8,
  },
  jocsTextContinuar: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    flexDirection: "column",
    fontWeight: "400",
    fontSize: 20,
    color: "#FFFFFF",
    textAlign: "center",
  },
});

export default globalstyle;
