import Constants from "expo-constants";

export const API_URL: string = Constants.expoConfig?.config?.API_URL;
export const API_TOKEN: string = Constants.expoConfig?.config?.API_TOKEN;

export const GOOGLE_MAPS_API_KEY =
  Constants.expoConfig?.config?.GOOGLE_MAPS_API_KEY;

export const PROJECTE_ID: number = parseInt(
  Constants.expoConfig?.config?.PROJECTE_ID,
);
