import React, { useState } from "react";
import {
  ImageBackground,
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import globalstyle from "@/Styles/Globals/globals";
import { Image } from "expo-image";
import { useGlobal } from "@/Components/Context/globalContext";
import constants from "@/config/constants";
import { useAuth } from "@/Components/Context/authContext";

const LoginSenzill = () => {
  const { textRecurs, imatgeRecurs, projecte } = useGlobal();
  const { loginSenzill } = useAuth();

  const [username, setUsername] = React.useState("");
  const [nameError, setNameError] = useState("");

  const validateName = () => {
    if (username.trim() === "" || username.length < 3) {
      setNameError(textRecurs("acceso_error_nombre_obligatorio"));
    } else {
      setNameError("");
    }
  };

  const handleLoginSenzill = () => {
    if (username.trim() === "" || username.length < 3) {
      return validateName();
    }

    loginSenzill(username);
  };

  return (
    <View style={globalstyle.containerBg}>
      <ImageBackground
        source={{ uri: imatgeRecurs("onboarding_fons") }}
        style={globalstyle.backgroundImatges}
      >
        <View style={[globalstyle.container]}>
          <Image
            source={{ uri: imatgeRecurs("login_imatge") }}
            style={[style.imatge]}
            contentFit={"contain"}
          />

          <KeyboardAvoidingView
            behavior={"padding"}
            style={{
              flex: 1,
              padding: 20,
            }}
          >
            <Text
              style={[style.mainTitle, { color: projecte.color_secundari }]}
            >
              {textRecurs("acceso_login_titulo")}
            </Text>
            <Text style={[style.infoText, { color: projecte.color_secundari }]}>
              {textRecurs("acceso_login_descripcion")}
            </Text>

            <View
              style={{
                alignItems: "center",
              }}
            >
              <TextInput
                placeholder={textRecurs("acceso_nombre")}
                onChangeText={setUsername}
                onBlur={validateName}
                style={[style.textInput, { color: projecte.color_principal }]}
              />
              {nameError !== "" && (
                <Text style={style.errorText}>{nameError}</Text>
              )}

              <TouchableOpacity
                onPress={handleLoginSenzill}
                style={[
                  style.buttonContainer,
                  { backgroundColor: projecte.color_principal },
                ]}
              >
                <Text style={style.buttonText}>
                  {textRecurs("onboarding_continuar")}
                </Text>
              </TouchableOpacity>
            </View>
          </KeyboardAvoidingView>
        </View>
      </ImageBackground>
    </View>
  );
};

export default LoginSenzill;

const style = StyleSheet.create({
  mainTitle: {
    fontFamily: "Georgia",
    fontSize: 32,
    fontWeight: "700",
  },
  infoText: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "400",
    marginTop: 8,
    marginBottom: 34,
    lineHeight: 22.4,
  },
  imatge: {
    flex: 1,
    borderRadius: 16,
  },
  textInput: {
    fontFamily: "Roboto",
    marginTop: 12,
    padding: 16,
    borderWidth: 1,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: "400",
    backgroundColor: constants.colorWhite,
    width: "100%",
  },
  buttonContainer: {
    flexDirection: "row",
    marginHorizontal: 76,
    marginVertical: 20,
    color: constants.colorWhite,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    borderStyle: "solid",
    borderRadius: 8,
    borderWidth: 2,
    width: "100%",
  },
  buttonText: {
    color: "white",
    flex: 2,
    textAlign: "center",
    fontSize: 16,
    fontWeight: "700",
    letterSpacing: 0.8,
  },
  errorText: {
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: 13,
    lineHeight: 12,
    letterSpacing: 0.64,
    color: "red",
    textAlign: "left",
    paddingLeft: 23,
    paddingTop: 6,
  },
});
