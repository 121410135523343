import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import IdiomaScreen from "@/Components/Screens/Idioma/IdiomaScreen";
import LoginScreen from "../Screens/Login/LoginScreen";
import OnBoardingScreen from "../Screens/OnBoarding/OnBoardingScreen";
import PwdRecover1 from "../../Components/Screens/Login/PwdRecover1";
import PwdRecover2 from "../../Components/Screens/Login/PwdRecover2";
import Settings from "../../Components/Screens/Settings/SettingsScreen";
import Signup from "../../Components/Screens/Signup/Signup";
import { Image } from "react-native";
import useTabsConfiguration from "../Hooks/useTabsConfiguration";
import EditarPerfil from "../Screens/Perfil/EditarPerfil";
import ItinerariDetail from "../Rutes/ItinerariDetail";
import MapScreen from "../Screens/Map/Map";
import QuizzImages from "../Screens/QuizzImages/QuizzImages";
import Quizz from "../Screens/Quizz/Quizz";
import Reward from "../Screens/Reward/Reward";
import Game360 from "../Screens/Game360/Game360";
import JocEscriureParaula from "@/Components/Screens/JocEscriureParaula/JocEscriureParaula";
import PuzzlePerfil from "../Screens/Perfil/PuzzlePerfil";
import PuzzleGran from "../Screens/Perfil/PuzzleGran";
import Tours360 from "../Screens/Tours360/Tours360";
import GaleriaImatge from "../Rutes/GaleriaImatge";
import CanjearGuads from "../Screens/Perfil/CanjearGuads";
import Trivial from "@/Components/Screens/Trivial";
import { useAuth } from "../Context/authContext";
import SplashScreen from "../Screens/SplashScreen";
import Perfil from "../Screens/Perfil/PerfilScreen";
import JocCheckIn from "@/Components/Screens/JocCheckIn/JocCheckIn";
import JocPuzle from "@/Components/Screens/JocPuzle/JocPuzle";
import Trucada from "@/Components/Screens/Trucada/Trucada";
import PreviTrucada from "@/Components/Screens/Trucada/PreviTrucada";
import Ranking from "@/Components/Screens/Ranking/Ranking";
import Valoracions from "@/Components/Screens/Valoracions/Valoracions";
import Premi from "@/Components/Screens/Premi/Premi";
import MapTest from "@/tests/MapTest";
import QRScreen from "@/Components/Screens/QRScreen";
import JocPuzleJigsaw from "@/Components/Screens/JocPuzle/JocPuzleJigsaw";

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

export default function AppNavigation() {
  const { isAuthenticated } = useAuth();

  return (
    <NavigationContainer>
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </NavigationContainer>
  );
}

const AuthenticatedApp = () => {
  return (
    <Stack.Navigator
      initialRouteName={"Splash"}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="QRScreen" component={QRScreen} />
      <Stack.Screen name="Tabs" component={TabNavigation} />
      <Stack.Screen name="Settings" component={Settings} />
      <Stack.Screen name="EditarPerfil" component={EditarPerfil} />
      <Stack.Screen name="ItinerariDetail" component={ItinerariDetail} />
      <Stack.Screen name="Map" component={MapScreen} />
      <Stack.Screen name="QuizzImages" component={QuizzImages} />
      <Stack.Screen name="Quizz" component={Quizz} />
      <Stack.Screen name="JocEscriureParaula" component={JocEscriureParaula} />
      <Stack.Screen name="JocCheckIn" component={JocCheckIn} />
      <Stack.Screen name="JocPuzle" component={JocPuzle} />
      <Stack.Screen name="JocPuzleJigsaw" component={JocPuzleJigsaw} />
      <Stack.Screen name="Reward" component={Reward} />
      <Stack.Screen name="PreviTrucada" component={PreviTrucada} />
      <Stack.Screen name="Trucada" component={Trucada} />
      <Stack.Screen name="Ranking" component={Ranking} />
      <Stack.Screen name="Valoracions" component={Valoracions} />
      <Stack.Screen name="Premi" component={Premi} />
      <Stack.Screen name="PuzzlePerfil" component={PuzzlePerfil} />
      <Stack.Screen name="PuzzleGran" component={PuzzleGran} />
      <Stack.Screen name="Game360" component={Game360} />
      <Stack.Screen name="Tours360" component={Tours360} />
      <Stack.Screen name="GaleriaImatge" component={GaleriaImatge} />
      <Stack.Screen name="CanjearGuads" component={CanjearGuads} />
      <Stack.Screen name="Trivial" component={Trivial} />
      <Stack.Screen name="PerfilScreen" component={Perfil} />
      <Stack.Screen name="Splash" component={SplashScreen} />
      <Stack.Screen name="MapTest" component={MapTest} />
    </Stack.Navigator>
  );
};

const TabNavigation = () => {
  const { tabsConfiguration } = useTabsConfiguration();

  return (
    <Tab.Navigator screenOptions={{ headerShown: false }}>
      {tabsConfiguration.map(({ title, name, component, imageURL }) => (
        <Tab.Screen
          key={name}
          name={name}
          options={{
            title,
            tabBarIcon: () => (
              <Image
                style={{ width: 25, height: 25, resizeMode: "contain" }}
                source={{
                  uri: imageURL,
                }}
              />
            ),
          }}
          component={component}
        />
      ))}
    </Tab.Navigator>
  );
};

const UnauthenticatedApp = () => {
  return (
    <Stack.Navigator
      initialRouteName="Splash"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="QRScreen" component={QRScreen} />
      <Stack.Screen name="IdiomaScreen" component={IdiomaScreen} />
      <Stack.Screen name="OnBoarding" component={OnBoardingScreen} />
      <Stack.Screen name="Signup" component={Signup} />
      <Stack.Screen name="LoginScreen" component={LoginScreen} />
      <Stack.Screen name="PWD1" component={PwdRecover1} />
      <Stack.Screen name="PWD2" component={PwdRecover2} />
      <Stack.Screen name="Splash" component={SplashScreen} />
    </Stack.Navigator>
  );
};
