import { getDestination } from "@/Components/Functions/MapFunctions";
import { useEffect } from "react";
import { useAuth } from "@/Components/Context/authContext";

export default function Trivial(RouterData: any) {
  const { poi, itinerari, punts } = RouterData.route.params;
  const { user, savePerfil } = useAuth();

  const goToNextPregunta = () => {
    const currentPreguntaIndex = RouterData.route.params.nextPreguntaIndex || 0;

    const pregunta = poi.preguntes[currentPreguntaIndex];

    if (!pregunta) {
      user.progres.itineraris[itinerari.id].pois[poi.id] = {
        id: poi.id,
        punts,
        dataihora: new Date().toISOString(),
      };

      savePerfil({
        progres: user.progres,
      });

      return RouterData.navigation.replace("Reward", {
        ...RouterData.route.params,
        esFinal: true,
      });
    }

    const destination = getDestination(pregunta.tipus);

    RouterData.navigation.replace(destination, {
      itinerari,
      poi,
      pregunta,
      nextPreguntaIndex: currentPreguntaIndex + 1,
      mostraPremi: poi.tipus !== "TrivialResum",
      punts: punts || 0,
    });
  };

  useEffect(() => {
    try {
      goToNextPregunta();
    } catch (error) {
      console.log(error);
    }
  }, []);
}
