import React, { useEffect, useState } from "react";
import {
  Dimensions,
  FlatList,
  Image,
  ImageBackground,
  Pressable,
  Text,
  View,
} from "react-native";
import Alert from "@/Components/Components/Alert";

import globalstyle from "../../../Styles/Globals/globals";
import constants from "@/config/constants";
//import LinearLayout from 'react-linear-layout';
import { LinearGradient } from "expo-linear-gradient";
import { perfilStyles } from "./PerfilStyle";
import QRModal from "../../Screens/Modals/EscanearQR";
import { useNavigation } from "@react-navigation/native";
import { useGlobal } from "../../Context/globalContext";
import api_urls from "@/config/api_urls";
import { useAuth } from "../../Context/authContext";
import ModalQRFail from "../Modals/ModalQRFail";
import ModalQRSuccess from "../Modals/ModalQRSuccess";

const { width } = Dimensions.get("window");

export default function CanjearGuads() {
  const { __, textRecurs, imatgeRecurs, projecte } = useGlobal();

  const navigation = useNavigation();

  const { user, totalPointsSum } = useAuth();
  const userid = user?.uid;

  const [modalQRVisible, setModalQRVisible] = useState(false);
  const [modalQRFailVisible, setModalQRFailVisible] = useState(false);
  const [modalQRSuccessVisible, setModalQRSuccessVisible] = useState(false);
  const [guadsCount, setGuadsCount] = useState(0);
  const [totalGuadsGastats, setTotalGuadsGastats] = useState(0);

  //TESTING - PASSAR A FALSE AL ACABAR
  const [confirmat, setConfirmat] = useState(false);

  //Guads per mostrar al QrSuccess
  const [guadsSuccess, setGuadsSuccess] = useState(0);

  //Mirar total de punts gastats per l'usuari
  useEffect(() => {
    const fetchData = async () => {
      try {
        const puntsGastatsResponse = await fetch(
          `${api_urls.api_url}/moviments-de-punts?persona=${userid}&confirmat=true`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          },
        );
        const puntsGastatsData = await puntsGastatsResponse.json();
        const puntsGastats = puntsGastatsData.reduce((sum: any, item: any) => {
          return sum + item.punts;
        }, 0);
        setTotalGuadsGastats(puntsGastats);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [modalQRVisible]);

  /*
    let guadsGastats = getFirebaseTotalPuntsGastats(user);
  
    useEffect(() => {
      console.log(guadsGastats);
    });*/

  useEffect(() => {
    if (
      firebaseData?.name ||
      firebaseData?.cognoms ||
      firebaseData?.telefon ||
      firebaseData?.data_naixement ||
      firebaseData?.provincia ||
      firebaseData?.municipi
    ) {
      setConfirmat(true);
    }
  });

  useEffect(() => {
    navigation.getParent()?.setOptions({
      tabBarStyle: {
        display: "none",
      },
    });
    return () =>
      navigation.getParent()?.setOptions({
        tabBarStyle: undefined,
      });
  }, [navigation]);

  const navigateToPerfil = () => {
    navigation.goBack();
  };

  /*
    const showResult = (success: boolean) => {
      console.log({ success });
      if (success) {
        setModalQRVisible(true);
      } else {
        setModalQRFailVisible(true);
      }
    };
  */
  const InfoPerfil = (itinerari: any) => {
    return (
      <View style={perfilStyles.cardCanjearGuadsContainer}>
        {ModalQRFail(modalQRFailVisible, setModalQRFailVisible)}
        {ModalQRSuccess(
          modalQRSuccessVisible,
          setModalQRSuccessVisible,
          guadsSuccess,
        )}
        <View>
          <View style={{ flexDirection: "column" }}>
            <Text
              style={{
                fontFamily: "GeorgiaBold",
                fontSize: 28,
                color: "white",
                textAlign: "center",
              }}
            >
              {textRecurs("canjear_guads")}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              flex: 1,
              padding: 10,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <LinearGradient
                colors={[constants.colorLinearGx, constants.colorLinearGy]}
                style={[
                  perfilStyles.smallContainer,
                  perfilStyles.marginContainer,
                ]}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 1 }}
              >
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <Text
                    style={{
                      marginRight: 5,
                      fontFamily: "Roboto",
                      fontSize: 14,
                      color: "#484848",
                    }}
                  >
                    {textRecurs("perfil_img4_texto1")}
                  </Text>
                  <Text
                    style={{
                      marginRight: 5,
                      fontFamily: "Poppins",
                      fontSize: 24,
                      color: "#484848",
                      fontWeight: "700",
                    }}
                  >
                    {totalPointsSum}
                  </Text>
                  <Text
                    style={{
                      marginRight: 5,
                      fontFamily: "Roboto",
                      fontSize: 14,
                      color: "#484848",
                    }}
                  >
                    {textRecurs("perfil_img4_guads")}
                  </Text>
                </View>
              </LinearGradient>
              <LinearGradient
                colors={[constants.colorLinearGx, constants.colorLinearGy]}
                style={perfilStyles.smallContainer}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 1 }}
              >
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <Text
                    style={{
                      marginRight: 5,
                      fontFamily: "Roboto",
                      fontSize: 14,
                      color: "#484848",
                    }}
                  >
                    {textRecurs("perfil_img4_texto2")}
                  </Text>
                  <Text
                    style={{
                      marginRight: 5,
                      fontFamily: "Poppins",
                      fontSize: 24,
                      color: "#484848",
                      fontWeight: "700",
                    }}
                  >
                    {totalGuadsGastats}
                  </Text>
                  <Text
                    style={{
                      marginRight: 5,
                      fontFamily: "Roboto",
                      fontSize: 14,
                      color: "#484848",
                    }}
                  >
                    {textRecurs("perfil_img4_guads")}
                  </Text>
                </View>
              </LinearGradient>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const TusGuads = () => {
    const incrementGuads = (amount: number) => {
      if (guadsCount >= 0) {
        setGuadsCount(guadsCount + amount);
      } else {
        setGuadsCount(0);
      }
    };

    const PuebloGuads = (itinerari: any) => {
      const { getFirebaseItinerari } = useAuth();
      const maxPoints = itinerari.pois.length * 100; // TODO ?
      const myItinerari = getFirebaseItinerari(itinerari.id);

      return (
        <>
          <View style={perfilStyles.separadorVerde} />
          <View style={perfilStyles.guadsPorMunicipio}>
            <View
              style={{ flexDirection: "row", alignItems: "center", gap: 8 }}
            >
              {/* {imatgeItinerari()} */}
              <Image
                source={{ uri: api_urls.api_url + itinerari.Imatge.url }}
                style={{ height: 20, width: 20 }}
              />
              <Text style={perfilStyles.guadsNombreMunicipio}>
                {__(itinerari, "nom")}
              </Text>
            </View>
            <Text style={perfilStyles.guadsCantidadPorMunicipio}>
              {myItinerari.totalPoints}/{maxPoints}
            </Text>
          </View>
        </>
      );
    };
    return (
      <View style={{ position: "absolute", bottom: 0 }}>
        <View
          style={{
            backgroundColor: "#F9F7E8",
            width: width,
            borderTopLeftRadius: 32,
            borderTopRightRadius: 32,
          }}
        >
          <View style={perfilStyles.overlayHeader}>
            <Text
              style={{
                fontFamily: "Poppins",
                fontWeight: "700",
                color: "#00491F",
                fontSize: 13,
                marginBottom: 10,
              }}
            >
              {textRecurs("tus_guads")}
            </Text>
            <Pressable
              style={{
                backgroundColor: "#7CC400",
                borderRadius: 4,
                paddingHorizontal: 8,
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  fontSize: 10,
                  padding: 4,
                  color: "#00491F",
                }}
              >
                {textRecurs("perfil_img4_info")}
              </Text>
            </Pressable>
          </View>
          <View style={{ flexDirection: "column" }}>
            <FlatList
              data={projecte.itineraris}
              renderItem={({ item }) => {
                return (
                  <View style={{ paddingHorizontal: 20 }}>
                    <PuebloGuads {...item} />
                  </View>
                );
              }}
              style={{ flexGrow: 1 }}
              keyExtractor={(item) => item.id}
            />
            <View style={perfilStyles.separadorVerde} />
            <View
              style={{
                flexDirection: "column",
                marginHorizontal: 30,
                paddingTop: 15,
                paddingBottom: 10,
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  fontSize: 16,
                  color: "#484848",
                }}
              >
                {textRecurs("perfil_cuantos_quieres_canjear")}
              </Text>
              {/* <Text
                style={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  color: "#484848",
                }}
              >
                {textRecurs("perfil_img4_texto2")}
              </Text> */}
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginHorizontal: 30,
                marginBottom: 25,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Pressable
                  style={perfilStyles.botonGuadsContainer}
                  onPress={() => incrementGuads(-10)}
                >
                  <Text style={perfilStyles.botonGuadsFont}>-</Text>
                </Pressable>
                <View style={perfilStyles.botonGuadsContainer}>
                  <Text
                    style={{
                      fontFamily: "Roboto",
                    }}
                  >
                    {guadsCount}
                  </Text>
                </View>
                <Pressable
                  style={perfilStyles.botonGuadsContainer}
                  onPress={() => incrementGuads(10)}
                >
                  <Text style={perfilStyles.botonGuadsFont}>+</Text>
                </Pressable>
              </View>
              <Pressable
                style={{
                  backgroundColor: "#00491F",
                  borderRadius: 12,
                  alignContent: "center",
                  justifyContent: "center",
                  paddingHorizontal: 25,
                }}
                onPress={() => {
                  if (guadsCount >= 20) {
                    if (!confirmat) {
                      Alert("", textRecurs("completa_perfil"));
                    } else if (
                      guadsCount >
                      totalPointsSum - totalGuadsGastats
                    ) {
                      Alert("", textRecurs("insuficient_guads"));
                    } else {
                      setModalQRVisible(!modalQRVisible);
                    }
                  } else {
                    Alert("", textRecurs("minimo_guads"));
                  }
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    fontSize: 12,
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  {textRecurs("perfil_img4_boton1")}
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
        {QRModal(
          modalQRVisible,
          setModalQRVisible,
          guadsCount,
          setGuadsSuccess,
          setModalQRFailVisible,
          setModalQRSuccessVisible,
        )}
      </View>
    );
  };
  return (
    <View style={globalstyle.containerBg}>
      <ImageBackground
        source={{}}
        style={{ ...globalstyle.backgroundImatges, alignItems: "center" }}
      >
        <View
          style={{
            alignSelf: "flex-start",
            marginLeft: 30,
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          <Pressable onPress={navigateToPerfil}>
            <Image
              source={{ uri: imatgeRecurs("boton_atras") }}
              style={{ height: 25, width: 25, marginTop: 40, marginBottom: 10 }}
            />
          </Pressable>
        </View>
        <InfoPerfil />
        <TusGuads />
      </ImageBackground>
    </View>
  );
}
