import api from "@/config/api_urls";

export function padNumber(num: number, numbers = 2) {
  return String(num).padStart(numbers, "0");
}

export const normalizeText = (text: string) => {
  if (text && text !== "") {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9]/g, "") // Allow numbers (0-9) along with letters
      .toLowerCase();
  } else {
    return "";
  }
};

export function postStadistic(data) {
  try {
    let formBody = [];
    for (let property in data) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return fetch(api.postEstadistiques, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
}

export const puntsPerItinerari = (itinerari: any): number => {
  // @ts-ignore
  return Object.values(itinerari.pois).reduce(
    (a: number, poi: any) => a + poi.punts,
    0,
  );
};

export const totalPunts = (itineraris: any) => {
  return Object.values(itineraris).reduce(
    (a: number, itinerari: any) => a + puntsPerItinerari(itinerari),
    0,
  );
};

export function generateRandomString(length: number = 10): string {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
