import React from "react";
import { Modal, StyleSheet, Text, Pressable, View } from "react-native";
import { useGlobal } from "../../Context/globalContext";

const ModalItinerariComplet = (
  modalCompletVisible: boolean,
  setModalCompletVisible: any,
) => {
  const { textRecurs } = useGlobal();

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalCompletVisible}
      onRequestClose={() => setModalCompletVisible(false)}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <View>
            <Text style={styles.modalText}>
              {textRecurs("rutas_img12_texto1")}
            </Text>
            <Text style={styles.modalSecondText}>
              {textRecurs("rutas_img12_texto2")}
            </Text>
          </View>
          <Pressable
            style={[styles.buttonStartAdventure]}
            onPress={() => setModalCompletVisible(false)}
          >
            <Text style={styles.buttonStartAdventureText}>
              {textRecurs("rutas_img12_boton1")}
            </Text>
          </Pressable>
          <Pressable onPress={() => setModalCompletVisible(false)}>
            <Text style={styles.goBack}>{textRecurs("general_cerrar")}</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 22,
    gap: 24,
    isolation: "isolate",
    width: "80%",
    height: "60%",
    backgroundColor:
      "linear-gradient(180deg, #FFFFFF 100%, rgba(255, 255, 255, 1) 100%), #BBE0C4",
    border: "1px solid #BBE0C4",
    borderRadius: 24,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    // fontFamily: 'Poppins'
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 24,
    textAlign: "center",
    color: "#484848",
  },
  modalSecondText: {
    // fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    textAlign: "center",
    color: "#484848",
  },
  buttonStartAdventure: {
    backgroundColor: "#2A6438",
    borderRadius: 32,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    gap: 10,
    width: "100%",
    height: "15%",
  },
  buttonStartAdventureText: {
    // fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    color: "#FFFFFF",
    letterSpacing: 0.4,
  },
  goBack: {
    // fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    textAlign: "center",
    color: "#484848",
    marginBottom: "10%",
  },
  closeButton: {
    position: "absolute",
    right: 23,
    top: 15.5,
  },
});

export default ModalItinerariComplet;
