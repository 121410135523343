import { Dimensions, StyleSheet } from "react-native";

const { width } = Dimensions.get("window");
const { height } = Dimensions.get("window");

export const tours360Styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    paddingHorizontal: 20,
  },
  cardContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: 150,
    backgroundColor: "#F9F7E8",
    marginTop: 10,
    borderRadius: 12,
  },
  whiteFilter: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: 12,
  },
  cardFont: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 13,
  },
  card: {
    alignItems: "center",
  },
  cardName: {
    fontFamily: "GeorgiaBold",
    fontSize: 20,
    color: "#3D3935",
  },
  heartContainer: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  button360: {
    width: 100,
    height: 32,
    backgroundColor: "#00491F",
    borderRadius: 6,
    marginTop: 16,
    padding: 5,
  },
  vrVectorStyle: { width: 21, height: 15 },
  buttonText: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: 12,
    color: "white",
  },
  categoriaFiltre: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
    paddingVertical: 6,
    paddingHorizontal: 8,
  },

  //Scroll lateral dintre Home

  cardScrollName: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: 13,
    fontWeight: "700",
    color: "white",
    padding: 12,
  },

  cardScrollContainer: {
    width: 128,
    height: 200,
    marginTop: 10,
    marginHorizontal: 5,
    borderRadius: 12,
  },
  buttonScroll360: {
    height: 32,
    backgroundColor: "#D0E2D5",
    opacity: 0.8,
    borderRadius: 6,
    padding: 5,
    margin: 12,
  },
  buttonScrollContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f7fa",
  },
  modalCardContainer: {
    flexDirection: "column",
    borderRadius: 12,
    backgroundColor: "#F9F7E8",
    marginTop: 10,
    alignItems: "flex-start",
    width: "92%",
  },
  iframeContainer: {
    flex: 1,
    width: "100%",
  },
  iframe: {
    height: "100%",
    width: "100%",
  },
  closeButton: {
    position: "absolute",
    right: 23,
    top: 15.5,
  },
});
