import { Audio } from "expo-av";

const checkErrors = (errors: number) => {
  if (errors === 0) {
    return 100; // TODO ?
  } else if (errors === 1) {
    return 75;
  } else if (errors === 2) {
    return 50;
  } else if (errors === 3) {
    return 25;
  }
  return 0;
};

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

const getDistanceFromLatLonInMeters = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) => {
  let R = 6371; // Radi de la terra en kilometres
  let dLat = deg2rad(lat2 - lat1);
  let dLon = deg2rad(lon2 - lon1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c; // Distancia en kilometres
  return d * 1000; // Distancia en metres
};

const getDestination = (tipus: string) => {
  switch (tipus) {
    case "Joc360":
      return "Game360";
    case "Info":
    case "PuntFantasma":
    case "CheckIn":
      return "JocCheckIn";
    case "Paraula (escriure)":
      return "JocEscriureParaula";
    case 'Paraula ("ahorcado")':
      return "Game360";
    case "Imatges (seleccionar una)":
      return "QuizzImages";
    case "Paraules (seleccionar una)":
      return "Quizz";
    case "Puzle":
      // return "JocPuzle";
      return "JocPuzleJigsaw";
    case "DragAndDrop":
      return "DragAndDrop";
    case "Trivial":
    case "TrivialResum":
      return "Trivial";
    default:
      console.warn("Error: joc not found");
      return null; // Return null if the case is not found
  }
};

const fromTextToIndex = (text: string) => {
  switch (text) {
    case "Primera":
      return 1;
    case "Segona":
      return 2;
    case "Tercera":
      return 3;
    case "Quarta":
      return 4;
  }
};

const secondsToDatetimeString = (seconds: number = 30) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedMinutes = minutes.toString();
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
};

const adjustTime = (time: string, currentTime: number) => {
  const timeArray = time.split(":");
  let minutes = parseInt(timeArray[0]);
  let seconds = parseInt(timeArray[1]);

  const now = new Date().getTime();
  const timeDifferenceMillis = now - currentTime;
  let timeDifferenceSeconds = Math.floor(timeDifferenceMillis / 1000);

  while (timeDifferenceSeconds >= 60) {
    minutes -= 1;
    timeDifferenceSeconds -= 60;
  }

  if (seconds > 0) {
    seconds -= timeDifferenceSeconds;

    while (seconds < 0) {
      seconds += 60;
      minutes -= 1;
    }
  } else {
    minutes -= 1;
    seconds = 59;
  }

  return `${minutes}:${seconds < 10 ? 0 : ""}${seconds}`;
};

const playSoundForAWhile = async (
  uri,
  time = 0,
  setSound = (_) => {},
  options = {},
) => {
  if (!uri) return;

  try {
    const { sound } = await Audio.Sound.createAsync(
      { uri },
      {
        shouldPlay: true,
        isLooping: false,
        volume: 0.5,
        ...options,
      },
    );

    setSound?.(sound);

    if (time) {
      setTimeout(() => {
        sound?.stopAsync();
        sound?.unloadAsync();
      }, time);
    }
  } catch (error) {}
};

const loadSound = async (
  soundUrl,
  setSound = (_) => {},
  setStatus = undefined,
) => {
  if (!soundUrl) return;

  try {
    const { sound, status } = await Audio.Sound.createAsync(
      { uri: soundUrl },
      {
        shouldPlay: true,
        isLooping: false,
        volume: 0.5,
      },
    );
    setSound(sound);
    // @ts-ignore
    setStatus?.(status);
  } catch (error) {
    console.log(error);
  }
};

export {
  checkErrors,
  getDistanceFromLatLonInMeters,
  getDestination,
  fromTextToIndex,
  secondsToDatetimeString,
  adjustTime,
  playSoundForAWhile,
  loadSound,
};
