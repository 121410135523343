import React from "react";
import {
  ActivityIndicator,
  Modal,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
} from "react-native";
import { Image } from "expo-image";
import { ResizeMode, Video } from "expo-av";

import { complicesStyles } from "@/Components/Screens/Recursos/RecursosStyle";
import { useGlobal } from "../../Context/globalContext";
import Markdown from "react-native-markdown-display";
import useMediaType from "@/hooks/useMediaType";

const ModalPremi = ({
  modalPremiVisible,
  setModalPremiVisible,
  premi,
  itinerari,
  callback,
  isLoading,
}) => {
  const { __, textRecurs } = useGlobal();

  const source: string = __(premi, "multimedia")?.url;

  const MediaDisplay = ({ source }) => {
    const mediaType = useMediaType(source);

    if (mediaType === "image") {
      return (
        <Image
          placeholder={require("@/assets/Images/loading.gif")}
          source={{ uri: source }}
          style={style.commonStyle}
          contentFit={"contain"}
        />
      );
    }

    if (mediaType === "video") {
      return (
        <Video
          source={{ uri: source }}
          style={style.commonStyle}
          useNativeControls
          resizeMode={ResizeMode.CONTAIN}
          rate={1.0}
          volume={1.0}
          isMuted={false}
          shouldPlay={true}
          videoStyle={{
            width: "100%",
            height: "100%",
          }}
        />
      );
    }

    if (mediaType === "none") {
      return;
    }

    return <ActivityIndicator style={style.commonStyle} />;
  };

  return (
    <Modal
      transparent={true}
      visible={modalPremiVisible}
      onRequestClose={() => setModalPremiVisible(false)}
    >
      <View style={complicesStyles.modalContainer}>
        <View
          style={{
            flexDirection: "column",
            borderRadius: 12,
            backgroundColor: "#F9F7E8",
            marginTop: 10,
            alignItems: "flex-start",
            width: "92%",
            padding: 10,
          }}
        >
          <View
            style={{
              width: "100%",
            }}
          >
            {__(premi, "popup_titol") && (
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  fontSize: 20,
                  textAlign: "center",
                }}
              >
                {__(premi, "popup_titol")}
              </Text>
            )}

            <Markdown
              style={{
                body: {
                  fontSize: 16,
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              {__(premi, "popup_text") || " "}
            </Markdown>
          </View>

          <MediaDisplay source={source} />

          <TouchableOpacity
            style={{
              backgroundColor: itinerari.color_fons2,
              width: 250,
              borderRadius: 6,
              marginTop: 10,
              alignSelf: "center",
            }}
            onPress={callback}
          >
            {isLoading ? (
              <ActivityIndicator />
            ) : (
              <Text
                style={{
                  color: "white",
                  textAlign: "center",
                  padding: 20,
                  fontFamily: "Roboto",
                  fontWeight: "700",
                }}
              >
                {textRecurs("perfil_guardar")}
              </Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ModalPremi;

const style = StyleSheet.create({
  commonStyle: {
    width: "100%",
    height: 250,
    aspectRatio: 1,
    borderRadius: 12,
    marginVertical: 10,
    alignSelf: "center",
  },
});
