import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { FloatingLabelInput } from "react-native-floating-label-input";

import constants from "@/config/constants";
import { useGlobal } from "../../Context/globalContext";

export default function PwdRecover1(RouterData: any) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const { textRecurs, projecte } = useGlobal();

  const navigateToPwd2 = async () => {
    if (email.trim() === "") {
      return setEmailError(textRecurs("acceso_correo_electronico_pista"));
    }

    setEmailError("");

    RouterData.navigation.navigate("PWD2", {
      ...RouterData.route.params,
    });
  };

  const navigateToLogin = () => {
    RouterData.navigation.goBack();
  };

  return (
    <View style={{ backgroundColor: constants.colorBgLogin }}>
      <View style={style.mainContainer}>
        <Text style={{ ...style.mainTitle, color: projecte.color_principal }}>
          {textRecurs("acceso_recuperar_contrasena")}
        </Text>

        <Text style={style.infoText}>
          {textRecurs("acceso_recuperar_contrasena_texto")}
        </Text>

        <FloatingLabelInput
          label={textRecurs("correo_electronico")}
          hint={textRecurs("acceso_correo_electronico_pista")}
          value={email}
          staticLabel
          containerStyles={style.input}
          customLabelStyles={{
            colorFocused: projecte.color_principal,
            fontSizeFocused: 10,
          }}
          labelStyles={{
            paddingTop: 21,
            paddingLeft: 4,
            fontSize: 10,
            fontWeight: "400",
          }}
          inputStyles={{
            color: projecte.color_principal,
            fontSize: 16,
            fontWeight: "400",
          }}
          onChangeText={(value) => setEmail(value)}
        />

        {emailError !== "" && (
          <Text
            style={{
              fontFamily: "Poppins",
              fontWeight: "700",
              fontSize: 13,
              lineHeight: 12,
              letterSpacing: 0.64,
              color: "red",
              textAlign: "left",
              paddingLeft: 23,
              paddingTop: 6,
            }}
          >
            {emailError}
          </Text>
        )}

        <TouchableOpacity
          style={{
            ...style.btnLogin,
            backgroundColor: projecte.color_principal,
          }}
          onPress={navigateToPwd2}
        >
          <Text
            style={{
              color: constants.colorWhite,
              letterSpacing: 0.8,
              fontSize: 16,
              fontWeight: "700",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {textRecurs("acceso_restablecer_contrasena")}
          </Text>
        </TouchableOpacity>

        <Text
          onPress={navigateToLogin}
          style={{ ...style.links, color: projecte.color_principal }}
        >
          {textRecurs("general_volver")}
        </Text>
      </View>
    </View>
  );
}

const style = StyleSheet.create({
  //TODO: labelStyles green not working!
  //TODO: Keyboard style!
  //TODO: on click: change borderColor

  mainContainer: {
    flex: 0,
    paddingTop: 80,
    marginBottom: 493,
    paddingHorizontal: 24,
    // backgroundColor: constants.colorBgLogin,
    AlignItems: "center",
  },

  mainTitle: {
    fontFamily: "Georgia",
    flex: 0,
    fontSize: 32,
    fontWeight: "700",
    lineHeight: 38.4,
  },
  infoText: {
    fontFamily: "Roboto",
    flex: 0,
    fontSize: 16,
    fontWeight: "400",
    color: constants.colorBlackText,
    marginTop: 8,
    //marginBottom:26,
    textAlign: "justify",
    // width: 327,
    height: 66,
    // marginLeft:24,
    lineHeight: 22.4,
  },
  input: {
    fontFamily: "Roboto",
    flex: 0,
    width: 327,
    height: 47,
    marginTop: 12,
    // marginHorizontal:24,
    paddingLeft: 24,
    paddingTop: 5,
    borderWidth: 1,
    borderRadius: 32,
    borderColor: constants.colorGreenDarkBtn,
    backgroundColor: constants.colorWhite,
    fontSize: 16,
    color: constants.colorGreenDarkBtn,
  },
  btnLogin: {
    fontFamily: "Roboto",
    // width: 327,
    height: 56,
    marginTop: 24,
    // marginHorizontal:24,
    // marginBottom:40,
    // paddingHorizontal:10,
    paddingVertical: 12,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: constants.colorGreenDarkBtn,
    borderRadius: 8,
    fontSize: 16,
  },
  links: {
    color: constants.colorGreenDarkBtn,
    fontWeight: "700",
    margin: 5,
    textAlign: "center",
    letterSpacing: 0.64,
    paddingTop: 50,
  },
});
