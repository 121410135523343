import React, { useContext, useEffect } from "react";
import useProjectData from "@/Components/Hooks/useProjectData";
import useIdioma from "@/Components/Hooks/useIdioma";
import useAudioPlayer from "@/Components/Hooks/useAudioPlayer";
import { IRecurs } from "@/types/projecteTypes";
import { Platform } from "react-native";

const GlobalContext = React.createContext<any | null>(null);

const GlobalProvider = ({ children }: { children: JSX.Element }) => {
  const { isLoading, projecte } = useProjectData();

  const { idioma, guardarIdioma } = useIdioma();
  const { isPlaying, togglePlayback } = useAudioPlayer();

  const __ = (object: any, key: string): string => {
    if (!object) {
      return "";
    }

    const locale = idioma ?? "ES";

    return object[`${key}_${locale}`] || object[key];
  };

  /**
   * Agafa la traducció d'un text amb paràmetres opcionals:
   * "Vull {0} i {1}."
   *
   * @param tipus
   * @param replacements
   * @param emptyValue
   */
  const textRecurs = (
    tipus: string,
    replacements: string[] = [],
    emptyValue: string | undefined = undefined,
  ): string => {
    let text =
      __(
        projecte.textos.find((e: IRecurs) => e.tipus === tipus),
        "text",
      ) || tipus;

    if (text === tipus && emptyValue !== undefined) {
      return emptyValue;
    }

    // Replace placeholders with values from the replacements array
    replacements.forEach((replacement, index) => {
      text = text.replace(new RegExp(`\\{${index}\\}`, "g"), replacement);
    });

    return text;
  };

  const imatgeRecurs = (tipus: string): string => {
    return projecte.multimedies.find((e: IRecurs) => e.tipus === tipus)
      ?.multimedia?.url;
  };

  useEffect(() => {
    if (Platform.OS === "web") {
      const script = document.createElement("script");
      script.src = "https://static.kuula.io/api.js";
      document.body.appendChild(script);
    }
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        projecte,
        __,
        textRecurs,
        imatgeRecurs,
        guardarIdioma,
        isPlaying,
        togglePlayback,
        isLoading,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobal = (): any => useContext(GlobalContext);

export { GlobalProvider, useGlobal };
