import React from "react";
import { Modal, Pressable, View, Text, Image, ScrollView } from "react-native";
import { perfilStyles } from "../Perfil/PerfilStyle";
import { useGlobal } from "../../Context/globalContext";
import Markdown from "react-native-markdown-display";

const InfoModal = (
  itinerari: any,
  modalInfoVisible: boolean,
  setModalInfoVisible: any,
) => {
  const { textRecurs, imatgeRecurs } = useGlobal();

  return (
    <Modal
      transparent={true}
      visible={modalInfoVisible}
      onRequestClose={() => setModalInfoVisible(false)}
    >
      <Pressable
        style={perfilStyles.modalContainer}
        onPress={() => setModalInfoVisible(false)}
      >
        <View style={perfilStyles.modalCardContainer}>
          <View
            style={{ paddingHorizontal: 16, paddingTop: 16, paddingBottom: 24 }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "95%",
              }}
            >
              <Text style={perfilStyles.tituloInfoModal}>
                {textRecurs("general_informacion")}
              </Text>
              <Pressable onPress={() => setModalInfoVisible(false)}>
                <Image
                  source={{ uri: imatgeRecurs("boton_cerrar") }}
                  style={{ width: 13, height: 13 }}
                />
              </Pressable>
            </View>
            <View
              style={{
                flexDirection: "column",
                width: "95%",
                marginTop: 40,
              }}
            >
              <ScrollView>
                <Markdown>{itinerari.info1 || " "}</Markdown>
                <View style={perfilStyles.separadorBlanco} />
                <Markdown>{itinerari.info2 || " "}</Markdown>
                <View style={perfilStyles.separadorBlanco} />
              </ScrollView>
            </View>
          </View>

          <Pressable
            style={{
              width: "80%",
              backgroundColor: itinerari.color_fons2,
              alignSelf: "center",
              borderRadius: 6,
              paddingVertical: 20,
            }}
            onPress={() => setModalInfoVisible(false)}
          >
            <Text
              style={{
                textAlign: "center",
                fontFamily: "Roboto",
                fontWeight: "700",
                fontSize: 16,
                color: "white",
              }}
            >
              {textRecurs("general_cerrar")}
            </Text>
          </Pressable>
        </View>
      </Pressable>
    </Modal>
  );
};

export default InfoModal;
