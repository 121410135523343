import React, { useState } from "react";
import {
  Image,
  Linking,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import constants from "@/config/constants";
import { useGlobal } from "../Context/globalContext";
import { useAuth } from "@/Components/Context/authContext";
import ModalLeerMas from "@/Components/Screens/Modals/ModalLeerMas";
import Toast from "react-native-toast-message";

interface HeaderCardProps {
  itinerari: any;
}

export default function HeaderCard({ itinerari }: HeaderCardProps) {
  const { __, textRecurs, imatgeRecurs, projecte } = useGlobal();
  const { user } = useAuth();
  const [modalLeerMas, setModalLeerMas] = useState(false);

  const myItinerari = user.progres.itineraris[itinerari.id] ?? {
    pois: {},
  };

  const itinerariCompletat: boolean =
    Object.values(myItinerari?.pois || {}).length === itinerari.pois.length;

  const transportMap = {
    Caminant: "transport_caminant",
    Bicicleta: "transport_bicicleta",
    Cotxe: "transport_cotxe",
  };

  const tipus = transportMap[itinerari.transport] ?? "transport_caminant";

  const uri = projecte.multimedies.find((e: any) => e.multimedia == tipus)?.[
    "multimedia"
  ].url;

  const handleOnPlayComoLlegar = () => {
    const { latitud, longitud } = itinerari;
    const label = __(itinerari, "nom");

    const scheme = Platform.select({
      ios: "maps://0,0?q=",
      android: "geo:0,0?q=",
      web: "https://www.google.com/maps/search/?api=1&query=",
    });

    const url = Platform.select({
      ios: `${scheme}${label}@${latitud},${longitud}`,
      android: `${scheme}${latitud},${longitud}(${label})`,
      web: `${scheme}${latitud},${longitud}`,
    });

    if (url) {
      Linking.openURL(url);
    }
  };

  const handleBaixarFitxer = () => {
    const url = __(itinerari, "fitxer")?.url;

    if (url) {
      Linking.openURL(url);
    }
  };

  const handleDownloadTrack = () => {
    Toast.show({
      type: "success",
      text1: textRecurs("itinerario_ruta_bajando"),
    });

    setTimeout(
      () => {
        Toast.show({
          type: "success",
          text1: textRecurs("itinerario_ruta_bajada"),
        });
      },
      Math.random() * 10 * 1000,
    );
  };

  const handleShowInfoAtributs = () => {
    setModalLeerMas(true);
  };

  const renderButtonPois = () => {
    if (!itinerariCompletat) {
      return (
        <Pressable
          style={{
            ...style.containerBtnOrange,
            backgroundColor: itinerari.color_fons1,
          }}
        >
          <Text style={style.textBtn}>{textRecurs("poi_pendiente")}</Text>
        </Pressable>
      );
    }

    return (
      <Pressable style={style.containerBtnGreen}>
        <Text style={style.textBtn}>{textRecurs("poi_resuelto")}</Text>
      </Pressable>
    );
  };

  return (
    <View
      style={{ flexGrow: 1, flexDirection: "row", height: 80, maxHeight: 80 }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingHorizontal: 15,
          paddingTop: 20,
        }}
      >
        <View>{renderButtonPois()}</View>
        <View
          style={{
            flexGrow: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <View style={style.containerDret}>
            {!!__(itinerari, "track") && (
              <TouchableOpacity onPress={handleDownloadTrack}>
                <Image
                  source={{ uri: imatgeRecurs("itinerari_baixar_ruta") }}
                  style={{ height: 30, width: 30 }}
                />
              </TouchableOpacity>
            )}
            {itinerari.atributs.length > 0 && (
              <TouchableOpacity onPress={handleShowInfoAtributs}>
                <Image
                  source={{ uri: imatgeRecurs("itinerari_info_atributs") }}
                  style={{ height: 30, width: 30 }}
                />
              </TouchableOpacity>
            )}
            <TouchableOpacity onPress={handleOnPlayComoLlegar}>
              <Image
                source={{ uri: imatgeRecurs("boto_como_llegar") }}
                style={{ width: 30, height: 30 }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={handleBaixarFitxer}>
              <Image
                source={{ uri: imatgeRecurs("general_url") }}
                style={{ width: 30, height: 30 }}
              />
            </TouchableOpacity>
          </View>
          <View style={style.containerDret}>
            <Image
              source={{ uri }}
              style={{ height: 13, width: 15, marginLeft: 12 }}
            />
            <Text>{__(itinerari, "dificultat")}</Text>
          </View>
        </View>
      </View>
      {ModalLeerMas(modalLeerMas, setModalLeerMas, itinerari)}
    </View>
  );
}

const style = StyleSheet.create({
  navContainerTitle: {
    flexGrow: 1,
    //alignItems:'center',
    //alignContent:'center'
  },
  containerBtnOrange: {
    color: constants.colorWhite,
    //alignItems: 'center',
    //justifyContent: 'center',
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderRadius: 8,
    backgroundColor: constants.colorOrange,
    alignContent: "center",
    //marginRight: 15,
  },
  containerBtnGreen: {
    color: constants.colorWhite,
    //alignItems: 'center',
    //justifyContent: 'center',
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderRadius: 8,
    backgroundColor: "rgba(124, 196, 0, 1)",
    alignContent: "center",
    //marginRight: 15,
  },
  containerDret: {
    color: constants.colorWhite,
    borderRadius: 8,
    alignContent: "center",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  textBtn: {
    fontFamily: "Roboto",
    color: constants.colorWhite,
    textAlign: "center",
    fontSize: 12,
    fontWeight: "500",
    letterSpacing: 0.8,
    lineHeight: 12,
    alignContent: "center",
    alignItems: "center",
    //backgroundColor:'green'
  },
});
