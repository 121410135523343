import React from "react";
import ModalBase from "@/Components/Screens/Modals/ModalBase";
import PerfilPremis from "@/Components/Components/Itinerari/PerfilPremis";
import { useGlobal } from "@/Components/Context/globalContext";

const ModalPremis = ({
  setModalVisible,
  itinerari,
}: {
  setModalVisible: (visible: boolean) => void;
  itinerari: any;
}) => {
  const { textRecurs } = useGlobal();

  return (
    <ModalBase
      setModalVisible={setModalVisible}
      title={textRecurs("general_imagenes")}
    >
      <PerfilPremis {...itinerari} />
    </ModalBase>
  );
};

export default ModalPremis;
