import React from "react";
import { View, StyleSheet, SafeAreaView } from "react-native";
import constants from "@/config/constants";
import { useNavigation } from "@react-navigation/native";
import globalstyle from "../../Styles/Globals/globals";

import CarouselGaleriaImatges from "./CarouselGaleriaImatges";
import HeaderButtonsGaleria from "../Components/HeaderButtonsGaleria";

export default function GaleriaImatge({ route }) {
  const navigation = useNavigation();

  const { itinerari } = route.params;

  return (
    <SafeAreaView style={globalstyle.container}>
      <View style={{ flexGrow: 1, backgroundColor: "black" }}>
        <View style={globalstyle.containerBg}>
          <HeaderButtonsGaleria />
          <CarouselGaleriaImatges {...itinerari} />
        </View>
      </View>
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  rutaImatgeContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    //borderRadius: 12,
    // width: "85%",
    // height: "35%",
    backgroundColor: constants.colorCardBg,
    //marginTop: 50,
    //paddingTop: 10,
    // gap:24,
  },
});
