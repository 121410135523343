const POI_TYPES = {
  available: "poi_disponible",
  current: "poi_actual",
  start: "poi_inici",
  completedStart: "poi_inici_realitzat",
  completed: "poi_realitzat",
  end: "poi_final",
  completedEnd: "poi_final_realitzat",
};

export function getPoiImage(
  multimedia,
  index,
  isCompleted,
  currentPoiIndex,
  itinerari,
) {
  // Helper function to fetch URL for a given POI type
  const getUrlForType = (type) => {
    const item = multimedia.find((element) => element.tipus === type);
    return item?.multimedia?.url || "defaultImageUrl"; // TODO Provide a default URL or handle error
  };

  // Determine the type of POI based on its index and completion status
  const determinePoiType = () => {
    if (index === 0) {
      return isCompleted ? POI_TYPES.completedStart : POI_TYPES.start;
    }

    if (index === itinerari.pois.length - 1) {
      return isCompleted ? POI_TYPES.completedEnd : POI_TYPES.end;
    }

    if (index === currentPoiIndex) {
      return POI_TYPES.current;
    }

    return isCompleted ? POI_TYPES.completed : POI_TYPES.available;
  };

  return getUrlForType(determinePoiType());
}
