import React from "react";
import { View, StyleSheet, Pressable, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import constants from "@/config/constants";
import { useGlobal } from "@/Components/Context/globalContext";

export default function HeaderButtonsPerfil({ goHome = false }) {
  const { imatgeRecurs } = useGlobal();
  const navigation = useNavigation<any>();

  return (
    <View
      style={{ flexGrow: 1, flexDirection: "row", height: 50, maxHeight: 50 }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          paddingHorizontal: 15,
          paddingTop: 20,
        }}
      >
        <View style={style.navContainerTitle}>
          <Pressable onPress={() => navigation.navigate("Tabs")}>
            <Image
              source={{ uri: imatgeRecurs("boton_atras") }}
              style={{ height: 30, width: 30 }}
            />
          </Pressable>
        </View>
      </View>
    </View>
  );
}

const style = StyleSheet.create({
  navContainerTitle: {
    flexGrow: 1,
  },
  containerBtnOrange: {
    color: constants.colorWhite,
    //alignItems: 'center',
    //justifyContent: 'center',
    //paddingVertical: 8,
    //paddingHorizontal: 5,
    borderRadius: 8,
    // backgroundColor: 'red',
    alignContent: "center",
    //marginRight: 15,
  },
  textBtn: {
    //fontStyle:'Roboto',
    color: constants.colorWhite,
    textAlign: "center",
    fontSize: 12,
    fontWeight: "700",
    letterSpacing: 0.8,
    lineHeight: 12,
    alignContent: "center",
    alignItems: "center",
    //backgroundColor:'green'
  },
});
