import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

const useAsyncStorage = (
  key: string,
  initialValue: unknown
): [
  unknown,
  (value: unknown) => Promise<void>,
  { retrievedFromStorage: boolean; isLoading: boolean }
] => {
  const [data, setData] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(true);
  const [retrievedFromStorage, setRetrievedFromStorage] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const value = await AsyncStorage.getItem(key);
        setData(value ? JSON.parse(value) : initialValue);
        setRetrievedFromStorage(true);
        setIsLoading(false);
      } catch (error) {
        console.error("useAsyncStorage getItem error:", error);
      }
    })();
  }, [key, initialValue]);

  const setNewData = async (value: unknown) => {
    try {
      await AsyncStorage.setItem(key, JSON.stringify(value));
      setData(value);
    } catch (error) {
      console.error("useAsyncStorage setItem error:", error);
    }
  };

  return [data, setNewData, { retrievedFromStorage, isLoading }];
};

export default useAsyncStorage;
