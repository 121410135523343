import * as React from "react";
import * as Font from "expo-font";
import { AuthProvider } from "@/Components/Context/authContext";
import AppNavigation from "@/Components/Components/AppNavigation";
import { GlobalProvider } from "@/Components/Context/globalContext";
import { StatusBar, View } from "react-native";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Toast from "react-native-toast-message";

export default function App() {
  const queryClient = new QueryClient();

  return (
    Font.loadAsync({
      LeckerliOne: require("@/assets/Fonts/LeckerliOne-Regular.ttf"),
      Poppins: require("@/assets/Fonts/Poppins-Regular.ttf"),
      Avenir: require("@/assets/Fonts/AvenirLTStd-Book.otf"),
      Georgia: require("@/assets/Fonts/Georgia.ttf"),
      GeorgiaBold: require("@/assets/Fonts/georgia-bold.ttf"),
      Roboto: require("@/assets/Fonts/Roboto-Regular.ttf"),
    }),
    (
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
          <AuthProvider>
            <View
              style={{
                flex: 1,
              }}
            >
              {/*<StatusBar translucent backgroundColor="#163F15"/>*/}
              <StatusBar translucent hidden />
              <AppNavigation />
              <Toast />
            </View>
          </AuthProvider>
        </GlobalProvider>
      </QueryClientProvider>
    )
  );
}
