import React from "react";
import { Pressable, StyleSheet, Text } from "react-native";

export default function Button({
  title,
  onPress,
}: {
  title: string;
  onPress: any;
}) {
  return (
    <Pressable style={styles.button} onPress={onPress}>
      <Text style={styles.textButton}>{title}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    flex: 1,
    flexBasis: "25%",
    alignSelf: "center",
    alignItems: "center",
    borderRadius: 12,
    backgroundColor: "rgba(255,255,255,0.3)",
    padding: 10,
    marginHorizontal: 10,
    marginVertical: 5,
    flexGrow: 0,
  },
  textButton: {
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: 20,
    fontWeight: "700",
    letterSpacing: 0.25,
    color: "#FFFFFF",
  },
});
