import React, { useState } from "react";
import { Image, Pressable, Text, TouchableOpacity, View } from "react-native";
import { useAuth } from "../../Context/authContext";
import AuthAppShell from "../../Components/AuthAppShell";
import constants from "@/config/constants";
import ModalPrivacy from "../Modals/ModalPrivacy";
import ModalCookies from "../Modals/ModalCookies";
import { useNavigation } from "@react-navigation/native";
import { useGlobal } from "../../Context/globalContext";
import { API_URL } from "@/config/config";
import HeaderComponent from "@/Components/Components/HeaderComponent";
import Constants from "expo-constants";
import Alert from "@/Components/Components/Alert";

export default function Settings() {
  const { textRecurs, imatgeRecurs, projecte } = useGlobal();

  const { logout, user } = useAuth();
  const [modalVisiblePrivacy, setModalVisiblePrivacy] = useState(false);
  const [modalVisibleCookies, setModalVisibleCookies] = useState(false);
  const navigation = useNavigation<any>();

  const handleLogout = () => {
    logout();
  };

  const donarDeBaixa = async () => {
    try {
      const formData = new URLSearchParams();
      const NOW = new Date();
      formData.append("persona", user.id);
      formData.append("dataihora", NOW.toISOString());

      const response = await fetch(`${API_URL}/sollicitudsdebaixas`, {
        method: "POST",
        body: formData.toString(),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.status === 200) {
        Alert(textRecurs("baja_exito_titulo"), textRecurs("baja_exito_texto"));
      } else {
        Alert(textRecurs("baja_error_titulo"), textRecurs("baja_error_text"));
      }
    } catch (error: any) {
      Alert("Error en la crida a l'API: " + error.message);
    }
  };

  const Botoconfirmacio = () => {
    if (projecte.tipus_login === "Senzill") {
      return;
    }
    const createTwoButtonAlert = () => {
      Alert(
        `${textRecurs("baja_titulo")}`,
        `${textRecurs("baja_texto")}`,
        [
          {
            text: `${textRecurs("confirmar")}`,
            onPress: () => donarDeBaixa(),
            style: "destructive",
          },
          {
            text: `${textRecurs("general_cancelar")}`,
            onPress: () => console.log("Cancelado"),
            style: "cancel",
          },
        ],
        { cancelable: false },
      );
    };

    return (
      <Pressable
        style={{
          flexDirection: "row",
          marginHorizontal: 29,
        }}
        onPress={createTwoButtonAlert}
      >
        <Image
          source={{ uri: imatgeRecurs("perfil_perfil") }}
          style={{ width: 18, height: 18 }}
        />
        <Text
          style={{
            color: constants.colorWhite,
            marginLeft: 20,
            fontFamily: "Georgia",
            fontWeight: "700",
            fontSize: 20,
            lineHeight: 24,
            letterSpacing: 0.64,
          }}
        >
          {textRecurs("baja_boton")}
        </Text>
      </Pressable>
    );
  };

  return (
    <AuthAppShell>
      <HeaderComponent
        title={textRecurs("general_menu")}
        showGoHome={true}
        showSettings={false}
      />

      <View style={{ flex: 1, marginVertical: 40 }}>
        <View style={{ marginBottom: 50, gap: 30 }}>
          <TouchableOpacity
            style={{ flexDirection: "row", marginHorizontal: 29 }}
            onPress={() => navigation.navigate("EditarPerfil")}
          >
            <Image
              source={{ uri: imatgeRecurs("perfil_editar") }}
              style={{ width: 18, height: 18 }}
            />
            <Text
              style={{
                color: constants.colorWhite,
                marginLeft: 20,
                fontFamily: "Georgia",
                fontWeight: "700",
                fontSize: 20,
                lineHeight: 24,
                letterSpacing: 0.64,
              }}
            >
              {textRecurs("perfil_editar")}
            </Text>
          </TouchableOpacity>
          {projecte.tipus_login !== "Senzill" && (
            <>
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  marginHorizontal: 29,
                }}
                onPress={() => setModalVisiblePrivacy(true)}
              >
                <Image
                  source={{ uri: imatgeRecurs("perfil_privacitat") }}
                  style={{ width: 18, height: 18 }}
                />
                <Text
                  style={{
                    color: constants.colorWhite,
                    marginLeft: 20,
                    fontFamily: "Georgia",
                    fontWeight: "700",
                    fontSize: 20,
                    lineHeight: 24,
                    letterSpacing: 0.64,
                  }}
                >
                  {textRecurs("aviso_privacidad")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  marginHorizontal: 29,
                }}
                onPress={() => setModalVisibleCookies(true)}
              >
                <Image
                  source={{ uri: imatgeRecurs("perfil_privacitat") }}
                  style={{ width: 18, height: 18 }}
                />
                <Text
                  style={{
                    color: constants.colorWhite,
                    marginLeft: 20,
                    fontFamily: "Georgia",
                    fontWeight: "700",
                    fontSize: 20,
                    lineHeight: 24,
                    letterSpacing: 0.64,
                  }}
                >
                  {textRecurs("aviso_legal")}
                </Text>
              </TouchableOpacity>
            </>
          )}
          {/*TODO*/}
          {/*<View*/}
          {/*  style={{*/}
          {/*    flexDirection: "row",*/}
          {/*    marginHorizontal: 29,*/}
          {/*    marginVertical: 15,*/}
          {/*    alignItems: "center",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Image*/}
          {/*    source={require("../../../assets/Images/musicIcon.png")}*/}
          {/*    style={{ width: 18, height: 25 }}*/}
          {/*  />*/}
          {/*  <Switch*/}
          {/*    value={isPlaying}*/}
          {/*    onValueChange={togglePlayback}*/}
          {/*    thumbColor={isPlaying ? "white" : "gray"}*/}
          {/*  />*/}
          {/*</View>*/}
          <Botoconfirmacio />
        </View>

        <View
          style={{
            marginHorizontal: 29,
          }}
        >
          <TouchableOpacity
            onPress={handleLogout}
            style={{ flexDirection: "row" }}
          >
            <Image
              source={{ uri: imatgeRecurs("prefil_cerrar_sesion") }}
              style={{ width: 18, height: 18 }}
            />
            <Text
              style={{
                color: constants.colorWhite,
                marginLeft: 20,
                fontFamily: "Georgia",
                fontWeight: "700",
                fontSize: 20,
                lineHeight: 24,
                letterSpacing: 0.64,
              }}
            >
              {textRecurs("acceso_cerrar_sesion")}
            </Text>
          </TouchableOpacity>
          <Text style={{ color: "white", marginTop: 30, textAlign: "right" }}>
            v{Constants.expoConfig?.version}
          </Text>
        </View>
      </View>

      {ModalPrivacy(modalVisiblePrivacy, setModalVisiblePrivacy)}
      {ModalCookies(modalVisibleCookies, setModalVisibleCookies)}
    </AuthAppShell>
  );
}
