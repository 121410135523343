import React from "react";
import { View } from "react-native";
import RutesImatge from "./RutesImatge";
import RutesInfo from "./RutesInfo";
import globalstyle from "@/Styles/Globals/globals";

export default function ItinerariDetail({ route }: any) {
  return (
    <View style={globalstyle.container}>
      <RutesImatge route={route} />
      <RutesInfo route={route} />
    </View>
  );
}
