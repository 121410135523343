import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Image } from "expo-image";
import style from "./QuizzImagesStyle";
import { IResposta } from "../../Interfices";
import { useGlobal } from "../../Context/globalContext";
import {
  checkErrors,
  fromTextToIndex,
} from "@/Components/Functions/MapFunctions";
import JocComponent from "@/Components/Components/JocComponent";

export default function QuizzImages(RouterData: any) {
  const { __, textRecurs, imatgeRecurs } = useGlobal();

  /** Si la pregunta no és undefined, és un trivial i el poi és el trivial */
  let { poi, pregunta, itinerari, punts } = RouterData.route.params;
  poi = pregunta ?? poi;

  const validAnswerIndex = fromTextToIndex(poi.opcio_correcta);

  const [goAnswers, setGoanswers] = useState<boolean>(false);
  const [errors, setErrors] = useState(0);
  const [respostes, setRespostes] = useState(
    Array.from({ length: 4 }, (_, index) => ({
      id: index + 1,
      url: __(poi, `opcio_imatge${index + 1}`),
      valid: validAnswerIndex === index + 1,
      checked: false,
    })),
  );

  const failImage = imatgeRecurs("poi_imatge_error");
  const successImage = imatgeRecurs("poi_imatge_exit");

  const checkAnswer = (item: IResposta) => {
    if (item.valid) {
      setGoanswers(true);
    } else {
      setErrors(errors + 1);
    }

    const find = respostes.find((e: any) => e.id == item.id);
    find.checked = true;

    setRespostes(respostes);
  };

  const renderItem = (item, index) => {
    let buttonStyle;

    if (item.checked) {
      buttonStyle = item.valid
        ? style.answerButtonCorrect
        : style.answerButtonWrong;
    }

    return (
      <TouchableOpacity
        key={item.id}
        disabled={item.checked || goAnswers}
        onPress={() => checkAnswer(item)}
        style={[style.answerButton, buttonStyle]}
      >
        <Image
          placeholder={require("@/assets/Images/loading.gif")}
          style={style.questionImage}
          source={{
            uri: __(poi, `opcio_imatge${item.id}`)?.url,
          }}
        />
        {item.checked && (
          <Image
            style={style.answerResponse}
            source={{ uri: !item.valid ? failImage : successImage }}
            contentFit={"scale-down"}
          />
        )}
      </TouchableOpacity>
    );
  };

  const renderAnswers = () => {
    return respostes.map((e, index) => renderItem(e, index));
  };

  const navigateToReward = () => {
    setGoanswers(false);

    // Es trivial?
    if (!!pregunta) {
      if (RouterData.route.params.poi.tipus === "TrivialResum") {
        return RouterData.navigation.replace("Trivial", {
          ...RouterData.route.params,
          poi: RouterData.route.params.poi,
          punts: punts + checkErrors(errors),
        });
      }

      return RouterData.navigation.replace("Reward", {
        ...RouterData.route.params,
        poi,
        poiOriginal: RouterData.route.params.poi,
        punts: checkErrors(errors),
      });
    }

    RouterData.navigation.replace("Reward", {
      ...RouterData.route.params,
      punts: checkErrors(errors),
    });
  };

  return (
    <JocComponent RouterData={RouterData} setPistesDemanades={setErrors}>
      <View style={style.quizzAnswerBackground}>
        <View style={style.answersContainer}>{renderAnswers()}</View>
        <TouchableOpacity
          onPress={() => navigateToReward()}
          style={[
            style.botoContinuar,
            !goAnswers ? { opacity: 0.5 } : { opacity: 1 },
            { backgroundColor: itinerari.color_fons2 },
          ]}
          disabled={!goAnswers}
        >
          <Text style={style.textContinuar}>
            {textRecurs("onboarding_continuar")}
          </Text>
        </TouchableOpacity>
      </View>
    </JocComponent>
  );
}
