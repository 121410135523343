import useMediaType from "@/hooks/useMediaType";
import { Image } from "expo-image";
import { ResizeMode, Video } from "expo-av";
import { ActivityIndicator } from "react-native";
import React from "react";

const MediaDisplay = ({ source }) => {
  const mediaType = useMediaType(source);

  if (mediaType === "image") {
    return (
      <Image
        placeholder={require("@/assets/Images/loading.gif")}
        contentFit={"contain"}
        style={{
          width: "100%",
          flex: 2,
        }}
        source={{
          uri: source,
        }}
      />
    );
  }

  if (mediaType === "video") {
    return (
      <Video
        source={{ uri: source }}
        style={{
          width: "100%",
          flex: 2,
        }}
        useNativeControls
        resizeMode={ResizeMode.CONTAIN}
        rate={1.0}
        volume={1.0}
        isMuted={false}
        shouldPlay={true}
        videoStyle={{
          width: "100%",
          height: "100%",
        }}
      />
    );
  }

  if (mediaType === "none") {
    return;
  }

  return <ActivityIndicator />;
};

export default MediaDisplay;
