import { Text, TouchableOpacity, View } from "react-native";
import * as React from "react";
import { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { useGlobal } from "@/Components/Context/globalContext";
import { RFeature, RLayerTileWebGL, RLayerVector, RMap, RStyle } from "rlayers";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";

const MapTest = () => {
  const navigation = useNavigation();
  const { projecte } = useGlobal();

  const [location, setLocation] = useState(fromLonLat([2.452541, 42.12033])); //fromLonLat([1.6725558042526245,41.20839165286224])

  const UserLocationMarker = ({ coordinates }) => {
    return (
      <RLayerVector>
        <RStyle.RStyle>
          <RStyle.RIcon
            src={"https://dummyimage.com/600x400/000/fff"}
            scale={0.08}
          />
        </RStyle.RStyle>

        <RFeature geometry={new Point(coordinates)} />
      </RLayerVector>
    );
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <TouchableOpacity onPress={() => navigation.goBack()}>
        <Text>BACK</Text>
      </TouchableOpacity>

      {/* {"layouts":[{"tipus":"mapa","url":"https://geoserveis.icgc.cat/icc_mapesmultibase/noutm/wmts/orto/GRID3857/{z}/{x}/{y}.png"},{"tipus":"mapa","url":"http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"}],"maxZoom":16}*/}
      <RMap
        width={"100%"}
        height={"100%"}
        initial={{ center: location, zoom: 15 }}
        maxZoom={20}
        noDefaultControls={true}
      >
        <RLayerTileWebGL
          key={"1"}
          url={
            "https://geoserveis.icgc.cat/icc_mapesmultibase/noutm/wmts/orto/GRID3857/{z}/{x}/{y}.png"
          }
          properties={{ label: "IternaturaStyle" }}
        />

        <UserLocationMarker coordinates={location} />

        {/*<RLayerVector zindex={10}>*/}
        {/*  <RStyle.RStyle>*/}
        {/*    <RStyle.RIcon*/}
        {/*      src={*/}
        {/*        api.api_url +*/}
        {/*        RouterData.route.params.recursos.find(*/}
        {/*          (e) => e.tipus_recurs == "geolocalitzacio",*/}
        {/*        ).imatge_recurs.url*/}
        {/*      }*/}
        {/*      scale={0.08}*/}
        {/*    />*/}
        {/*  </RStyle.RStyle>*/}
        {/*  /!*<RFeature geometry={new Point(pointLocation)} />*!/*/}
        {/*</RLayerVector>*/}

        {/*{_renderTrackPosition()}*/}
        {/*{PoisMapa(RouterData, features, location)}*/}
      </RMap>
    </View>
  );
};

export default MapTest;
