import { IProjecte, IRecurs, IText } from "@/types/projecteTypes";
import { API_TOKEN, API_URL, PROJECTE_ID } from "@/config/config";
import { IPoi } from "@/types/poiTypes";
import { useQuery } from "@tanstack/react-query";
import { useAsyncStorage } from "@/hooks/useAsyncStorage";

export default function useProjectData() {
  const [[, projecte], setProjecte] = useAsyncStorage("projecte");

  const fetchData = async () => {
    try {
      const constructUrl = (populates) => {
        const populateQuery = populates.map((p) => `populate=${p}`).join("&");

        return `${API_URL}/api/projectes/${PROJECTE_ID}?${populateQuery}`;
      };

      const createUrlObject = (item: any, attributeName: string) => {
        if (!item?.attributes[attributeName]?.data) {
          return null;
        }

        return {
          url: API_URL + item?.attributes[attributeName].data?.attributes?.url,
        };
      };

      const populates = [
        "itineraris.pois.popup_multimedia",
        "itineraris.pois.popup_multimedia_ES",
        "itineraris.pois.popup_multimedia_EN",
        "itineraris.pois.popup_multimedia_FR",
        "itineraris.pois.popup_multimedia_DE",
        "itineraris.pois.opcio_imatge1",
        "itineraris.pois.opcio_imatge2",
        "itineraris.pois.opcio_imatge3",
        "itineraris.pois.opcio_imatge4",
        "itineraris.pois.popup_so",
        "itineraris.pois.popup_so_ES",
        "itineraris.pois.popup_so_EN",
        "itineraris.pois.popup_so_FR",
        "itineraris.pois.popup_so_DE",
        "itineraris.pois.premi.multimedia",
        "itineraris.pois.premi.multimedia_ES",
        "itineraris.pois.premi.multimedia_EN",
        "itineraris.pois.premi.multimedia_FR",
        "itineraris.pois.premi.multimedia_DE",
        "itineraris.pois.premi.so",
        "itineraris.pois.premi.so_ES",
        "itineraris.pois.premi.so_EN",
        "itineraris.pois.premi.so_FR",
        "itineraris.pois.premi.so_DE",
        "itineraris.pois.preguntes.poi",
        "itineraris.pois.preguntes.opcio_imatge1",
        "itineraris.pois.preguntes.opcio_imatge2",
        "itineraris.pois.preguntes.opcio_imatge3",
        "itineraris.pois.preguntes.opcio_imatge4",
        "itineraris.pois.preguntes.opcio_imatge4",
        "itineraris.pois.preguntes.premi.multimedia",
        "itineraris.pois.preguntes.premi.multimedia_ES",
        "itineraris.pois.preguntes.premi.multimedia_EN",
        "itineraris.pois.preguntes.premi.multimedia_FR",
        "itineraris.pois.preguntes.premi.multimedia_DE",
        "itineraris.pois.preguntes.premi.so",
        "itineraris.pois.preguntes.premi.so_ES",
        "itineraris.pois.preguntes.premi.so_EN",
        "itineraris.pois.preguntes.premi.so_FR",
        "itineraris.pois.preguntes.premi.so_DE",
        "itineraris.fons",
        "itineraris.fons_detall",
        "itineraris.imatge",
        "itineraris.imatge_completat",
        "itineraris.multimedia_recompensa",
        "itineraris.so_recompensa",
        "itineraris.galeria",
        "itineraris.premi",
        "itineraris.fitxer",
        "itineraris.fitxer_ES",
        "itineraris.fitxer_EN",
        "itineraris.fitxer_FR",
        "itineraris.fitxer_DE",
        "itineraris.premi.multimedia",
        "itineraris.premi.so",
        "itineraris.premi.so_ES",
        "itineraris.premi.so_EN",
        "itineraris.premi.so_FR",
        "itineraris.premi.so_DE",
        "itineraris.track",
        "itineraris.track_ES",
        "itineraris.track_EN",
        "itineraris.track_FR",
        "itineraris.track_DE",
        "itineraris.atributs",
        "recursos.multimedia",
        "establiments",
        "establiments.multimedia",
        "vistes.miniatura",
        "textos",
        "qr",
        "fons",
      ];

      const url = constructUrl(populates);

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
        },
      });

      const data = (await response.json()).data;

      const project: IProjecte = {
        id: data.id,
        ...data.attributes,
        qr: createUrlObject(data, "qr"),
        fons: createUrlObject(data, "fons"),
        itineraris: data.attributes.itineraris.data.map((item): any => ({
          id: item.id,
          ...item.attributes,
          fons: createUrlObject(item, "fons"),
          fons_detall: createUrlObject(item, "fons_detall"),
          imatge: createUrlObject(item, "imatge"),
          imatge_completat: createUrlObject(item, "imatge_completat"),
          multimedia_recompensa: createUrlObject(item, "multimedia_recompensa"),
          so_recompensa: createUrlObject(item, "so_recompensa"),
          galeria:
            item.attributes.galeria?.data?.map((item): any => ({
              url: API_URL + item.attributes.url,
            })) || [],
          track: createUrlObject(item, "track"),
          track_ES: createUrlObject(item, "track_ES"),
          track_EN: createUrlObject(item, "track_EN"),
          track_FR: createUrlObject(item, "track_FR"),
          track_DE: createUrlObject(item, "track_DE"),
          fitxer: createUrlObject(item, "fitxer"),
          fitxer_ES: createUrlObject(item, "fitxer_ES"),
          fitxer_EN: createUrlObject(item, "fitxer_EN"),
          fitxer_FR: createUrlObject(item, "fitxer_FR"),
          fitxer_DE: createUrlObject(item, "fitxer_DE"),
          premi: {
            ...item.attributes.premi.data?.attributes,
            multimedia: createUrlObject(
              item.attributes.premi.data,
              "multimedia",
            ),
            multimedia_ES: createUrlObject(
              item.attributes.premi.data,
              "multimedia_ES",
            ),
            multimedia_EN: createUrlObject(
              item.attributes.premi.data,
              "multimedia_EN",
            ),
            multimedia_FR: createUrlObject(
              item.attributes.premi.data,
              "multimedia_FR",
            ),
            multimedia_DE: createUrlObject(
              item.attributes.premi.data,
              "multimedia_DE",
            ),
            so: createUrlObject(item.attributes.premi.data, "so"),
            so_ES: createUrlObject(item.attributes.premi.data, "so_ES"),
            so_EN: createUrlObject(item.attributes.premi.data, "so_EN"),
            so_FR: createUrlObject(item.attributes.premi.data, "so_FR"),
            so_DE: createUrlObject(item.attributes.premi.data, "so_DE"),
          },
          pois: item.attributes.pois.data.map(
            (item: any): IPoi => ({
              id: item.id,
              ...item.attributes,
              opcio_imatge1: createUrlObject(item, "opcio_imatge1"),
              opcio_imatge2: createUrlObject(item, "opcio_imatge2"),
              opcio_imatge3: createUrlObject(item, "opcio_imatge3"),
              opcio_imatge4: createUrlObject(item, "opcio_imatge4"),
              popup_multimedia: createUrlObject(item, "popup_multimedia"),
              popup_multimedia_ES: createUrlObject(item, "popup_multimedia_ES"),
              popup_multimedia_EN: createUrlObject(item, "popup_multimedia_EN"),
              popup_multimedia_FR: createUrlObject(item, "popup_multimedia_FR"),
              popup_multimedia_DE: createUrlObject(item, "popup_multimedia_DE"),
              popup_so: createUrlObject(item, "popup_so"),
              popup_so_ES: createUrlObject(item, "popup_so_ES"),
              popup_so_EN: createUrlObject(item, "popup_so_EN"),
              popup_so_FR: createUrlObject(item, "popup_so_FR"),
              popup_so_DE: createUrlObject(item, "popup_so_DE"),
              premi: {
                ...item.attributes.premi.data?.attributes,
                multimedia: createUrlObject(
                  item.attributes.premi.data,
                  "multimedia",
                ),
                multimedia_ES: createUrlObject(
                  item.attributes.premi.data,
                  "multimedia_ES",
                ),
                multimedia_EN: createUrlObject(
                  item.attributes.premi.data,
                  "multimedia_EN",
                ),
                multimedia_FR: createUrlObject(
                  item.attributes.premi.data,
                  "multimedia_FR",
                ),
                multimedia_DE: createUrlObject(
                  item.attributes.premi.data,
                  "multimedia_DE",
                ),
                so: createUrlObject(item.attributes.premi.data, "so"),
                so_ES: createUrlObject(item.attributes.premi.data, "so_ES"),
                so_EN: createUrlObject(item.attributes.premi.data, "so_EN"),
                so_FR: createUrlObject(item.attributes.premi.data, "so_FR"),
                so_DE: createUrlObject(item.attributes.premi.data, "so_DE"),
              },
              preguntes: item.attributes.preguntes.data.map((item): any => ({
                id: item.id,
                ...item.attributes,
                opcio_imatge1: createUrlObject(item, "opcio_imatge1"),
                opcio_imatge2: createUrlObject(item, "opcio_imatge2"),
                opcio_imatge3: createUrlObject(item, "opcio_imatge3"),
                opcio_imatge4: createUrlObject(item, "opcio_imatge4"),
                premi: {
                  ...item.attributes.premi.data?.attributes,
                  multimedia: createUrlObject(
                    item.attributes.premi.data,
                    "multimedia",
                  ),
                  so: createUrlObject(item.attributes.premi.data, "so"),
                },
              })),
            }),
          ),
          color_fons1: item.attributes.color_fons1,
          color_fons2: item.attributes.color_fons2,
          color_text1: item.attributes.color_text1,
          color_text2: item.attributes.color_text2,
          atributs: item.attributes.atributs.data.map((item): any => ({
            id: item.id,
            ...item.attributes,
          })),
        })),
        multimedies: data.attributes.recursos.data.map(
          (item): IRecurs => ({
            id: item.id,
            tipus: item.attributes.tipus,
            multimedia: createUrlObject(item, "multimedia"),
          }),
        ),
        textos: data.attributes.textos.data.map(
          (item): IText => ({
            id: item.id,
            ...item.attributes,
          }),
        ),
        establiments: data.attributes.establiments.data.map((item): any => ({
          id: item.id,
          ...item.attributes,
          multimedia: createUrlObject(item, "multimedia"),
        })),
        vistes: data.attributes.vistes.data.map((item): any => ({
          id: item.id,
          ...item.attributes,
          miniatura: {
            url: API_URL + item.attributes.miniatura.data?.attributes?.url,
          },
        })),
      };

      project.tipusRecursos = [
        ...new Set(project.establiments.map((e) => e.tipus)),
      ];

      setProjecte(project);

      return project;
    } catch (error) {
      console.log({ error });
      alert("Error al cargar los datos");
      return {};
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["data"],
    queryFn: fetchData,
  });

  return { projecte: data ?? (projecte as IProjecte), isLoading };
}
