import React from "react";
import { ScrollView } from "react-native";
import Markdown from "react-native-markdown-display";
import { useGlobal } from "../../Context/globalContext";
import ModalBase from "@/Components/Screens/Modals/ModalBase";

const ModalTutorial = (setModalVisible: any) => {
  const { textRecurs, projecte, __ } = useGlobal();

  return (
    <ModalBase
      setModalVisible={setModalVisible}
      title={textRecurs("general_tutorial")}
    >
      <ScrollView>
        <Markdown
          style={{
            body: { fontSize: 16, color: projecte.color_principal },
            image: { width: 32, height: 32, flex: 0, marginRight: 40 },
            em: { fontSize: 12 },
          }}
        >
          {__(projecte, "tutorial") || " "}
        </Markdown>
      </ScrollView>
    </ModalBase>
  );
};

export default ModalTutorial;
