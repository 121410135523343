import { useEffect, useState } from "react";
import { Audio } from "expo-av";

const useAudioPlayer = () => {
  const audioFile = require("");
  const [sound, setSound] = useState<Audio.Sound | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    const loadSound = async () => {
      // try {
      //   const { sound } = await Audio.Sound.createAsync(audioFile, {
      //     shouldPlay: true,
      //     isLooping: true,
      //     volume: 0.05,
      //   });
      //   setSound(sound);
      //   setIsPlaying(true);
      // } catch (error) {
      //   console.log('Error loading sound:', error);
      // }
    };

    loadSound();

    return () => {
      sound?.stopAsync();
      sound?.unloadAsync();
    };
  }, [audioFile]);

  const togglePlayback = () => {
    if (sound) {
      if (isPlaying) {
        sound.pauseAsync();
      } else {
        sound.playAsync();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return { isPlaying, togglePlayback };
};

export default useAudioPlayer;
