import React, { useState } from "react";
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import Alert from "@/Components/Components/Alert";

import constants from "@/config/constants";
import ModalPrivacy from "@/Components/Screens/Modals/ModalPrivacy";
import ModalCookies from "@/Components/Screens/Modals/ModalCookies";
import { useAuth } from "@/Components/Context/authContext";
import { useGlobal } from "@/Components/Context/globalContext";
import LoginSenzill from "@/Components/Components/Auth/LoginSenzill";

export default function LoginScreen(RouterData: any) {
  const { textRecurs, projecte } = useGlobal();

  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modalVisiblePrivacy, setModalVisiblePrivacy] = useState(false);
  const [modalVisibleCookies, setModalVisibleCookies] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if (projecte.tipus_login === "Senzill") {
    return <LoginSenzill />;
  }

  const navigateToSignup = () => {
    RouterData.navigation.navigate("Signup", {
      ...RouterData.route.params,
    });
  };

  const navigateToPwd1 = () => {
    RouterData.navigation.navigate("PWD1", {
      ...RouterData.route.params,
    });
  };

  const handleLogin = async () => {
    setIsLoading(true);

    const error = await login(email, password);

    if (error) {
      Alert(
        "",
        textRecurs("acceso_error_login"),
        [{ text: textRecurs("onboarding_aceptar") }],
        {
          cancelable: true,
        },
      );
    }

    setIsLoading(false);
  };

  return (
    <View style={style.mainContainer}>
      <View
        style={{
          backgroundColor: "#fff",
          paddingHorizontal: 24,
          paddingTop: 24,
        }}
      >
        <Text style={{ ...style.mainTitle, color: projecte.color_principal }}>
          {textRecurs("acceso_login_titulo")}
        </Text>
        <Text style={style.infoText}>
          {textRecurs("acceso_login_descripcion")}
        </Text>
      </View>
      <View style={{ paddingHorizontal: 24 }}>
        <View style={style.inputContainer}>
          <Text style={style.label}>{textRecurs("correo_electronico")}</Text>
          <TextInput
            style={style.input}
            value={email}
            onChangeText={(value) => setEmail(value?.trim())}
            autoCapitalize="none"
            placeholder={textRecurs("acceso_correo_electronico_pista")}
            placeholderTextColor={projecte.color_principal}
          />
        </View>
        <View style={style.inputContainer}>
          <Text style={style.label}>{textRecurs("acceso_contrasena")}</Text>
          <TextInput
            style={style.input}
            value={password}
            onChangeText={(value) => setPassword(value?.trim())}
            autoCapitalize="none"
            secureTextEntry={true}
            placeholder={textRecurs("acceso_contrasena_pista")}
            placeholderTextColor={projecte.color_principal}
          />
        </View>

        <TouchableOpacity
          style={{
            ...style.btnLogin,
            backgroundColor: projecte.color_principal,
          }}
          onPress={handleLogin}
        >
          {isLoading ? (
            <ActivityIndicator />
          ) : (
            <Text style={style.btnLoginText}>
              {textRecurs("onboarding_continuar")}
            </Text>
          )}
        </TouchableOpacity>

        <View>
          <Text
            style={{
              color: constants.colorBlackText,
              letterSpacing: 0.64,
              fontSize: 14,
            }}
            numberOfLines={2}
          >
            {textRecurs("acceso_crear_cuenta")}
          </Text>
          <TouchableOpacity
            onPress={navigateToSignup}
            style={{ paddingHorizontal: 10 }}
          >
            <Text
              style={{
                marginLeft: 2,
                letterSpacing: 0.64,
                color: projecte.color_principal,
                fontSize: 14,
                fontWeight: "700",
                verticalAlign: "middle",
              }}
            >
              {textRecurs("acceso_click_aqui")}
            </Text>
          </TouchableOpacity>
        </View>

        <View style={{ marginTop: 20 }}>
          <Text
            style={{
              color: constants.colorBlackText,
              letterSpacing: 0.64,
              fontSize: 14,
            }}
          >
            {textRecurs("acceso_no_recuerdas_contrasena")}
          </Text>
          <TouchableOpacity
            onPress={navigateToPwd1}
            style={{ paddingHorizontal: 10 }}
          >
            <Text
              style={{
                letterSpacing: 0.64,
                marginLeft: 2,
                color: projecte.color_principal,
                fontSize: 14,
                fontWeight: "700",
                verticalAlign: "middle",
              }}
            >
              {textRecurs("acceso_click_aqui")}
            </Text>
          </TouchableOpacity>
        </View>

        <Text style={style.cookiesText}>
          <Text>{textRecurs("acceso_texto_legal1")}</Text>
          <Text
            style={{ ...style.links, color: projecte.color_principal }}
            onPress={() => setModalVisiblePrivacy(true)}
          >
            {" "}
            {textRecurs("aviso_privacidad")}{" "}
          </Text>
          {textRecurs("acceso_texto_legal2")}
          <Text
            style={{ ...style.links, color: projecte.color_principal }}
            onPress={() => setModalVisibleCookies(true)}
          >
            {" "}
            {textRecurs("aviso_legal")}
          </Text>
          <Text>.</Text>
        </Text>
      </View>
      {ModalPrivacy(modalVisiblePrivacy, setModalVisiblePrivacy)}
      {ModalCookies(modalVisibleCookies, setModalVisibleCookies)}
    </View>
  );
}

const style = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  mainTitle: {
    fontFamily: "Georgia",
    flex: 0,
    fontSize: 32,
    fontWeight: "700",
    color: constants.colorGreenDark,
  },
  infoText: {
    fontFamily: "Roboto",

    fontSize: 16,
    fontWeight: "400",
    color: constants.colorBlackText,
    marginTop: 8,
    marginBottom: 34,
    textAlign: "left",
    lineHeight: 22.4,
  },
  input: {
    fontFamily: "Roboto",
    flex: 0,
    marginTop: 12,
    paddingLeft: 24,
    paddingTop: 5,
    borderWidth: 1,
    borderRadius: 32,
    backgroundColor: constants.colorWhite,
    fontSize: 16,
    fontWeight: "400",
  },
  btnLogin: {
    fontFamily: "Roboto",
    // width: 327,
    height: 56,
    marginTop: 24,
    // marginHorizontal:24,
    marginBottom: 40,
    // paddingHorizontal:10,
    paddingVertical: 12,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 8,
    fontSize: 16,
  },
  btnLoginText: {
    fontFamily: "Roboto",
    color: constants.colorWhite,
    letterSpacing: 0.8,
    fontSize: 16,
    fontWeight: "700",
    textAlign: "center",
    justifyContent: "center",
  },
  cookiesText: {
    fontFamily: "Roboto",
    textAlign: "left",
    lineHeight: 19.6,
    flexDirection: "column",
    marginTop: 75,
    color: constants.colorBlackText,
    letterSpacing: 0.64,
    fontSize: 14,
    marginRight: -3,
  },
  links: {
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  inputContainer: {
    // Container styles (e.g., padding, margin)
  },
  label: {
    paddingTop: 23,
    paddingLeft: 4,
    color: "rgb(0,73,31)",
    fontSize: 10,
    fontWeight: "400",
    fontFamily: "Roboto",
  },
});
