import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import MapView, { MapType, Marker, Polyline } from "react-native-maps";
import {
  ActivityIndicator,
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import {
  checkErrors,
  getDistanceFromLatLonInMeters,
  loadSound,
  playSoundForAWhile,
  secondsToDatetimeString,
} from "../../Functions/MapFunctions";
import ModalPuntInici from "../Modals/ModalPuntInici";
import globalstyle from "../../../Styles/Globals/globals";
import ModalGhostEnd from "../Modals/ModalGhostEnd";
import { RenderTimer } from "../GhostPoint/GhostPoint";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import ModalFiltres from "../Modals/ModalFiltres";
import ModalEtsAprop from "../Modals/ModalEtsAprop";
import ModalItinerariComplet from "../Modals/ModalItinerarioCompleto";
import { useGlobal } from "../../Context/globalContext";
import { useAuth } from "../../Context/authContext";
import HintModal from "../Modals/HintModal";
import { Audio } from "expo-av";
import CompliceModal from "../Modals/CompliceModal";
import Alert from "@/Components/Components/Alert";

import {
  generateRandomString,
  postStadistic,
} from "@/Components/Functions/utils";
import * as FileSystem from "expo-file-system";
import { parseString } from "react-native-xml2js";
import { IPoi } from "@/types/poiTypes";
import useLocation from "@/hooks/useLocation";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import {
  MODAL_NO_VISIBLE,
  MODAL_TORNAT_OBRIR,
  MODAL_VISIBLE,
} from "@/config/constants";
import { getPoiImage } from "@/Components/Functions/getPoiImage";
import ModalPremis from "@/Components/Screens/Modals/ModalPremis";
import { GOOGLE_MAPS_API_KEY } from "@/config/config";
import { RFeature, RLayerTileWebGL, RLayerVector, RMap, RStyle } from "rlayers";
import { LineString, Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import { Feature } from "ol";
import { Stroke, Style } from "ol/style";

const UserLocationMarker = ({ coordinates }) => {
  return (
    <Marker coordinate={coordinates}>
      <FontAwesome name={"circle"} size={18} color={"#1380FF"} />
    </Marker>
  );
};

const UserLocationMarkerMapaPersonalitzat = ({ coordinates }) => {
  const { imatgeRecurs } = useGlobal();

  const feature = new Feature({
    geometry: new Point(coordinates),
  });

  return (
    <RLayerVector>
      <RStyle.RStyle>
        <RStyle.RIcon src={imatgeRecurs("posicio_actual")} scale={0.1} />
      </RStyle.RStyle>

      <RFeature feature={feature} />
    </RLayerVector>
  );
};

const GoogleMapsView = (props: {
  mapType:
    | "hybrid"
    | "mutedStandard"
    | "none"
    | "satellite"
    | "standard"
    | "terrain";
  region: {
    longitudeDelta: number;
    latitudeDelta: number;
    latitude: number;
    longitude: number;
  };
  coordinates: any;
  points: any;
  establiments: React.JSX.Element;
  user: any;
  currentLocation: any;
}) => (
  <MapView
    provider={"google"}
    style={globalstyle.container}
    showsUserLocation={true}
    mapType={props.mapType}
    region={props.region}
    initialRegion={props.region}
    loadingEnabled={false}
    googleMapsApiKey={GOOGLE_MAPS_API_KEY}
    initialCamera={{
      ...(Platform.OS !== "android"
        ? {
            center: {
              latitude: props.region.latitude,
              longitude: props.region.longitude,
            },
            zoom: 16,
          }
        : {}),
    }}
    options={{
      ...(Platform.OS === "web"
        ? {
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
          }
        : {}),
    }}
  >
    <Polyline
      coordinates={props.coordinates}
      strokeColor={"red"}
      strokeWidth={3}
    />
    {props.points}
    {props.establiments}
    {props.user.es_admin && (
      <View style={{ gap: 10, flexDirection: "row" }}>
        <Text>{props.currentLocation.latitude}</Text>
        <Text>{props.currentLocation.longitude}</Text>
      </View>
    )}
    <UserLocationMarker coordinates={props.currentLocation} />
  </MapView>
);

const MapaPersonalitzat = (props: {
  region: {
    longitudeDelta: number;
    latitudeDelta: number;
    latitude: number;
    longitude: number;
  };
  points: any;
  establiments: React.JSX.Element;
  user: any;
  currentLocation: any;
  layoutUrl: string;
  coordinates: any;
}) => {
  const center = fromLonLat([props.region.longitude, props.region.latitude]);
  const location = fromLonLat([
    props.currentLocation.longitude,
    props.currentLocation.latitude,
  ]);

  return (
    <RMap
      width={"100%"}
      height={"100%"}
      initial={{ center, zoom: 15 }}
      maxZoom={20}
      noDefaultControls={true}
    >
      <RLayerTileWebGL
        url={props.layoutUrl}
        properties={{ label: "IternaturaStyle" }}
      />
      <UserLocationMarkerMapaPersonalitzat coordinates={location} />
      <RLayerVector>
        <RFeature
          geometry={
            new LineString(
              props.coordinates.map((v) =>
                fromLonLat([parseFloat(v[1]), parseFloat(v[0])]),
              ),
            )
          }
          style={
            new Style({
              stroke: new Stroke({
                color: "red",
                width: 3,
              }),
            })
          }
        />
      </RLayerVector>
      <RLayerVector>
        <RFeature
          geometry={new LineString(props.coordinates)} // Use the LineString geometry
          style={
            new Style({
              stroke: new Stroke({
                color: "blue", // Line color
                width: 4, // Line width
              }),
            })
          }
        />
      </RLayerVector>
      {props.points}
      {props.establiments}
      {props.user.es_admin && (
        <View style={{ gap: 10, flexDirection: "row" }}>
          <Text>{props.currentLocation.latitude}</Text>
          <Text>{props.currentLocation.longitude}</Text>
        </View>
      )}
    </RMap>
  );
};

export default function MapScreen({ route }: any) {
  let { itinerari } = route.params ?? {};
  itinerari ??= {
    id: 0,
    pois: [],
  };

  const { __, projecte, textRecurs, imatgeRecurs } = useGlobal();
  const { user, savePerfil } = useAuth();
  const navigation = useNavigation<any>();
  const isFocused = useIsFocused();

  const myPois = itinerari.id
    ? user.progres.itineraris[itinerari.id].pois
    : Object.values(user.progres.itineraris)
        ?.map((i) => i.pois)
        ?.reduce((a, b) => ({ ...a, ...b }), {});

  const itinerariCompletat: boolean =
    Object.values(user.progres.itineraris[itinerari.id]?.pois || {}).length ===
    itinerari.pois.length;

  const currentPoiIndex = Object.values(myPois).filter(
    (p: IPoi) => !p.esPreguntaEncadenada,
  ).length;

  const [currentPoi, setCurrentPoi] = useState<any>(
    itinerari.pois[currentPoiIndex] ?? {},
  );

  const [track, setTrack] = useState<any>([]);
  const { currentLocation, mapRegion, isLoadingLocation, foraDelGeofence } =
    useLocation(itinerari, track);
  const [distanceNextPoi, setDistanceNextPoi] = useState(0);

  const [modalIniciVisible, setModalIniciVisible] = useState(
    itinerari.id && currentPoiIndex === 0,
  );
  const [modalApropVisible, setModalApropVisible] =
    useState<number>(MODAL_NO_VISIBLE);
  const [modalCompletVisible, setModalCompletVisible] = useState(false);

  /** Cor a la barra de filtres **/
  const [filtreFavoritosActiu, setFiltreFavoritosActiu] = useState(false);
  const filtreFavoritosSource = filtreFavoritosActiu
    ? imatgeRecurs("filtros_favoritos_on")
    : imatgeRecurs("filtros_favoritos_off");

  /** So **/
  const [sound, setSound] = useState<Audio.Sound | null>(null);

  /** Establiments **/
  const [currentEstabliment, setCurrentEstabliment] = useState(null);
  const [modalComplicesVisible, setModalComplicesVisible] = useState(false);

  /** Establiments a favoritos **/
  const [filteredEstabliments, setFilteredEstabliments] = useState(
    projecte.establiments,
  );

  const [modalPremisVisible, setModalPremisVisible] = useState<boolean>(false);

  /** Capes Maps **/
  const [mapType, setMapType] = useState<MapType>("satellite");

  /** Modal filtres **/
  const [modalFiltresVisible, setModalFiltresVisible] = useState(false);
  const [filtreActiu, setFiltreActiu] = useState(null);

  /** Configuració Joc Fantasma - Ghost **/
  const [isCurrentPoiGhost, setIsCurrentPoiGhost] = useState<boolean>(false);
  const [activeGhostGame, setActiveGhostGame] = useState<boolean>(false);
  const [time, setTime] = useState<string>("5:00");
  const [timeNumeric, setTimeNumeric] = useState<number>(300);
  const [modalFinishGhost, setModalFinishGhost] = useState<boolean>(false);
  const [ghostStartTime, setGhostStartTime] = useState<number>(0);

  /** Mostrar pistes durant el Joc Fantasma **/
  const pistes: string[] = [];
  if (currentPoi) {
    for (let i = 0; i < 4; i++) {
      const pista = __(currentPoi, `pista${i + 1}`);

      if (pista) {
        pistes.push(pista);
      }
    }
  }

  const [botoPistes, setBotoPistes] = useState<boolean>(false);
  const [hintVisible, setHintVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<number>(0);

  const [currentLayoutUrl, setCurrentLayoutUrl] = useState<string>(
    itinerari.mapes?.layouts?.[0].url ||
      projecte.itineraris[0].mapes?.layouts?.[0].url,
  );

  let timer: any = null;

  /** computed **/
  useEffect(() => setTime(secondsToDatetimeString(timeNumeric)), [timeNumeric]);

  const handleGameWin = useCallback(() => {
    playSoundForAWhile(imatgeRecurs("poi_encert_so"), undefined, setSound);
  }, [imatgeRecurs]);

  const handleGameLoss = useCallback(() => {
    playSoundForAWhile(imatgeRecurs("poi_error_so"), undefined, setSound);
  }, [imatgeRecurs]);

  const handleGhostGameFinish = useCallback(
    (win: boolean, punts: number) => {
      win ? handleGameWin() : handleGameLoss();

      clearInterval(timer);

      navigation.replace("Reward", {
        itinerari,
        poi: currentPoi,
        punts: win ? checkErrors(errors) : 0,
      });

      setIsCurrentPoiGhost(false);
      setActiveGhostGame(false);
      setBotoPistes(false);

      savePerfil({ progres: user.progres });
    },
    [
      timer,
      handleGameWin,
      handleGameLoss,
      savePerfil,
      user.progres,
      errors,
      navigation,
      itinerari,
      currentPoi,
    ],
  );

  const startGhostTimer = useCallback(() => {
    setActiveGhostGame(true);
    setBotoPistes(true);

    timer = setInterval(() => {
      setTimeNumeric((prevTime) => {
        const segonsQueHanPassat = (Date.now() - ghostStartTime) / 1000;

        const newTime =
          (user.es_admin ? 10 : currentPoi.segons) - segonsQueHanPassat;

        if (newTime <= 0) {
          clearInterval(timer);

          handleGhostGameFinish(false, 0);
        }

        return newTime;
      });
    }, 1000);
  }, [
    timer,
    handleGhostGameFinish,
    ghostStartTime,
    currentPoi.segons,
    user.es_admin,
  ]);

  useEffect(() => {
    if (!itinerari.id || !isFocused) return;

    if (itinerariCompletat) {
      console.log("FI DEL JOC");

      postStadistic({
        projecte: projecte.nom,
        token: user.id,
        peticio: `${itinerari.id} ${__(itinerari, "nom")}`,
        tipus_peticio: "Fi itinerari",
        screen: "Mapa",
      });

      // postStadistic({
      //   projecte: projecte.nom,
      //   token: user.id,
      //   peticio: `${itinerari.id} ${__(itinerari, "nom")}`,
      //   tipus_peticio: "FIN_JUEGO",
      // });

      user.progres.itineraris[itinerari.id].fi = new Date().toISOString();
      user.progres.itineraris[itinerari.id].codiPremi = generateRandomString();

      savePerfil({ progres: user.progres });

      if (itinerari.premi?.tipus === "Trucada") {
        return navigation.replace("PuzzlePerfil", {
          itinerari,
          esFinal: true,
        });
      }

      if (itinerari.premi) {
        return navigation.navigate("Premi", {
          itinerari,
          premi: itinerari.premi,
          esFinal: true,
        });
      }
    }

    const poi = itinerari.pois[currentPoiIndex] ?? {};

    setCurrentPoi(poi);

    if (poi.tipus === "PuntFantasma" && !isCurrentPoiGhost) {
      setTimeNumeric(user.es_admin ? 10 : poi.segons);
    }
  }, [isFocused]);

  useEffect(() => {
    if (!modalIniciVisible && foraDelGeofence) {
      loadSound(imatgeRecurs("geofence_out"));
    }
  }, [modalIniciVisible, foraDelGeofence, currentLocation]);

  useEffect(() => {
    if (
      !itinerari.id ||
      modalIniciVisible ||
      modalApropVisible ||
      modalFinishGhost
    ) {
      return;
    }

    if (isFocused) {
      setTimeout(() => comprovaPuntProxim(), 2000);
    }
  }, [
    modalIniciVisible,
    modalApropVisible,
    modalFinishGhost,
    isCurrentPoiGhost,
    currentLocation,
  ]);

  useEffect(() => {
    if (!itinerari.id || !isCurrentPoiGhost || !currentPoi.latitud) {
      return;
    }

    if (!activeGhostGame) {
      startGhostTimer();
    }

    const distance = getDistanceFromLatLonInMeters(
      currentLocation.latitude,
      currentLocation.longitude,
      currentPoi.latitud,
      currentPoi.longitud,
    );

    if (distance < currentPoi.metres_activacio) {
      handleGhostGameFinish(true, checkErrors(errors));

      clearInterval(timer);
    }
  }, [isCurrentPoiGhost, currentLocation, activeGhostGame]);

  useEffect(() => {
    if (!filtreFavoritosActiu) {
      return setFilteredEstabliments(projecte.establiments);
    }

    const coincidencias = projecte.establiments.filter((establiment: any) =>
      user.progres.favoritos.recursos.includes(establiment.id),
    );

    setFilteredEstabliments(coincidencias);
  }, [filtreFavoritosActiu]);

  const handleTancaModalPuntFantasmaAcabat = useCallback(() => {
    setModalFinishGhost(false);
    setHintVisible(false);

    const poi = itinerari.pois[currentPoiIndex];

    setCurrentPoi(poi);
  }, [currentPoiIndex, itinerari.pois]);

  const comprovaPuntProxim = useCallback(() => {
    if (!currentPoi.latitud) {
      return false;
    }

    const distance = getDistanceFromLatLonInMeters(
      currentLocation.latitude,
      currentLocation.longitude,
      currentPoi.latitud,
      currentPoi.longitud,
    );

    if (distance > 0) {
      setDistanceNextPoi(Math.round(distance));
    }

    // Ja està activat, res a fer
    if (isCurrentPoiGhost) {
      return;
    }

    if (
      distance < currentPoi.metres_activacio ||
      currentPoi.tipus === "PuntFantasma"
    ) {
      setModalApropVisible(MODAL_VISIBLE);
    }

    if (distance < itinerari.metres_activacio_arribada_poi) {
      loadSound(imatgeRecurs("poi_arribada"));
    }
  }, [
    currentLocation.latitude,
    currentLocation.longitude,
    currentPoi.latitud,
    currentPoi.longitud,
    currentPoi.metres_activacio,
    currentPoi.tipus,
    imatgeRecurs,
    itinerari.metres_activacio_arribada_poi,
    isCurrentPoiGhost,
  ]);

  const onPoiPress = (poi: any, poiIndex: number, realitzat: boolean) => {
    const distance = getDistanceFromLatLonInMeters(
      currentLocation.latitude,
      currentLocation.longitude,
      poi.latitud,
      poi.longitud,
    );

    if (
      !projecte.desactivar_geoposicionament &&
      !user.es_admin &&
      poi.metres_activacio > 0 &&
      distance > poi.metres_activacio
    ) {
      return Alert(
        textRecurs("poi_alerta_distancia_titulo"),
        textRecurs("poi_alerta_distancia_texto"),
      );
    }

    if (realitzat && itinerari.id) {
      return Alert("", textRecurs("poi_alerta_completat"));
    }

    // Encara no es pot fer aquest poi
    if (poiIndex != currentPoiIndex && itinerari.id) {
      return Alert("", textRecurs("poi_alerta_error"));
    }

    if (!itinerari.id) {
      setCurrentPoi(poi);
      setModalApropVisible(MODAL_TORNAT_OBRIR);
    } else {
      setModalApropVisible(MODAL_VISIBLE);
    }
  };

  const renderTrack = async () => {
    try {
      // const fileUri = `${FileSystem.documentDirectory + itinerari.id}.gpx`;
      //
      // if (!(await FileSystem.getInfoAsync(fileUri)).exists) {
      //   return;
      // }
      //
      // const content = await FileSystem.readAsStringAsync(fileUri);

      const file = await fetch(__(itinerari, "track")?.url);
      const content = await file.text();

      // parseString(content, function (err, result) {
      //   const coordinates = result.gpx.trk[0].trkseg[0].trkpt.map(
      //     (element: any) => ({
      //       latitude: parseFloat(element.$.lat),
      //       longitude: parseFloat(element.$.lon),
      //     }),
      //   );
      //   setTrack(coordinates);
      // });

      parseString(content, function (err, result) {
        const coordinates = result.gpx.trk[0].trkseg[0].trkpt.map(
          (element: any) => [
            parseFloat(element.$.lat),
            parseFloat(element.$.lon),
          ],
        );
        setTrack(coordinates);
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!__(itinerari, "track")) return;

    renderTrack();
  }, [itinerari]);

  const handleShowInfoView = () => {
    setModalApropVisible(MODAL_TORNAT_OBRIR);
  };

  const mostraPoisFets = () => {
    const pois: any = [];

    projecte.itineraris.forEach((itinerari: any) => {
      itinerari.pois.forEach((poi: any, poiIndex: number) => {
        const realitzat = !!myPois[poi.id];

        if (!realitzat || poi.tipus === "PuntFantasma" || !poi.latitud) {
          return;
        }

        const _poi = (
          <Marker
            key={poiIndex}
            coordinate={{
              latitude: parseFloat(poi.latitud),
              longitude: parseFloat(poi.longitud),
            }}
            onPress={() => onPoiPress(poi, poiIndex, realitzat)}
          >
            <Image
              source={{
                uri: getPoiImage(
                  projecte.multimedies,
                  poiIndex,
                  realitzat,
                  currentPoiIndex,
                  itinerari,
                ),
              }}
              style={{ width: 40, height: 40 }}
            />
          </Marker>
        );
        pois.push(_poi);
      });
    });

    return pois;
  };

  const mostraPoisFetsMapaPersonalitzat = () => {
    const pois: any = [];

    projecte.itineraris.forEach((itinerari: any) => {
      itinerari.pois.forEach((poi: any, poiIndex: number) => {
        const realitzat = !!myPois[poi.id];

        if (!realitzat || poi.tipus === "PuntFantasma" || !poi.latitud) {
          return;
        }

        const icon = getPoiImage(
          projecte.multimedies,
          poiIndex,
          realitzat,
          currentPoiIndex,
          itinerari,
        );

        const feature = new Feature({
          geometry: new Point(
            fromLonLat([parseFloat(poi.longitud), parseFloat(poi.latitud)]),
          ),
        });

        const _poi = (
          <RLayerVector key={poi.id}>
            <RStyle.RStyle>
              <RStyle.RIcon src={icon} scale={0.5} />
            </RStyle.RStyle>
            <RFeature
              feature={feature}
              onClick={(e) => onPoiPress(poi, poiIndex, realitzat)}
            />
          </RLayerVector>
        );

        pois.push(_poi);
      });
    });

    return pois;
  };

  const Points = () => {
    if (!itinerari.id) {
      return mostraPoisFets();
    }

    // El pròxim punt fantasma
    const indexPoiFantasma: number = itinerari.pois.findIndex(
      (p: any, index) => index >= currentPoiIndex && p.tipus === "PuntFantasma",
    );

    return itinerari.pois
      .map((poi: any, poiIndex: number) => {
        const realitzat = !!myPois[poi.id];

        if (
          poi.tipus === "PuntFantasma" ||
          !poi.latitud ||
          (currentPoiIndex === 0 && poiIndex !== 0)
        ) {
          return null;
        }

        // Només mostra fins al poi fantasma
        if (indexPoiFantasma >= 0 && poiIndex > indexPoiFantasma) {
          return null;
        }

        return (
          <Marker
            key={poiIndex}
            coordinate={{
              latitude: parseFloat(poi.latitud),
              longitude: parseFloat(poi.longitud),
            }}
            onPress={() => onPoiPress(poi, poiIndex, realitzat)}
          >
            <Image
              source={{
                uri: getPoiImage(
                  projecte.multimedies,
                  poiIndex,
                  realitzat,
                  currentPoiIndex,
                  itinerari,
                ),
              }}
              style={{ width: 40, height: 40 }}
            />
          </Marker>
        );
      })
      .filter((i) => i);
  };

  const Establiments = () => {
    const handleClickMarker = (establiment: any) => {
      setCurrentEstabliment(establiment);
      setModalComplicesVisible(true);
    };

    return (
      <>
        {filteredEstabliments.map((establiment: any) => {
          if (
            establiment.latitud &&
            establiment.longitud &&
            ((filtreActiu && establiment.tipus === filtreActiu) ||
              filtreFavoritosActiu)
          ) {
            const uri = imatgeRecurs(
              "recurs_" + establiment.tipus.replaceAll(" ", "_").toLowerCase(),
            );

            return (
              <Marker
                key={establiment.id}
                coordinate={{
                  latitude: parseFloat(establiment.latitud),
                  longitude: parseFloat(establiment.longitud),
                }}
                onPress={() => handleClickMarker(establiment)}
              >
                <Image style={{ width: 60, height: 60 }} source={{ uri }} />
              </Marker>
            );
          }
          return null;
        })}
      </>
    );
  };

  const PointsMapaPersonaltizat = () => {
    if (!itinerari.id) {
      return mostraPoisFetsMapaPersonalitzat();
    }

    // El pròxim punt fantasma
    const indexPoiFantasma: number = itinerari.pois.findIndex(
      (p: any, index) => index >= currentPoiIndex && p.tipus === "PuntFantasma",
    );

    return itinerari.pois
      .map((poi: any, poiIndex: number) => {
        const realitzat = !!myPois[poi.id];

        if (
          poi.tipus === "PuntFantasma" ||
          !poi.latitud ||
          (currentPoiIndex === 0 && poiIndex !== 0)
        ) {
          return null;
        }

        // Només mostra fins al poi fantasma
        if (indexPoiFantasma >= 0 && poiIndex > indexPoiFantasma) {
          return null;
        }

        const icon = getPoiImage(
          projecte.multimedies,
          poiIndex,
          realitzat,
          currentPoiIndex,
          itinerari,
        );

        const feature = new Feature({
          geometry: new Point(
            fromLonLat([parseFloat(poi.longitud), parseFloat(poi.latitud)]),
          ),
        });

        return (
          <RLayerVector key={poi.id}>
            <RStyle.RStyle>
              <RStyle.RIcon src={icon} scale={0.1} />
            </RStyle.RStyle>
            <RFeature
              feature={feature}
              onClick={(e) => onPoiPress(poi, poiIndex, realitzat)}
            />
          </RLayerVector>
        );
      })
      .filter((i) => i);
  };

  const EstablimentsMapaPersonalitzat = () => {
    const handleClickMarker = (establiment: any) => {
      setCurrentEstabliment(establiment);
      setModalComplicesVisible(true);
    };

    return (
      <>
        {filteredEstabliments
          .filter(
            (establiment: any) =>
              establiment.latitud &&
              establiment.longitud &&
              ((filtreActiu && establiment.tipus === filtreActiu) ||
                filtreFavoritosActiu),
          )
          .map((establiment: any) => {
            const uri = imatgeRecurs(
              "recurs_" + establiment.tipus.replaceAll(" ", "_").toLowerCase(),
            );

            return (
              <RLayerVector key={establiment.id}>
                <RStyle.RStyle>
                  <RStyle.RIcon src={uri} scale={0.5} />
                </RStyle.RStyle>

                <RFeature
                  geometry={
                    new Point(
                      fromLonLat([
                        parseFloat(establiment.longitud),
                        parseFloat(establiment.latitud),
                      ]),
                    )
                  }
                  onClick={() => handleClickMarker(establiment)}
                />
              </RLayerVector>
            );
          })}
      </>
    );
  };

  const handleChangeMapType = () => {
    const layouts =
      itinerari.mapes?.layouts || projecte.itineraris[0].mapes?.layouts;

    if (layouts?.length > 0) {
      setCurrentLayoutUrl((prev) => {
        const index = layouts.findIndex((l) => l.url === prev);

        if (index + 1 >= layouts.length) {
          return layouts[0].url;
        }

        return layouts[index + 1].url;
      });
    }

    if (mapType === "standard") {
      setMapType("satellite");
    } else if (mapType === "satellite") {
      setMapType("hybrid");
    } else {
      setMapType("standard");
    }
  };

  const mostraMapaPersonalitzat =
    (itinerari?.mapes?.layouts?.length ||
      projecte.itineraris[0].mapes?.layouts?.length) > 0;

  return (
    <View style={globalstyle.container}>
      {!isFocused || isLoadingLocation ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      ) : mostraMapaPersonalitzat ? (
        <MapaPersonalitzat
          region={mapRegion}
          establiments={EstablimentsMapaPersonalitzat()}
          points={PointsMapaPersonaltizat()}
          user={user}
          currentLocation={currentLocation}
          layoutUrl={currentLayoutUrl}
          coordinates={track}
        />
      ) : (
        <GoogleMapsView
          mapType={mapType}
          region={mapRegion}
          coordinates={track}
          points={Points()}
          establiments={Establiments()}
          user={user}
          currentLocation={currentLocation}
        />
      )}

      {/*{itinerari.id > 0 && !modalIniciVisible && (*/}
      {/*  <View style={styles.liniaBotons}>*/}
      {/*    <TouchableOpacity*/}
      {/*      style={[styles.boto]}*/}
      {/*      onPress={() => setModalPremisVisible(true)}*/}
      {/*    >*/}
      {/*      <FontAwesome5 name={"award"} size={20} color="black" />*/}
      {/*    </TouchableOpacity>*/}

      {/*    {isCurrentPoiGhost && (*/}
      {/*      <TouchableOpacity style={styles.boto} onPress={handleShowInfoView}>*/}
      {/*        <FontAwesome name={"info"} size={20} color="black" />*/}
      {/*      </TouchableOpacity>*/}
      {/*    )}*/}

      {/*    {botoPistes && (*/}
      {/*      <TouchableOpacity*/}
      {/*        style={styles.boto}*/}
      {/*        onPress={() => setHintVisible(true)}*/}
      {/*      >*/}
      {/*        <FontAwesome name={"question"} size={20} color="black" />*/}
      {/*      </TouchableOpacity>*/}
      {/*    )}*/}
      {/*  </View>*/}
      {/*)}*/}
      {/*<View*/}
      {/*  style={[*/}
      {/*    styles.liniaBotons,*/}
      {/*    {*/}
      {/*      top: 130,*/}
      {/*      display: itinerari.id && !modalIniciVisible ? "flex" : "none",*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*>*/}
      {/*  <View style={[styles.boto, styles.botoLlarg]}>*/}
      {/*    <FontAwesome name={"flag"} size={20} color="black" />*/}
      {/*    <Text>{distanceNextPoi}m</Text>*/}
      {/*  </View>*/}
      {/*  /!*</View>      /!*<View style={[styles.liniaBotons, { top: 130 }]}>*!/*/}
      {/*  /!*  <View style={[styles.boto, styles.botoLlarg]}>*!/*/}
      {/*  /!*    <FontAwesome name={"flag"} size={20} color="black" />*!/*/}
      {/*  /!*    <Text>{distanceNextPoi}m</Text>*!/*/}
      {/*  /!*  </View>*!/*/}
      {/*</View>*/}
      {!isCurrentPoiGhost && (
        <TouchableOpacity
          style={globalstyle.arrowPosition}
          onPress={() => navigation.goBack()}
        >
          <Image
            source={{ uri: imatgeRecurs("boton_atras") }}
            style={{ height: 30, width: 30 }}
          />
        </TouchableOpacity>
      )}

      <RenderTimer time={time} poi={currentPoi} isVisible={isCurrentPoiGhost} />

      {!isCurrentPoiGhost && (
        <View style={globalstyle.filtersContainer}>
          <View
            style={{
              flexDirection: "row",
              backgroundColor: "white",
              paddingVertical: 20,
              paddingHorizontal: 30,
              borderRadius: 32,
              borderWidth: 1,
              justifyContent: "center",
            }}
          >
            <TouchableOpacity
              style={{ marginHorizontal: 12 }}
              onPress={() => setModalFiltresVisible(!modalFiltresVisible)}
            >
              <Image
                style={{ width: 30, height: 30 }}
                source={{ uri: imatgeRecurs("filtros_filtre") }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={{ marginHorizontal: 12 }}
              onPress={() => setFiltreFavoritosActiu(!filtreFavoritosActiu)}
            >
              <Image
                style={{ width: 24, height: 24 }}
                source={{ uri: filtreFavoritosSource }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                marginHorizontal: 12,
              }}
              onPress={handleChangeMapType}
            >
              <Image
                style={{ width: 30, height: 30 }}
                source={{ uri: imatgeRecurs("filtros_capes") }}
              />
            </TouchableOpacity>
          </View>
        </View>
      )}
      <HintModal
        modalVisible={hintVisible}
        setModalVisible={setHintVisible}
        pistes={pistes}
        errors={errors}
        setErrors={setErrors}
      />
      <CompliceModal
        modalCompliceVisible={modalComplicesVisible}
        setModalCompliceVisible={setModalComplicesVisible}
        item={currentEstabliment}
      />

      {ModalFiltres(
        modalFiltresVisible,
        setModalFiltresVisible,
        filtreActiu,
        setFiltreActiu,
      )}

      <ModalEtsAprop
        modalVisible={modalApropVisible}
        setModalVisible={setModalApropVisible}
        itinerari={itinerari}
        poi={currentPoi}
        setStartGame={setIsCurrentPoiGhost}
        setGhostStartTime={setGhostStartTime}
      />

      {modalPremisVisible && (
        <ModalPremis
          itinerari={itinerari}
          setModalVisible={setModalPremisVisible}
        />
      )}

      {ModalPuntInici(modalIniciVisible, setModalIniciVisible, itinerari)}

      {ModalGhostEnd(
        modalFinishGhost,
        handleTancaModalPuntFantasmaAcabat,
        checkErrors(errors),
        itinerari,
        currentPoi,
      )}

      {ModalItinerariComplet(modalCompletVisible, setModalCompletVisible)}
    </View>
  );
}

const styles = StyleSheet.create({
  liniaBotons: {
    flexDirection: "row",
    position: "absolute",
    top: 80,
    right: 12,
    gap: 10,
  },
  boto: {
    backgroundColor: "#FFF",
    borderRadius: 4,
    padding: 10,
    width: 40,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  botoLlarg: {
    width: 90,
  },
});
