import { Alert as AlertReactNative, Platform } from "react-native";

const alertPolyfill = (title, description, options, extra) => {
  const method = extra && extra.cancelable ? "alert" : "confirm";

  const result = window[method](
    [title, description].filter(Boolean).join("\n"),
  );

  if (result) {
    const confirmOption = options?.find(({ style }) => style !== "cancel");
    confirmOption?.onPress();
  } else {
    const cancelOption = options?.find(({ style }) => style === "cancel");
    cancelOption?.onPress();
  }
};

const Alert = Platform.OS === "web" ? alertPolyfill : AlertReactNative.alert;

export default Alert;
