import React from "react";
import { View, SafeAreaView } from "react-native";
// import { useNavigation } from "@react-navigation/native";
import globalstyle from "../../../Styles/Globals/globals";
import HeaderButtonsPerfil from "./HeaderButtonsPerfil";
import PuzzleGranCard from "./PuzzleGranCard";

export default function PuzzleGran() {
  // const itinerari = RouterData.route.params.itinerari;

  //TODO: Delate Carousel
  return (
    <SafeAreaView style={globalstyle.container}>
      <View style={{ flex: 1, backgroundColor: "rgba(249, 247, 232, 1)" }}>
        <HeaderButtonsPerfil showTutorial />

        <PuzzleGranCard />
      </View>
    </SafeAreaView>
  );
}
