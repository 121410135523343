import { useState, useEffect } from "react";

const useCountdownTimer = (initialTime: number, onCountdownFinish: any) => {
  const [timeLeft, setTimeLeft] = useState<number>(initialTime);

  useEffect(() => {
    // Exit early when we reach 0
    if (timeLeft === 0 || initialTime === 0) {
      return onCountdownFinish?.();
    }

    // Save intervalId to clear the interval when the component unmounts
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return timeLeft;
};

export default useCountdownTimer;
