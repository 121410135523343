import React, { useEffect, useState } from "react";
import {
  Image,
  ImageBackground,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import constants from "@/config/constants";
import HeaderCard from "@/Components/Components/HeaderCard";
import ModalLeerMas from "@/Components/Screens/Modals/ModalLeerMas";
import { useGlobal } from "@/Components/Context/globalContext";
import { useAuth } from "@/Components/Context/authContext";
import { postStadistic } from "@/Components/Functions/utils";
import * as FileSystem from "expo-file-system";

export default function RutesInfo({ route }: any) {
  const { itinerari } = route.params;

  const { __, textRecurs, imatgeRecurs, projecte } = useGlobal();
  const { user, savePerfil } = useAuth();
  const downloadFile = async () => {
    try {
      const fileUri = FileSystem.documentDirectory + itinerari.id + ".gpx";

      await FileSystem.downloadAsync(__(itinerari, "track")?.url, fileUri);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!__(itinerari, "track")) {
      return;
    }

    downloadFile();
  }, []);

  const navigation = useNavigation<any>();
  const [modalLeerMas, setModalLeerMas] = useState(false);

  const itinerariCompletat: boolean =
    Object.values(user.progres.itineraris[itinerari.id]?.pois || {}).length ===
    itinerari.pois.length;

  const navigateToMap = async () => {
    if (!user.progres.itineraris[itinerari.id]) {
      user.progres.itineraris[itinerari.id] = {
        id: itinerari.id,
        nom: itinerari.nom,
        inici: new Date().toISOString(),
        pois: {},
      };

      await savePerfil({
        progres: user.progres,
      });

      postStadistic({
        projecte: projecte.nom,
        token: user.id,
        tipus_peticio: "Inici itinerari",
        peticio: `Inici itinerari ${itinerari.nom}`,
        screen: "Mapa",
      });
    }

    navigation.navigate("Map", {
      itinerari,
    });
  };

  const navigateToPuzzleGif = ({}) => {
    navigation.navigate("PuzzlePerfil", { itinerari });
  };

  const renderButton = (backgroundColor, onPress, buttonTextKey) => (
    <TouchableOpacity
      style={{ ...style.buttonStyle, backgroundColor }}
      onPress={onPress}
    >
      <Text style={style.textStyle}>{textRecurs(buttonTextKey)}</Text>
    </TouchableOpacity>
  );

  const renderButtonJugar = () => {
    if (!itinerariCompletat) {
      return renderButton(
        itinerari.color_fons2,
        navigateToMap,
        itinerari.tipus === "Track" ? "onboarding_iniciar" : "poi_jugar",
      );
    }

    return renderButton(
      "rgba(124, 196, 0, 1)",
      navigateToPuzzleGif,
      "poi_ver_recompensa",
    );
  };

  return (
    <View
      style={{ ...style.mainContainer, overflow: "hidden", marginTop: "-40%" }}
    >
      <ImageBackground
        source={{ uri: itinerari.fons_detall?.url }}
        resizeMode="repeat"
        style={{
          flex: 1,
          backgroundColor: itinerari.color_fons1,
        }}
      >
        <View style={{ flex: 1, justifyContent: "center" }}>
          <HeaderCard itinerari={itinerari} />
        </View>
        <View style={{ flex: 4, marginHorizontal: 24 }}>
          <Text
            style={{
              fontFamily: "Poppins",
              fontWeight: "700",
              color: itinerari.color_text2,
              fontSize: 29,
            }}
          >
            {__(itinerari, "nom")}
          </Text>
          {__(itinerari, "subtitol") && (
            <Text
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: 13,
                lineHeight: 14.4,
                color: itinerari.color_text2,
              }}
            >
              {__(itinerari, "subtitol")}
            </Text>
          )}
          <View
            style={{
              height: "30vh",
              overflow: "scroll",
            }}
          >
            <Text
              style={{
                marginVertical: 7,
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: 14,
                lineHeight: 19.6,
                color: itinerari.color_text2,
              }}
            >
              {__(itinerari, "descripcio")}
            </Text>
          </View>
          {/*<Text*/}
          {/*  style={{*/}
          {/*    fontFamily: "Roboto",*/}
          {/*    fontWeight: "700",*/}
          {/*    fontSize: 14,*/}
          {/*    lineHeight: 19.6,*/}
          {/*    color: "rgba(61, 57, 53, 1)",*/}
          {/*  }}*/}
          {/*  onPress={() => setModalLeerMas(true)}*/}
          {/*>*/}
          {/*  Leer más*/}
          {/*</Text>*/}
          <View
            style={{
              flexDirection: "row",
              marginVertical: 12,
              alignItems: "center",
            }}
          >
            {itinerari.url && (
              <View>
                <Image
                  source={{ uri: imatgeRecurs("general_url") }}
                  style={{ width: 16, height: 16, marginRight: 4 }}
                />
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    fontSize: 14,
                    lineHeight: 12.6,
                    color: "rgba(15, 177, 228, 1)",
                  }}
                  onPress={() => Linking.openURL(itinerari.url)}
                >
                  {textRecurs("general_web")}
                </Text>
              </View>
            )}
          </View>
        </View>
        {renderButtonJugar()}
      </ImageBackground>
      {ModalLeerMas(modalLeerMas, setModalLeerMas, itinerari)}
    </View>
  );
}

const style = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#F9F7E8",
    borderTopStartRadius: 32,
    borderTopEndRadius: 32,
  },
  buttonStyle: {
    flex: 1,
    justifyContent: "center",
    borderRadius: 8,
    marginHorizontal: 24,
    marginVertical: 24,
  },
  textStyle: {
    color: constants.colorWhite,
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 16,
    textAlign: "center",
  },
});
