import React, { useEffect, useState } from "react";
import { Modal, Pressable, Text, View } from "react-native";
import Alert from "@/Components/Components/Alert";
import QRCode from "react-native-qrcode-svg";
import { perfilStyles } from "../Perfil/PerfilStyle";
import { useGlobal } from "../../Context/globalContext";
import api_urls from "@/config/api_urls";
import { useAuth } from "../../Context/authContext";

const TIMER_SEGONS = 5 * 60;

const QRModal = (
  modalQRVisible: boolean,
  setModalQRVisible: any,
  guadsCount: number,
  setGuadsSuccess: any,
  setModalQRFailVisible: any,
  setModalQRSuccessVisible: any,
) => {
  const { textRecurs } = useGlobal();

  const [apiConfirmat, setApiConfirmat] = useState(false);

  const { user, updatePuntsGastats, totalPointsSum } = useAuth();

  const userid = user?.uid;

  const [id, setId] = useState<number | null>(null);
  const [idReady, setIdReady] = useState(false);

  useEffect(() => {
    console.log("Guads count:" + guadsCount);
    console.log("totalPointsSum:" + totalPointsSum);

    if (modalQRVisible) {
      cambiarGuads(guadsCount);
    }
  }, [modalQRVisible]);

  const cambiarGuads = async (punts: number) => {
    const formData = new URLSearchParams();
    const persona = user.uid;
    //formData.append("establiment", "test");
    formData.append("punts", punts.toString());
    formData.append("persona", persona);
    try {
      const response = await fetch(`${api_urls.api_url}/moviments-de-punts`, {
        method: "POST",
        body: formData.toString(),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.status === 200) {
        const responseData = await response.json();
        const generatedID = responseData.id;
        console.log("ID generat:", generatedID);

        console.log("tot ok");
        console.log(generatedID);
        setId(generatedID);
        setIdReady(true);
      } else {
        Alert(`Error: ${response.statusText}`);
      }
    } catch (error: any) {
      Alert("Error en la crida a l'API: " + error.message);
    }
  };

  const TimerComponent = () => {
    const [seconds, setSeconds] = useState(TIMER_SEGONS);

    useEffect(() => {
      if (seconds < TIMER_SEGONS) {
        return;
      }

      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(interval);
    }, []);

    useEffect(() => {
      const intervalHola = setInterval(() => {
        checkConfirmat(id);
      }, 3000);

      return () => clearInterval(intervalHola);
    }, []);

    useEffect(() => {
      if (seconds <= 0) {
        if (apiConfirmat) {
          updatePuntsGastats(guadsCount);
        }
        setApiConfirmat(false);
        setModalQRVisible(false);
        setModalQRFailVisible(true);
      }
    }, [seconds]);
  };

  const checkConfirmat = async (id: any) => {
    try {
      const response = await fetch(
        `${api_urls.api_url}/moviments-de-punts/${id}`,
      );

      if (response.status === 200) {
        const responseData = await response.json();
        const confirmat = responseData.confirmat;
        if (confirmat) {
          console.log("Confirmat!");
          setApiConfirmat(true);
          setModalQRVisible(false);
          setGuadsSuccess(guadsCount);
          setModalQRSuccessVisible(true);
          fetchData();
        } else {
          console.log(responseData);
          console.log(confirmat);
        }
      } else {
        console.log("Failed to fetch confirmat value");
      }
    } catch (error) {
      console.log("Error fetching confirmat value:", error);
    }
  };

  return (
    <Modal
      transparent={true}
      visible={idReady}
      onRequestClose={() => {
        setModalQRVisible(!modalQRVisible);
      }}
    >
      <Pressable
        style={perfilStyles.modalContainer}
        onPress={() => {
          setModalQRVisible(!modalQRVisible);
          console.log(userid);
        }}
      >
        <View style={perfilStyles.modalQRCardContainer}>
          <View
            style={{
              padding: 16,
            }}
          >
            <View
              style={{
                padding: 30,
                backgroundColor: "white",
                borderRadius: 6,
                alignSelf: "center",
              }}
            >
              <QRCode
                value={`${user?.uid}###${guadsCount}##${id}`}
                size={200}
              />
            </View>
            <View
              style={{
                width: "95%",
                marginTop: 10,
                paddingLeft: 30,
              }}
            >
              <Text style={perfilStyles.tituloQRModal}>
                {guadsCount} {textRecurs("perfil_img4_guads")}
              </Text>

              <TimerComponent />

              <Text style={perfilStyles.infoQRModal}>
                {textRecurs("escanear_guads")}
              </Text>
            </View>

            <Pressable
              style={{
                borderColor: "#00491F",
                borderWidth: 1,
                alignSelf: "center",
                borderRadius: 30,
                paddingVertical: 15,
                width: 200,
                marginTop: 10,
              }}
              onPress={() => {
                setModalQRVisible(!modalQRVisible);
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  fontSize: 16,
                  color: "#00491F",
                }}
              >
                {textRecurs("perfil_img5_boton1")}
              </Text>
            </Pressable>
          </View>
        </View>
      </Pressable>
    </Modal>
  );
};

export default QRModal;
