import * as React from "react";
import { ImageBackground, View } from "react-native";
import globalstyle from "../../Styles/Globals/globals";
import { useGlobal } from "@/Components/Context/globalContext";

export default function AuthAppShell({ children, uri }: any) {
  const { imatgeRecurs } = useGlobal();
  uri ??= imatgeRecurs("background_default");

  return (
    <ImageBackground source={{ uri }} style={globalstyle.backgroundImatges}>
      <View style={globalstyle.containerBg}>{children}</View>
    </ImageBackground>
  );
}
