import React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { ResizeMode, Video } from "expo-av";
import { useGlobal } from "@/Components/Context/globalContext";

export default function Trucada(RouterData) {
  const { __, imatgeRecurs } = useGlobal();
  const { itinerari, premi } = RouterData.route.params;
  const video = React.useRef<Video>(null);

  const closeCall = () => {
    video.current?.stopAsync();

    RouterData.navigation.replace("PuzzlePerfil", {
      itinerari,
      esFinal: true,
    });
  };

  const handlePlaybackStatusUpdate = (playbackStatus) => {
    if (playbackStatus.didJustFinish) {
      closeCall();
    }
  };

  return (
    <View style={{ flex: 1, height: "100%", width: "100%" }}>
      <View
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "black",
          alignSelf: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <View style={{ height: "100%", width: "100%" }}>
          <Video
            ref={video}
            style={styles.video}
            source={{
              uri: __(premi, "so")?.url,
            }}
            videoStyle={styles.video}
            resizeMode={ResizeMode.COVER}
            shouldPlay
            onPlaybackStatusUpdate={handlePlaybackStatusUpdate}
          />
        </View>
      </View>
      <View
        style={{
          bottom: 25,
          left: 0,
          right: 0,
          position: "absolute",
          zIndex: 100,
        }}
      >
        {/*era 70 */}
        <View style={{ flexDirection: "row", width: "100%" }}>
          <Image
            source={{
              uri: imatgeRecurs("boton_llamada_camara"),
            }}
            style={{
              height: 100,
              width: "33%",
              resizeMode: "contain",
              alignSelf: "center",
            }}
          />
          <TouchableOpacity style={{ width: "33%" }} onPress={closeCall}>
            <Image
              source={{ uri: imatgeRecurs("boton_colgar") }}
              style={{
                height: 100,
                width: "100%",
                resizeMode: "contain",
                alignSelf: "flex-start",
              }}
            />
          </TouchableOpacity>
          <Image
            source={{
              uri: imatgeRecurs("boton_llamada_miss"),
            }}
            style={{
              height: 100,
              width: "33%",
              resizeMode: "contain",
              alignSelf: "flex-end",
            }}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#ecf0f1",
  },
  video: {
    flex: 1,
    alignSelf: "center",
    width: "100%",
    height: "100%",
  },
  buttons: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "rgba(30, 30, 30, 0.5)",
  },
});
