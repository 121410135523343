import React, { useRef, useState } from "react";
import {
  FlatList,
  Image,
  Modal,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
const ModalMunicipis = (
  modalVisible: boolean,
  setModalVisible: any,
  setMunicipiActiu: any,
  setMunicipiActiuId: any,
  filtreActiu: number | undefined,
) => {
  const { __, textRecurs, imatgeRecurs } = useGlobal();

  const [currentFilter, setCurrentFilter] = useState(0);

  const close = () => {
    setModalVisible(false);
  };

  const { projecte } = useGlobal();
  const handleFiltreActiu = (filtre: string, id: number) => {
    if (currentFilter === id) {
      filtre = "";
      id = 0;
    }
    setCurrentFilter(id);
    setMunicipiActiu(filtre);
    setMunicipiActiuId(id);

    close();
  };

  const ItinerariFiltre = (itinerari: any) => {
    return (
      <Pressable
        style={{
          borderWidth: 1,
          backgroundColor: "#F9F7E8",
          borderColor: "#00491F",
          borderRadius: 12,
          marginLeft: 12,
          marginRight: 12,
          justifyContent: "center",
          alignItems: "baseline",
          padding: 10,
          marginBottom: 10,
        }}
        onPress={() => {
          handleFiltreActiu(itinerari.Nom, itinerari.id);
        }}
      >
        <View style={{ flexDirection: "column" }}>
          <Text
            style={{
              fontFamily: "GeorgiaBold",
              color: "#3D3935",
              fontSize: 16,
            }}
          >
            {__(itinerari, "nom")}
          </Text>
          {__(itinerari, "subtitol") && (
            <Text
              style={{
                fontFamily: "Roboto",
                color: "#6D6D6D",
                fontSize: 13,
              }}
            >
              {__(itinerari, "subtitol")}
            </Text>
          )}
          {filtreActiu === itinerari.id && (
            <Image
              style={{ height: 25, width: 25 }}
              source={{ uri: imatgeRecurs("filtros_seleccionat") }}
            />
          )}
        </View>
      </Pressable>
    );
  };

  return (
    <Modal animationType="slide" transparent={true} visible={modalVisible}>
      <Pressable style={styles.modalContainer}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={{ marginVertical: 10 }}>
              <Text
                style={{
                  fontFamily: "GeorgiaBold",
                  fontSize: 24,
                }}
              >
                {textRecurs("filtros_filtrar")}
              </Text>
            </View>
            <FlatList
              data={projecte.itineraris}
              renderItem={({ item }) => {
                return (
                  <View>
                    <ItinerariFiltre {...item} />
                  </View>
                );
              }}
              style={{ width: "100%" }}
              keyExtractor={(item) => item.id}
            />
            <Pressable onPress={() => setModalVisible(!modalVisible)}>
              <Text style={styles.goBack}>
                {textRecurs("general_cancelar")}
              </Text>
            </Pressable>
          </View>
        </View>
      </Pressable>
    </Modal>
  );
};

import { useGlobal } from "../../Context/globalContext";

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    width: "100%",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    width: "90%",
  },
  modalView: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 4,
    gap: 8,
    isolation: "isolate",
    width: "100%",
    height: "60%",
    backgroundColor: "#E2E8D6",
    border: "1px solid #BBE0C4",
    borderRadius: 24,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  fontFiltre: {
    fontFamily: "Roboto",
    color: "#3D3935",
    fontSize: 16,
  },
  buttonFiltres: {
    backgroundColor: "#F9F7E8",
    borderColor: "#00491F",
    borderWidth: 1,
    borderRadius: 32,

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingHorizontal: 20,
    paddingVertical: 10,
    gap: 10,
    width: "100%",
    height: "15%",
  },
  buttonFiltresActive: {
    backgroundColor: "#F9F7E8",
    borderColor: "#7CC400",
    borderWidth: 2,
    borderRadius: 32,

    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    gap: 10,
    width: "100%",
    height: "15%",
  },

  buttonVerResultados: {
    backgroundColor: "#2A6438",
    borderRadius: 6,

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    gap: 10,
    marginVertical: 10,
    width: "100%",
    height: "15%",
  },
  buttonStartAdventureText: {
    // fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    color: "#FFFFFF",
    letterSpacing: 0.4,
  },
  goBack: {
    // fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    textAlign: "center",
    color: "#484848",
    marginBottom: "10%",
  },
});

export default ModalMunicipis;
