import React from "react";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import constants from "@/config/constants";
import { useGlobal } from "@/Components/Context/globalContext";

export default function HeaderButtons(itinerari: any) {
  const { imatgeRecurs } = useGlobal();
  const navigation = useNavigation<any>();

  return (
    <View
      style={{
        position: "absolute",
        zIndex: 999,
        width: "100%",
        marginTop: 40,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          height: 80,
          maxHeight: 80,
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: 15,
            paddingTop: 20,
          }}
        >
          <View style={style.navContainerTitle}>
            <Pressable onPress={() => navigation.goBack()}>
              <Image
                source={{ uri: imatgeRecurs("boton_atras") }}
                style={{ height: 30, width: 30 }}
              />
            </Pressable>
          </View>
          <View
            style={{
              flexGrow: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Pressable
              style={style.containerBtnOrange}
              onPress={() =>
                navigation.navigate("Tours360", {
                  itinerari,
                })
              }
            >
              <Image
                source={{ uri: imatgeRecurs("boton_360") }}
                style={{ height: 40, width: 40 }}
              />
            </Pressable>
            <Pressable
              style={style.containerBtnOrange}
              onPress={() =>
                navigation.navigate("GaleriaImatge", { itinerari })
              }
            >
              <Image
                source={{ uri: imatgeRecurs("boton_galeria") }}
                style={{ height: 40, width: 40, marginLeft: 5 }}
              />
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
}

const style = StyleSheet.create({
  navContainerTitle: {
    flexGrow: 1,
  },
  containerBtnOrange: {
    color: constants.colorWhite,
    borderRadius: 8,
    alignContent: "center",
  },
  textBtn: {
    color: constants.colorWhite,
    textAlign: "center",
    fontSize: 12,
    fontWeight: "700",
    letterSpacing: 0.8,
    lineHeight: 12,
    alignContent: "center",
    alignItems: "center",
  },
});
