import { Dimensions, StyleSheet } from "react-native";
import constants from "@/config/constants";

export const complicesStyles = StyleSheet.create({
  cardContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 12,
    height: 106,
    backgroundColor: "#F9F7E8",
    marginTop: 10,
    marginHorizontal: 20,
  },
  imagenComplice: {
    borderBottomLeftRadius: 12,
    borderTopLeftRadius: 12,
    borderColor: "#F9F7E8",
    borderWidth: 1,
    flex: 2,
    height: "100%",
  },
  nombreComplice: {
    fontFamily: "GeorgiaBold",
    fontSize: 16,

    alignItems: "center",
    letterSpacing: 0.02,
    color: "#3D3935",
  },
  cardFont: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 13,
    flexWrap: "wrap",
    width: "80%",
  },
  cardStarVector: {
    height: 10,
    width: 10,
    marginRight: 2,
  },
  cardPhoneVector: {
    height: 10,
    width: 10,
    marginRight: 5,
  },
  cardLocationVector: {
    height: 10,
    width: 8,
    marginRight: 5,
  },
  card: {
    alignItems: "center",
  },
  containerBtn: {
    flex: 0,
    height: 36,
    width: 165,
    // marginLeft: 'auto',
    // marginRight: 'auto',
    marginTop: 16,
    color: constants.colorWhite,
    alignItems: "center",
    justifyContent: "center",
    // paddingVertical: 15,
    // paddingHorizontal: 81,
    // borderStyle: 'solid',
    borderRadius: 6,

    backgroundColor: constants.colorGreenDarkBtn,
  },
  textBtn: {
    color: constants.colorWhite,
    // flex:2,
    textAlign: "center",
    fontSize: 13,
    fontWeight: "700",
    letterSpacing: 0.8,
    lineHeight: 12,
  },
  containerBtnOrange: {
    flex: 0,
    height: 32,
    width: 96,
    marginLeft: 12,
    // marginRight: 'auto',
    color: constants.colorWhite,
    alignItems: "center",
    justifyContent: "center",
    // paddingVertical: 15,
    // paddingHorizontal: 81,
    // borderStyle: 'solid',
    borderRadius: 6,
    backgroundColor: constants.colorOrange,
  },
  //MODAL
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  cardContainerList: {},
  modalCardContainer: {
    flexDirection: "column",
    borderRadius: 12,
    backgroundColor: "#F9F7E8",
    marginTop: 10,
    alignItems: "flex-start",
    width: "92%",
  },
  imagenModalComplice: {
    width: "100%",
    height: 253,
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,

    borderColor: "#F9F7E8",
    borderWidth: 1,
  },
  nombreCompliceModal: {
    fontFamily: "GeorgiaBold",
    fontSize: 24,

    alignItems: "center",
    letterSpacing: 0.02,
    color: "#3D3935",
  },
  modalFont: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
  },
  categoriaFiltre: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
    paddingVertical: 6,
    paddingHorizontal: 8,
  },
  categoriaFiltreActiu: {
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "white",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#00491F",
    borderRadius: 6,
    paddingVertical: 6,
    paddingHorizontal: 8,
  },
  fontCategoriaFiltre: { fontFamily: "Poppins", fontSize: 14, color: "white" },
  fontCategoriaFiltreActiu: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#00491F",
  },

  //Scroll a Home
  modalCardScrollContainer: {
    flex: 0,
    flexDirection: "column",
    borderRadius: 12,
    backgroundColor: "white",
    marginTop: 10,
    marginRight: 5,
    width: 128,
    height: 128,
  },
  imagenScrollComplice: {
    width: "100%",
    height: 72,
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    borderColor: "#F9F7E8",
    borderWidth: 1,
  },

  nombreCompliceScroll: {
    fontFamily: "GeorgiaBold",
    fontSize: 13,
    color: "#484848",
  },

  scrollFont: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 13,
    color: "#484848",
  },
});
