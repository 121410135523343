import * as React from "react";
import { useState } from "react";
import { ImageBackground, Linking, Platform, View } from "react-native";
import HeaderComponent from "@/Components/Components/HeaderComponent";
import InfoHome from "@/Components/Components/Home/InfoHome";
import DraggableBox from "@/Components/Screens/Modals/DraggableBox";
import ModalInici from "@/Components/Screens/Modals/ModalInici";
import useAsyncStorage from "@/Components/Hooks/useAsyncStorage";
import { useGlobal } from "@/Components/Context/globalContext";
import globalstyle from "@/Styles/Globals/globals";
import HomeOverlayInferior from "@/Components/Components/Home/HomeOverlayInferior";
import Constants from "expo-constants";
import Alert from "@/Components/Components/Alert";

export default function HomeScreen() {
  const [showModalInici, setShowModalInici] = useAsyncStorage(
    "showModalInici",
    true,
  );

  const { projecte, textRecurs } = useGlobal();
  const [itinerari, setItinerari] = useState<any>(projecte.itineraris[0]);
  const [showNovaVersio, setShowNovaVersio] = React.useState(
    projecte.versio > (Constants.expoConfig?.version ?? ""),
  );

  const handleChangeItinerari = (itinerari: any) => {
    setItinerari(itinerari);
  };

  if (showNovaVersio) {
    Alert(
      textRecurs("general_nova_versio_titol"),
      `${textRecurs("general_nova_versio_text")} ${projecte.versio}`,
      [
        {
          text: textRecurs("general_nova_versio_actualitzar"),
          onPress: () => {
            if (Platform.OS === "ios") {
              Linking.openURL(
                "https://apps.apple.com/es/app/els-reptes-damer/id6473425709",
              );
            } else {
              Linking.openURL(
                "https://play.google.com/store/apps/details?id=es.iternatura.elsreptesamer",
              );
            }

            setShowNovaVersio(false);
          },
        },
      ],
    );
  }

  return (
    <ImageBackground
      source={{ uri: itinerari.fons?.url }}
      style={globalstyle.backgroundImatges}
    >
      <View style={globalstyle.containerBg}>
        <HeaderComponent showTutorial />
        <View>
          <InfoHome onChange={handleChangeItinerari} />
          <DraggableBox initialTranslateY={-300} initialScrollTo={-300}>
            <HomeOverlayInferior />
          </DraggableBox>
        </View>
        {/*{showModalInici && ModalInici(setShowModalInici)}*/}
      </View>
    </ImageBackground>
  );
}
