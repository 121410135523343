import React from "react";
import { FlatList, Image, Pressable, Text, View } from "react-native";
import { complicesStyles } from "@/Components/Screens/Recursos/RecursosStyle";
import { useNavigation } from "@react-navigation/native";
import { useGlobal } from "../../Context/globalContext";

const Recurs = (establiment: any) => {
  const { __, textRecurs } = useGlobal();
  const navigation = useNavigation<any>();

  const navigateToEstabliment = (establiment: any) => {
    navigation.navigate("Recursos", { filtre: establiment.tipus });
  };

  const getTextRecurs = (tipus: string) => {
    return "recursos_" + tipus.toLowerCase().replaceAll(" ", "_");
  };

  return (
    <Pressable
      style={complicesStyles.cardContainerList}
      onPress={() => navigateToEstabliment(establiment)}
    >
      <View style={complicesStyles.modalCardScrollContainer}>
        <Image
          source={{
            uri: establiment.multimedia?.url,
          }}
          alt={__(establiment, "nom")}
          style={complicesStyles.imagenScrollComplice}
        />

        <View style={{ padding: 5 }}>
          <Text style={complicesStyles.nombreCompliceScroll} numberOfLines={3}>
            {__(establiment, "nom")}
          </Text>
          {/*<View*/}
          {/*  style={{*/}
          {/*    flexDirection: "row",*/}
          {/*    alignItems: "center",*/}
          {/*    paddingTop: 4,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Text style={complicesStyles.scrollFont}>*/}
          {/*    {textRecurs(getTextRecurs(establiment.tipus))}*/}
          {/*  </Text>*/}
          {/*</View>*/}
        </View>
      </View>
    </Pressable>
  );
};

interface RecursosScrollProps {
  tipus: string;
}

const RecursosScroll = ({ tipus }: RecursosScrollProps) => {
  const { projecte } = useGlobal();
  const establiments = projecte.establiments.filter((e) => e.tipus === tipus);

  return (
    <FlatList
      horizontal
      data={establiments.slice(0, 3)}
      renderItem={({ item, index }) => <Recurs key={index} {...item} />}
      contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
      keyExtractor={(item, index) => index.toString()}
    />
  );
};

export default RecursosScroll;
