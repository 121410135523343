import { StyleSheet } from "react-native";
import constants from "@/config/constants";

/* Styles for all PopUps */

const styles = StyleSheet.create({
  centeredView: {
    width: "100%",
    flex: 1,
    // justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    //marginTop: 40,
    //marginBottom: 48,
    justifyContent: "center",
  },
  modalView: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 40,
    paddingHorizontal: 24,
    paddingBottom: 48,
    gap: 24,
    isolation: "isolate",
    width: "80%",
    height: "60%",
    backgroundColor: "white",
    borderRadius: 24,
  },
  containerScrollView: {
    flex: 1,
  },
  scrollView: {
    borderRadius: 8,
    gap: 10,
    fontSize: 20,
  },
  textScrollView: {
    fontSize: 14,
    //fontFamily: Roboto,
    fontWeight: "400",
    lineHeight: 19.6,
    color: constants.colorGrey,
  },
  //TODO: oppacity turn 1 once onclick
  //TODO: scroll bar, smaller and green
  //TODO: Title
  // TODO: cross image
  btnDarkGreen: {
    borderRadius: 8,

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    gap: 10,
    width: "100%",
    height: "15%",
  },
  btnDarkGreenText: {
    // fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    color: constants.colorWhite,
    letterSpacing: 0.4,
    opacity: 1,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default styles;
