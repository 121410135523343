import React from "react";
import {
  Image,
  ImageBackground,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useGlobal } from "@/Components/Context/globalContext";

const ModalBase = ({ setModalVisible, children, title = "" }) => {
  const { imatgeRecurs, projecte } = useGlobal();

  const closeModal = () => setModalVisible(false);
  const styles = StyleSheet.create({
    modalContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
    },
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginVertical: 22,
      width: "90%",
    },
    modalView: {
      flexDirection: "column",
      padding: 22,
      gap: 24,
      isolation: "isolate",
      minWidth: 250,
      width: "100%",
      height: "100%",
      backgroundColor: projecte.color_secundari,
      borderRadius: 24,
      overflow: "hidden", // This is important for borderRadius to take effect
      justifyContent: "center",
    },
    closeButton: {
      marginLeft: "auto",
      zIndex: 1000,
      padding: 16,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      color: projecte.color_principal,
      fontWeight: "700",
      fontSize: 32,
      lineHeight: 38,
      fontFamily: "Georgia",
    },
  });

  const modalAmbImatgedeFons = () => (
    <Modal animationType="none" transparent={true} visible={true}>
      <View style={styles.modalContainer}>
        <View style={styles.centeredView}>
          <ImageBackground
            source={{ uri: projecte.fons.url }}
            style={styles.modalView}
            resizeMode={"cover"}
          >
            <View style={styles.header}>
              <Text style={styles.title}>{title}</Text>

              <TouchableOpacity onPress={closeModal} style={styles.closeButton}>
                <Image
                  source={{ uri: imatgeRecurs("boton_cerrar") }}
                  style={{ width: 16, height: 16 }}
                />
              </TouchableOpacity>
            </View>
            {children}
          </ImageBackground>
        </View>
      </View>
    </Modal>
  );

  const modalSenseImatgeDeFons = () => (
    <Modal animationType="none" transparent={true} visible={true}>
      <View style={styles.modalContainer}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.header}>
              <Text style={styles.title}>{title}</Text>

              <TouchableOpacity onPress={closeModal} style={styles.closeButton}>
                <Image
                  source={{ uri: imatgeRecurs("boton_cerrar") }}
                  style={{ width: 16, height: 16 }}
                />
              </TouchableOpacity>
            </View>
            {children}
          </View>
        </View>
      </View>
    </Modal>
  );

  if (projecte.fons?.url) {
    return modalAmbImatgedeFons();
  }

  return modalSenseImatgeDeFons();
};

export default ModalBase;
