import { StyleSheet } from "react-native";
import constants from "@/config/constants";

/* Styles for the component HomeScreen */

const style = StyleSheet.create({
  //##Carousel Styles##
  carouselContainer: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  logoScroll: {
    resizeMode: "contain",
    alignItems: "center",
  },
  cardContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 12,
  },
  scrollView: {
    flex: 1,
    alignItems: "center",
  },

  card: {
    alignItems: "center",
    flex: 1,
  },
  titleCard: {
    marginTop: 3,
    marginBottom: 2,
    fontSize: 20,
    lineHeight: 24,
    color: constants.colorBlackText,
    fontWeight: "700",
    letterSpacing: 0.64,
  },
  textCard: {
    fontSize: 13,
    lineHeight: 14,
    color: constants.colorBlackText,
    fontWeight: "400",
    letterSpacing: 0.32,
  },
  containerBtn: {
    flex: 0.4,
    width: "60%",
    height: "80%",
    marginTop: 16,
    color: constants.colorWhite,
    justifyContent: "center",
    borderRadius: 6,
    backgroundColor: constants.colorGreenDarkBtn,
  },
  textBtn: {
    color: constants.colorWhite,
    textAlign: "center",
    fontSize: 13,
    fontWeight: "700",
    letterSpacing: 0.8,
    lineHeight: 12,
  },
  poisProperties: {
    flex: 1,
    flexDirection: "row",
  },
  smallContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "45%",
    height: "85%",
    borderRadius: 5,
  },
  marginContainer: {
    marginRight: 8,
  },
  guadsContainer: {
    flex: 1,
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "92%",
    height: "35%",
    borderRadius: 5,
    paddingLeft: 12,
    paddingRight: 12,
  },
  containerBtnOrange: {
    flex: 0.5,
    height: "70%",
    width: "10%",
    color: constants.colorWhite,
    alignItems: "center",
    justifyContent: "center",
    // paddingVertical: 15,
    // paddingHorizontal: 81,
    borderRadius: 12,
    backgroundColor: constants.colorOrange,
  },

  containerPagination: {
    position: "absolute",
    top: constants.windowWidth / 1.3,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 20,
    justifyContent: "center",
    alignSelf: "center",
  },
  buttonScroll: {
    height: 20,
    width: 20,
    fontSize: 25,
    borderRadius: 20 / 2,
    backgroundColor: "rgba(124, 196, 0, 1)",
    opacity: 0.5,
    margin: 5,
  },
  buttonScrollHighlighted: {
    width: 20,
    height: 20,
    fontSize: 25,
    borderRadius: 20 / 2,
    borderWidth: 2,
    borderColor: constants.colorWhite,
    backgroundColor: constants.colorGreenClear,
    margin: 5,
  },
});

export default style;
