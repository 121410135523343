import React from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";
import constants from "@/config/constants";
import { useGlobal } from "../../Context/globalContext";
import axios from "axios";
import { API_URL, PROJECTE_ID } from "@/config/config";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@/Components/Context/authContext";
import { useNavigation } from "@react-navigation/native";
import AuthAppShell from "@/Components/Components/AuthAppShell";
import HeaderComponent from "@/Components/Components/HeaderComponent";

const Item = ({ id, username, score }) => {
  const extraStyle = {
    ...(id === 1 && styles.rankBadgeFirst),
    ...(id === 2 && styles.rankBadgeSecond),
    ...(id === 3 && styles.rankBadgeThird),
  };

  return (
    <View style={styles.item}>
      <View style={[styles.rankBadge, extraStyle]}>
        <Text style={styles.rankText}>{id.toString().padStart(2, "0")}</Text>
      </View>
      <Text style={styles.username}>{username}</Text>
      <Text style={styles.score}>{score}</Text>
    </View>
  );
};

export default function Ranking(RouterData: any) {
  const { textRecurs, imatgeRecurs, projecte } = useGlobal();
  const { token } = useAuth();
  const navigation = useNavigation();

  const fetchData = async () => {
    const result = await axios.get(
      API_URL + `/api/users-permissions/users/ranking?projecte=${PROJECTE_ID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return result.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ["ranking"],
    queryFn: fetchData,
  });

  const renderItem = ({ item, index }) => (
    <Item id={index + 1} username={item.nom} score={item.punts} />
  );

  return (
    <AuthAppShell>
      <HeaderComponent
        title={textRecurs("valoracion_cualificacion_global")}
        showBack={true}
        showSettings={false}
      />

      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={(item, index) => String(index)}
        style={{ marginBottom: 60, marginHorizontal: 20, flex: 1 }}
      />
    </AuthAppShell>
  );
}

const styles = StyleSheet.create({
  item: {
    backgroundColor: "#f0f0f0",
    padding: 20,
    marginVertical: 8,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 5,
  },
  rankBadge: {
    backgroundColor: "rgba(235, 235, 235, 1)",
    borderRadius: 50,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  rankText: {
    color: "black",
  },
  rankBadgeFirst: {
    backgroundColor: "rgba(255, 196, 9, 1)",
    fontWeight: "bold",
  },
  rankBadgeSecond: {
    backgroundColor: "rgba(204, 204, 204, 1)",
    fontWeight: "bold",
  },
  rankBadgeThird: {
    backgroundColor: "rgba(228, 151, 0, 1)",
    fontWeight: "bold",
  },
  username: {
    flex: 1,
    fontWeight: "bold",
  },
  score: {
    fontWeight: "bold",
  },
  mainContainer: {
    paddingHorizontal: 16,
    flex: 1,
  },
  mainTitle: {
    fontFamily: "Poppins",
    flex: 0,
    fontSize: 32,
    fontWeight: "700",
    lineHeight: 38,
    letterSpacing: 0.64,
  },
  infoText: {
    flex: 0,
    fontSize: 16,
    fontWeight: "400",
    color: constants.colorBlackText,
    marginTop: 8,
    //marginBottom: 15,
    textAlign: "justify",
    // width: 327,
    height: 66,
    // marginLeft:24,
    lineHeight: 22.4,
    //backgroundColor:'red'
  },
  input: {
    flex: 0,
    // width: 327,
    // height: 47,
    marginTop: 1,
    // marginHorizontal:24,
    paddingLeft: 24,
    paddingTop: 5,
    borderWidth: 1,
    borderRadius: 32,
    borderColor: constants.colorGreenDarkBtn,
    backgroundColor: constants.colorWhite,
    fontSize: 16,
    color: constants.colorGreenDarkBtn,
    //height: 40,
    //margin: 12,
    padding: 10,
  },
  btnLogin: {
    // width: 327,
    height: 56,
    marginTop: 24,
    // marginHorizontal:24,
    marginBottom: 40,
    // paddingHorizontal:10,
    paddingVertical: 12,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: constants.colorGreenDarkBtn,
    borderRadius: 32,
    fontSize: 16,
  },
  btnLoginText: {
    color: constants.colorWhite,
    letterSpacing: 0.8,
    fontSize: 16,
    fontWeight: "700",
    textAlign: "center",
    justifyContent: "center",
  },
});
