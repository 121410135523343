import { useEffect, useState } from "react";
import { useGlobal } from "@/Components/Context/globalContext";
import * as Location from "expo-location";
import Alert from "@/Components/Components/Alert";
import { getDistanceFromLatLonInMeters } from "@/Components/Functions/MapFunctions";
import { Platform } from "react-native";

const useLocation = (itinerari: any, geofence: [any]) => {
  const { __, textRecurs, imatgeRecurs, projecte } = useGlobal();

  const DEFAULT_LATITUDE = projecte.latitud ?? 40;
  const DEFAULT_LONGITUDE = projecte.longitud ?? 3;

  const [isLoadingLocation, setIsLoadingLocation] = useState<boolean>(true);

  const [currentLocation, setCurrentLocation] = useState<any>({
    latitude: null,
    longitude: null,
  });

  const [mapRegion, setMapRegion] = useState({
    latitude: DEFAULT_LATITUDE,
    longitude: DEFAULT_LONGITUDE,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  });

  const [foraDelGeofence, setForaDelGeofence] = useState(false);

  const getCurrentPosition = async () => {
    try {
      const {
        coords: { latitude, longitude },
      } = await Location.getCurrentPositionAsync({
        accuracy: Location.Accuracy.Highest,
      });

      setCurrentLocation({ latitude, longitude });
    } catch (error) {
      console.log(error);
    }
  };

  const subscribeToLocationUpdates = () => {
    return Location.watchPositionAsync(
      {
        accuracy: Location.Accuracy.High,
        timeInterval: 5000,
        distanceInterval: 2,
      },
      (position) => {
        const { latitude, longitude } = position.coords;

        setCurrentLocation({
          latitude,
          longitude,
          latitudeDelta: 0.00622,
          longitudeDelta: 0.00421,
        });
      },
    ).catch((error) => {
      console.error("Error subscribing to location updates:", error);
    });
  };

  const suscribeToLocationUpdatesWeb = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
      return;
    }

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        setCurrentLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (e) => {
        alert("GEOCODE ERROR");
      },
      { enableHighAccuracy: true },
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  };

  useEffect(() => {
    let subscription;

    Location.requestForegroundPermissionsAsync().then(async ({ status }) => {
      if (status !== "granted") {
        return Alert("", textRecurs("error_permission_location"));
      }

      getCurrentPosition();

      if (Platform.OS === "web") {
        return suscribeToLocationUpdatesWeb();
      }

      subscription = await subscribeToLocationUpdates();
    });
    return () => {
      subscription?.remove();
    };
  }, []);

  useEffect(() => {
    if (!geofence.length) {
      return;
    }

    const minDistance = geofence.reduce((minDist, currentCoord) => {
      let dist = getDistanceFromLatLonInMeters(
        currentLocation.latitude,
        currentLocation.longitude,
        currentCoord.latitude,
        currentCoord.longitude,
      );

      return Math.min(minDist, dist);
    }, Infinity);

    if (minDistance > itinerari.metres_activacio_geofence) {
      setForaDelGeofence(true);
    } else {
      setForaDelGeofence(false);
    }
  }, [currentLocation]);

  useEffect(() => {
    if (currentLocation.latitude && mapRegion.latitude === DEFAULT_LATITUDE) {
      setMapRegion({
        ...currentLocation,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
      });

      setIsLoadingLocation(false);
    }
  }, [currentLocation]);

  return {
    currentLocation,
    mapRegion,
    foraDelGeofence,
    isLoadingLocation,
  };
};

export default useLocation;
