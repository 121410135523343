import React, { useState } from "react";
import {
  ActivityIndicator,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useGlobal } from "../../Context/globalContext";
import AuthAppShell from "@/Components/Components/AuthAppShell";
import { WebView } from "react-native-webview";
import { useAuth } from "@/Components/Context/authContext";

export default function Premi(RouterData: any) {
  const { textRecurs, imatgeRecurs, projecte, __ } = useGlobal();
  const { user, savePerfil } = useAuth();

  const { premi, itinerari, esFinal, poi, punts, poiOriginal } =
    RouterData.route.params;
  const [isLoading, setIsLoading] = useState(false);
  const esTrivial = !!poiOriginal;

  const handleContinuar = async () => {
    if (esFinal) {
      return RouterData.navigation.navigate("PuzzlePerfil", {
        ...RouterData.route.params,
      });
    }

    setIsLoading(true);

    try {
      user.progres.itineraris[itinerari.id].pois[poi.id] = {
        id: poi.id,
        punts: punts || 0,
        dataihora: new Date().toISOString(),
        esPreguntaEncadenada: esTrivial,
      };

      await savePerfil({
        progres: user.progres,
      });
    } catch (e) {
      console.log("Reward", e);
    } finally {
      setIsLoading(false);
    }

    if (esTrivial) {
      return RouterData.navigation.replace("Trivial", {
        ...RouterData.route.params,
        poi: poiOriginal,
      });
    }

    RouterData.navigation.replace("Map", {
      ...RouterData.route.params,
    });
  };

  const headerFontSize = premi.url ? 24 : 32;
  const textFontSize = premi.url ? 18 : 28;

  const getMultimedia = () => {
    if (!premi.url) {
      return null;
    }

    if (Platform.OS === "web") {
      return (
        <iframe
          src={premi.url}
          height={"100%"}
          width={"100%"}
          style={{ marginBottom: 20, border: "none" }}
        />
      );
    }

    return (
      <WebView
        source={{
          uri: premi.url,
        }}
        allowFullScreen={true}
        style={{
          marginVertical: 20,
        }}
      />
    );
  };

  return (
    <AuthAppShell uri={itinerari.fons?.url}>
      <View style={{ padding: 20, flex: 1 }}>
        <Text
          style={{
            fontFamily: "Roboto",
            fontWeight: "700",
            fontSize: headerFontSize,
            textAlign: "center",
            color: itinerari.color_text1,
          }}
        >
          {__(premi, "popup_titol")}
        </Text>
        <Text
          style={{
            fontFamily: "Roboto",
            fontWeight: "700",
            fontSize: textFontSize,
            textAlign: "center",
            color: itinerari.color_text1,
            marginTop: 40,
          }}
        >
          {__(premi, "popup_text")}
        </Text>

        {getMultimedia()}

        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <TouchableOpacity
            onPress={handleContinuar}
            style={{
              justifyContent: "center",
              borderRadius: 8,
              backgroundColor: itinerari.color_fons1,
              marginTop: "auto",
              marginBottom: 20,
              padding: 20,
              marginHorizontal: 40,
            }}
          >
            <Text
              style={{
                fontFamily: "Roboto",
                fontWeight: "700",
                fontSize: 28,
                textAlign: "center",
                color: itinerari.color_text1,
              }}
            >
              {textRecurs("onboarding_continuar")}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </AuthAppShell>
  );
}
