import React, { useEffect, useState } from "react";
import {
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useIsFocused } from "@react-navigation/native";
import globalstyle from "@/Styles/Globals/globals";
import style from "./RewardStyle";
import ModalPremi from "@/Components/Screens/Modals/ModalPremi";
import { useGlobal } from "@/Components/Context/globalContext";
import { useAuth } from "@/Components/Context/authContext";
import { postStadistic } from "@/Components/Functions/utils";
import { playSoundForAWhile } from "@/Components/Functions/MapFunctions";

export default function Reward(RouterData: any) {
  const { __, textRecurs, imatgeRecurs, projecte } = useGlobal();
  const isFocused = useIsFocused();
  const { user, savePerfil } = useAuth();

  const { itinerari, poi, poiOriginal, esFinal } = RouterData.route.params;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalPremiVisible, setModalPremiVisible] = useState<boolean>(
    itinerari.tipus === "Track",
  );

  const puntsGuanyats = RouterData.route.params.punts || 0;

  const esTrivial = !!poiOriginal;

  useEffect(() => {
    postStadistic({
      projecte: projecte.nom,
      token: user.id,
      peticio: `${poi.id} ${__(poi, "nom")} ${puntsGuanyats} puntos`,
      tipus_peticio: "POI_COMPLETADO",
    });
  }, []);

  useEffect(() => {
    const soundPath = imatgeRecurs(
      puntsGuanyats === 0 ? "poi_error_so" : "poi_encert_so",
    );

    playSoundForAWhile(soundPath, undefined, () => {});
  }, []);

  const showPremi = async () => {
    if (poi.premi?.url) {
      return RouterData.navigation.replace("Premi", {
        ...RouterData.route.params,
        premi: poi.premi,
      });
    }
    setModalPremiVisible(true);
  };

  if (isFocused) {
    const callback = async () => {
      setIsLoading(true);

      try {
        user.progres.itineraris[itinerari.id].pois[poi.id] = {
          id: poi.id,
          punts: puntsGuanyats,
          dataihora: new Date().toISOString(),
          esPreguntaEncadenada: esTrivial,
        };

        await savePerfil({
          progres: user.progres,
        });
      } catch (e) {
        console.log("Reward", e);
      } finally {
        setIsLoading(false);
      }

      if (esTrivial && !esFinal) {
        return RouterData.navigation.replace("Trivial", {
          ...RouterData.route.params,
          poi: poiOriginal,
        });
      }

      RouterData.navigation.replace("Map", {
        ...RouterData.route.params,
      });
    };

    return (
      <View style={globalstyle.container}>
        <ImageBackground
          source={{
            uri: itinerari.fons?.url,
          }}
          style={style.backgroundImatges}
        >
          <View style={style.rewardHeader}>
            {puntsGuanyats === 0 ? (
              <Image
                style={{ height: "35%", width: "35%" }}
                source={{ uri: imatgeRecurs("poi_error_imatge") }}
              />
            ) : (
              <Image
                style={{ height: "35%", width: "35%" }}
                source={{ uri: imatgeRecurs("poi_encert_imatge") }}
              />
            )}

            <Text style={style.titolReward}>
              {puntsGuanyats === 0
                ? __(poi.premi, "resposta_incorrecta") ||
                  textRecurs("poi_respuesta_incorrecta")
                : __(poi.premi, "resposta_correcta") ||
                  textRecurs("poi_respuesta_correcta")}
            </Text>
            <Text style={style.textReward}>
              {puntsGuanyats === 0
                ? `${textRecurs("perfil_ningun_punto")}`
                : `${textRecurs("poi_has_ganado_puntos", [puntsGuanyats])}`}
            </Text>
          </View>

          <View style={style.continueRewardBackground}>
            <TouchableOpacity
              onPress={showPremi}
              style={[
                style.botoContinuar,
                { backgroundColor: itinerari.color_fons2 },
              ]}
            >
              <Text style={style.textContinuar}>
                {textRecurs("onboarding_continuar")}
              </Text>
            </TouchableOpacity>
          </View>
        </ImageBackground>
        <ModalPremi
          modalPremiVisible={modalPremiVisible}
          setModalPremiVisible={setModalPremiVisible}
          premi={poi.premi}
          itinerari={itinerari}
          callback={callback}
          isLoading={isLoading}
        />
      </View>
    );
  }
}
