import React from "react";
import { Image, Modal, Pressable, View } from "react-native";
import { useGlobal } from "../../Context/globalContext";
import styles from "@/Styles/Globals/globals";
import TaulaAtributs from "@/Components/Components/Itinerari/TaulaAtributs";

const ModalLeerMas = (
  modalLeerMas: boolean,
  setModalLeerMas: any,
  itinerari: any,
) => {
  const { __, textRecurs, imatgeRecurs } = useGlobal();

  return (
    <Modal
      transparent={true}
      visible={modalLeerMas}
      onRequestClose={() => setModalLeerMas(false)}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalCardContainer}>
          <View style={styles.closeButton}>
            <Pressable
              onPress={() => setModalLeerMas(false)}
              style={styles.closeButtonPressable}
            >
              <Image
                source={{ uri: imatgeRecurs("boton_cerrar") }}
                style={{ width: 16, height: 16 }}
              />
            </Pressable>
          </View>
          <View style={styles.modalContent}>
            <TaulaAtributs itinerari={itinerari} />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ModalLeerMas;
