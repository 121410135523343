import React, { useState } from "react";
import {
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Image } from "expo-image";
import { useNavigation } from "@react-navigation/native";
import constants from "@/config/constants";
import ModalTutorial from "@/Components/Screens/Modals/ModalTutorial";
import { useGlobal } from "@/Components/Context/globalContext";

export default function HeaderComponent({
  title = "",
  showTutorial = false,
  showGoHome = false,
  showBack = false,
  showSettings = true,
  customBack = undefined,
}) {
  const { textRecurs, projecte, imatgeRecurs } = useGlobal();

  const [modalTutorial, setModalTutorial] = useState(false);

  const navigation = useNavigation<any>();

  const handleSettingsPress = () => {
    navigation.navigate("Settings");
  };

  const handleBackPress = () => {
    if (customBack) {
      return customBack();
    }

    return showBack ? navigation.goBack() : navigation.navigate("Tabs");
  };

  return (
    <View
      style={{ flexGrow: 1, flexDirection: "row", height: 80, maxHeight: 80 }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          paddingHorizontal: 15,
          paddingTop: 20,
        }}
      >
        {(showBack || showGoHome) && (
          <View style={{ padding: 10 }}>
            <Pressable onPress={handleBackPress}>
              <Image
                source={{ uri: imatgeRecurs("boton_atras") }}
                style={{ height: 30, width: 30 }}
              />
            </Pressable>
          </View>
        )}
        <View style={style.navContainerTitle}>
          <Text
            style={{
              fontSize: 20,
              color: constants.colorWhite,
              fontWeight: "400",
              // fontFamily: "Amarante",
            }}
          >
            {title}
          </Text>
        </View>
        <View
          style={{
            flexGrow: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {showTutorial ? (
            <Pressable
              style={{
                ...style.containerBtnOrange,
                backgroundColor: projecte.color_principal,
              }}
              onPress={() => setModalTutorial(true)}
            >
              <Text style={style.textBtn}>
                {textRecurs("general_tutorial")}
              </Text>
            </Pressable>
          ) : null}
          {showSettings && (
            <TouchableOpacity
              onPress={handleSettingsPress}
              style={{ padding: 16 }}
            >
              <Image
                source={{ uri: imatgeRecurs("general_ajustes") }}
                style={{
                  width: 16,
                  height: 16,
                }}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
      {modalTutorial && ModalTutorial(setModalTutorial)}
    </View>
  );
}

const style = StyleSheet.create({
  navContainerTitle: {
    flexGrow: 1,
  },
  containerBtnOrange: {
    color: constants.colorWhite,
    //alignItems: 'center',
    //justifyContent: 'center',
    paddingVertical: 8,
    paddingHorizontal: 5,
    borderRadius: 8,
    backgroundColor: constants.colorOrange,
    alignContent: "center",
    marginRight: 15,
  },
  textBtn: {
    //fontStyle:'Roboto',
    color: constants.colorWhite,
    textAlign: "center",
    fontSize: 12,
    fontWeight: "700",
    letterSpacing: 0.8,
    lineHeight: 12,
    alignContent: "center",
    alignItems: "center",
    //backgroundColor:'green'
  },
});
