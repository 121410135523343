import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Image } from "expo-image";
import { useGlobal } from "@/Components/Context/globalContext";
import { Audio } from "expo-av";
import { playSoundForAWhile } from "@/Components/Functions/MapFunctions";

export default function PreviTrucada(RouterData: any) {
  const { __, textRecurs, imatgeRecurs, projecte } = useGlobal();
  const [sound, setSound] = useState<Audio.Sound | null>(null);

  const { itinerari, premi } = RouterData.route.params;

  useEffect(() => {
    playSoundForAWhile(imatgeRecurs("general_llamada"), undefined, setSound, {
      isLooping: true,
      volume: 1,
    });

    return () => {
      sound?.stopAsync();
      sound?.unloadAsync();
    };
  }, []);

  const closeCall = () => {
    sound?.stopAsync();
    sound?.unloadAsync();

    RouterData.navigation.replace("ItinerariDetail", RouterData.route.params);
  };
  const goCall = () => {
    sound?.stopAsync();
    sound?.unloadAsync();

    RouterData.navigation.replace("Trucada", RouterData.route.params);
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#000", paddingVertical: 40 }}>
      <View style={{ flex: 60, justifyContent: "center" }}>
        <View
          style={{
            flex: 1,
            alignSelf: "center",
          }}
        >
          <View style={{ flex: 1 }}>
            <Text style={style.text}>{__(premi, "popup_titol")}</Text>
          </View>
          <View
            style={{
              flex: 1,
            }}
          >
            <Image
              placeholder={require("@/assets/Images/loading.gif")}
              source={{
                uri: __(premi, "multimedia")?.url,
              }}
              style={{
                flex: 1,
              }}
              contentFit={"cover"}
            />
          </View>
        </View>
      </View>

      <View style={{ flex: 10 }} />

      {/* Agafar/penjar icones  */}
      <View style={{ flex: 20 }}>
        <View
          style={{
            flex: 1,
            alignSelf: "center",
            width: "100%",
            height: "100%",
            flexDirection: "row",
            alignContent: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              goCall();
            }}
            style={{ width: "50%", height: "60%", alignSelf: "center" }}
          >
            <Image
              style={{ width: "100%", height: "100%", resizeMode: "contain" }}
              source={{
                uri: imatgeRecurs("boton_aceptar_llamada"),
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => closeCall()}
            style={{ width: "50%", height: "60%", alignSelf: "center" }}
          >
            <Image
              style={{ width: "100%", height: "100%", resizeMode: "contain" }}
              source={{ uri: imatgeRecurs("boton_colgar") }}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const style = StyleSheet.create({
  text: {
    color: "white",
    textAlign: "center",
    alignItems: "center",
    fontSize: 50,
    fontWeight: "bold",
  },
});
