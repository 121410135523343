import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";

export default function useIdioma() {
  const [idioma, setIdioma] = useState<any>("ES");

  useEffect(() => {
    AsyncStorage.getItem("idioma").then((value: any) => {
      setIdioma(value ?? idioma);
    });
  });

  const guardarIdioma = async (idioma: string) => {
    idioma === "EU" ? (idioma = "DE") : idioma; // Es fan servir els camps d'alemany a Strapi

    await AsyncStorage.setItem("idioma", idioma);

    setIdioma(idioma);
  };

  return {
    idioma,
    guardarIdioma,
  };
}
