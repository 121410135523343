import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import constants from "@/config/constants";
import ModalPrivacy from "../Modals/ModalPrivacy";
import ModalCookies from "../Modals/ModalCookies";
import Alert from "@/Components/Components/Alert";

import { useAuth } from "@/Components/Context/authContext";
import { useDebounce } from "@/Components/Hooks/useDebounce";
import { useGlobal } from "@/Components/Context/globalContext";

export default function Signup(RouterData: any) {
  const { textRecurs, projecte } = useGlobal();

  const { register } = useAuth();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [modalVisiblePrivacy, setModalVisiblePrivacy] = useState(false);
  const [modalVisibleCookies, setModalVisibleCookies] = useState(false);

  const debounce = useDebounce();

  const navigateToLogin = () => {
    RouterData.navigation.navigate("LoginScreen", {
      ...RouterData.route.params,
    });
  };

  const validateName = () => {
    if (name.trim() === "" || name.length < 3) {
      setNameError(textRecurs("acceso_error_nombre_obligatorio"));
    } else {
      setNameError("");
    }
  };

  const signUp = async () => {
    if (name.trim() === "" || name.length < 3) {
      return validateName();
    }

    if (!email.trim() || !password.trim()) {
      return setEmailError(textRecurs("acceso_correo_electronico_pista"));
    }

    setEmailError("");

    const error = await register(name.trim(), email.trim(), password);

    if (error) {
      Alert(
        "Error",
        textRecurs("acceso_error_cuenta"),
        [{ text: "OK", style: "cancel" }],
        { cancelable: true },
      );
    }
  };

  return (
    <View style={style.mainContainer}>
      <View
        style={{
          backgroundColor: "#fff",
          paddingHorizontal: 24,
          paddingTop: 24,
        }}
      >
        <Text style={{ ...style.mainTitle, color: projecte.color_principal }}>
          {textRecurs("acceso_registrar_titulo")}
        </Text>
        <Text style={style.infoText}>
          {textRecurs("acceso_registrar_descripcion")}
        </Text>
      </View>
      <View style={{ paddingHorizontal: 24 }}>
        <View>
          {/* Name Input */}
          <View style={style.inputContainer}>
            <Text style={style.label}>{textRecurs("acceso_nombre")}</Text>
            <TextInput
              style={style.input}
              value={name}
              onChangeText={(value) => setName(value)}
              onBlur={validateName}
              placeholder={textRecurs("acceso_nombre_pista")}
              placeholderTextColor={projecte.color_principal}
            />
          </View>
          {nameError !== "" && <Text style={style.errorText}>{nameError}</Text>}

          {/* Email Input */}
          <View style={style.inputContainer}>
            <Text style={style.label}>{textRecurs("correo_electronico")}</Text>
            <TextInput
              style={style.input}
              value={email}
              onChangeText={(value) => setEmail(value)}
              autoCapitalize="none"
              placeholder={textRecurs("acceso_correo_electronico_pista")}
              placeholderTextColor={projecte.color_principal}
            />
          </View>
          {emailError !== "" && (
            <Text style={style.errorText}>{emailError}</Text>
          )}

          {/* Password Input */}
          <View style={style.inputContainer}>
            <Text style={style.label}>{textRecurs("acceso_contrasena")}</Text>
            <TextInput
              style={style.input}
              value={password}
              onChangeText={(value) => setPassword(value)}
              autoCapitalize="none"
              secureTextEntry={true}
              placeholder={textRecurs("acceso_contrasena_pista")}
              placeholderTextColor={projecte.color_principal}
            />
          </View>
        </View>
        <TouchableOpacity
          style={{
            ...style.btnLogin,
            backgroundColor: projecte.color_principal,
          }}
          onPress={() => debounce(signUp, 1000)}
        >
          <Text style={style.btnLoginText}>
            {textRecurs("acceso_registrar_boton")}
          </Text>
        </TouchableOpacity>

        <View style={{ width: 327, height: 17, flexDirection: "row" }}>
          <Text
            style={{
              color: constants.colorBlackText,
              letterSpacing: 0.64,
              fontSize: 14,
              height: 56,

              paddingVertical: 12,
              textAlign: "center",
            }}
          >
            {textRecurs("acceso_ya_tienes_cuenta")}
          </Text>
          <TouchableOpacity onPress={navigateToLogin} style={style.btnGoLogin}>
            <Text
              style={{
                marginLeft: 2,
                letterSpacing: 0.64,
                color: projecte.color_principal,
                fontSize: 14,
                fontWeight: "700",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              {" "}
              {textRecurs("acceso_click_aqui")}
            </Text>
          </TouchableOpacity>
        </View>

        <View style={style.cookiesText}>
          <Text>
            <Text
              style={{
                color: constants.colorBlackText,
                letterSpacing: 0.64,
                fontSize: 14,
                height: 56,

                paddingVertical: 12,
                textAlign: "center",
              }}
            >
              {textRecurs("acceso_texto_legal1")}
            </Text>
            <Text
              style={style.links}
              onPress={() => setModalVisibleCookies(true)}
            >
              {" "}
              {textRecurs("aviso_legal")}
            </Text>
            <Text
              style={{
                color: constants.colorBlackText,
                letterSpacing: 0.64,
                fontSize: 14,
                height: 56,

                paddingVertical: 12,
                textAlign: "center",
              }}
            >
              {" "}
              {textRecurs("acceso_texto_legal2")}{" "}
            </Text>
            <Text
              style={style.links}
              onPress={() => setModalVisiblePrivacy(true)}
            >
              {textRecurs("aviso_privacidad")}
            </Text>
            <Text>.</Text>
          </Text>
        </View>
      </View>
      {ModalPrivacy(modalVisiblePrivacy, setModalVisiblePrivacy)}
      {ModalCookies(modalVisibleCookies, setModalVisibleCookies)}
    </View>
  );
}

const style = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },

  mainTitle: {
    flex: 0,
    fontSize: 32,
    fontWeight: "700",
    color: constants.colorGreenDark,
  },
  infoText: {
    flex: 0,
    fontSize: 16,
    fontWeight: "400",
    color: constants.colorBlackText,
    marginTop: 8,
    marginBottom: 15,
    textAlign: "justify",
    // width: 327,
    height: 66,
    // marginLeft:24,
    lineHeight: 22.4,
  },
  input: {
    fontFamily: "Roboto",
    flex: 0,
    marginTop: 12,
    paddingLeft: 24,
    paddingTop: 5,
    borderWidth: 1,
    borderRadius: 32,
    backgroundColor: constants.colorWhite,
    fontSize: 16,
    fontWeight: "400",
  },
  btnLogin: {
    // width: 327,
    height: 56,
    marginTop: 24,
    // marginHorizontal:24,
    marginBottom: 40,
    // paddingHorizontal:10,
    paddingVertical: 12,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 8,
    fontSize: 16,
  },
  btnGoLogin: {
    height: 56,
    paddingVertical: 12,
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "row",
    borderRadius: 8,

    fontSize: 16,
  },
  btnLoginText: {
    color: constants.colorWhite,
    letterSpacing: 0.8,
    fontSize: 16,
    fontWeight: "700",
    textAlign: "center",
    justifyContent: "center",
  },
  cookiesText: {
    // height: 110,
    textAlign: "left",
    lineHeight: 19.6,
    flexDirection: "column",
    marginTop: 65,
    color: constants.colorBlackText,
    letterSpacing: 0.64,
    fontSize: 14,
  },
  links: {
    fontWeight: "700",
    margin: 5,
    textAlign: "center",
    letterSpacing: 0.64,
  },
  inputContainer: {},
  label: {
    paddingTop: 23,
    paddingLeft: 4,
    color: "rgba(0,73,31)",
    fontSize: 10,
    fontWeight: "400",
  },
  inputStyles: {
    fontSize: 16,
    fontWeight: "400",
    // Additional text input styles
  },
  errorText: {
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: 13,
    lineHeight: 12,
    letterSpacing: 0.64,
    color: "red",
    textAlign: "left",
    paddingLeft: 23,
    paddingTop: 6,
  },
});
