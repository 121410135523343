import * as React from "react";
import { useState } from "react";
import {
  Image,
  ImageBackground,
  Pressable,
  Share,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import constants from "@/config/constants";
import { LinearGradient } from "expo-linear-gradient";
import { perfilStyles } from "./PerfilStyle";
import DraggableBox from "../Modals/DraggableBox";
import AuthAppShell from "@/Components/Components/AuthAppShell";
import HeaderComponent from "@/Components/Components/HeaderComponent";
import { useNavigation } from "@react-navigation/native";
import { FlatList } from "react-native-gesture-handler";
import { useGlobal } from "@/Components/Context/globalContext";
import { useAuth } from "@/Components/Context/authContext";
import InfoModalPerfil from "../Modals/InfoModalPerfil";
import { puntsPerItinerari, totalPunts } from "@/Components/Functions/utils";

const InfoPerfil = () => {
  const { textRecurs, imatgeRecurs, projecte, __ } = useGlobal();

  const { user } = useAuth();
  const navigation = useNavigation<any>();
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const myPoints = totalPunts(user.progres.itineraris);

  const onShare = async () => {
    try {
      await Share.share({
        message: __(projecte, "text_social"),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToEditProfile = ({}) => navigation.navigate("EditarPerfil", {});

  return (
    <View style={perfilStyles.cardContainer}>
      <View>
        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <TouchableOpacity
            style={perfilStyles.perfilShadow}
            onPress={navigateToEditProfile}
          >
            <Image
              source={{
                uri: user.avatar || imatgeRecurs("perfil_default"),
              }}
              style={perfilStyles.perfilFoto}
            />
            <View style={perfilStyles.editarPerfil}>
              <Image
                source={{ uri: imatgeRecurs("perfil_editar") }}
                style={{
                  width: 15,
                  height: 15,
                }}
              />
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: "column" }}>
          <Text style={perfilStyles.nomPerfil}>
            {user.nom || user.username}
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              gap: 10,
            }}
          >
            <Text style={perfilStyles.perfilFont}>{user.cognoms}</Text>

            {projecte.tipus_login !== "Senzill" && (
              <Text style={perfilStyles.perfilFont}>{user.email}</Text>
            )}
          </View>
        </View>
        <View
          style={{
            flexDirection: "column",
            width: "90%",
            paddingVertical: 20,
          }}
        >
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <LinearGradient
              colors={[constants.colorLinearGx, constants.colorLinearGy]}
              style={perfilStyles.guadsContainer}
            >
              <TouchableOpacity
                onPress={() => navigation.navigate("Ranking")}
                style={{
                  ...perfilStyles.containerBtnOrange,
                }}
              >
                <Image
                  source={{ uri: imatgeRecurs("perfil_punts") }}
                  width={28}
                  height={28}
                />
                <Text style={perfilStyles.textBtn}>
                  {myPoints} {textRecurs("general_puntos")}
                </Text>
              </TouchableOpacity>

              <View style={{ flexDirection: "row" }}>
                <Pressable
                  onPress={() => navigation.navigate("Valoracions", {})}
                >
                  <Image
                    source={{ uri: imatgeRecurs("perfil_valoracions") }}
                    style={{ width: 32, height: 32, marginLeft: 3 }}
                  />
                </Pressable>
                <Pressable onPress={() => setInfoModalVisible(true)}>
                  <Image
                    source={{ uri: imatgeRecurs("boton_info") }}
                    style={{ width: 32, height: 32, marginLeft: 10 }}
                  />
                </Pressable>
                <Pressable onPress={onShare}>
                  <Image
                    source={{ uri: imatgeRecurs("perfil_compartir") }}
                    style={{ width: 32, height: 32, marginLeft: 10 }}
                  />
                </Pressable>
              </View>
            </LinearGradient>
          </View>
        </View>
      </View>
      {InfoModalPerfil(infoModalVisible, setInfoModalVisible)}
    </View>
  );
};

const OverlayPerfil = () => {
  const { __, textRecurs, projecte } = useGlobal();

  return (
    <View style={perfilStyles.overlayContainer}>
      <View style={perfilStyles.overlaySelector} />

      <ImageBackground
        source={{ uri: projecte.fons?.url }}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <View style={perfilStyles.overlayHeader}>
          <Text
            style={{
              fontFamily: "Poppins",
              fontWeight: "700",
              color: "#00491F",
              fontSize: 13,
              marginBottom: 4,
            }}
          ></Text>
        </View>

        <FlatList
          data={projecte.itineraris}
          renderItem={({ item }) => {
            return (
              <View>
                <ItinerariPerfil {...item} />
              </View>
            );
          }}
          style={{ flexGrow: 1 }}
          keyExtractor={(item) => item.id}
        />
      </ImageBackground>
    </View>
  );
};

const ItinerariPerfil = (itinerari: any) => {
  const { __, textRecurs } = useGlobal();

  const navigation = useNavigation<any>();
  const { user } = useAuth();

  const myItinerari = user.progres.itineraris[itinerari.id] ?? {
    pois: {},
  };

  const navigateToPerfilItinerari = () => {
    navigation.navigate("PerfilItinerari", {
      itinerari,
    });
  };

  return (
    <Pressable
      style={perfilStyles.pobleContainer}
      onPress={navigateToPerfilItinerari}
    >
      <View style={{ flexDirection: "row", gap: 10 }}>
        <View>
          <Image
            source={{ uri: itinerari.imatge?.url }}
            style={{ height: 40, width: 40 }}
          />
        </View>
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "flex-start",
            alignItems: "flex-start",
            flexBasis: "60%",
          }}
        >
          <Text
            style={{
              fontFamily: "GeorgiaBold",
              color: "#3D3935",
              fontSize: 16,
            }}
          >
            {__(itinerari, "nom")}
          </Text>
          {__(itinerari, "subtitol") && (
            <Text
              style={{
                fontFamily: "Roboto",
                color: "#6D6D6D",
                fontSize: 13,
              }}
            >
              {__(itinerari, "subtitol")}
            </Text>
          )}
        </View>
      </View>
      <View style={{ flexDirection: "column" }}>
        <Text
          style={{
            fontFamily: "GeorgiaBold",
            color: "#3D3935",
            fontSize: 16,
            textAlign: "right",
            flex: 1,
          }}
        >
          {puntsPerItinerari(myItinerari)}
        </Text>
        <Text
          style={{
            fontFamily: "Roboto",
            fontWeight: "700",
            color: "#6D6D6D",
            fontSize: 13,
          }}
        >
          {textRecurs("general_puntos")}
        </Text>
      </View>
    </Pressable>
  );
};

export default function Perfil() {
  const { textRecurs } = useGlobal();

  return (
    <AuthAppShell>
      <HeaderComponent title={textRecurs("menu_perfil")} showTutorial />
      <View style={{ flexGrow: 3 }}>
        <InfoPerfil />
        {/*<DraggableBox initialTranslateY={-300} initialScrollTo={-300}>*/}
        <OverlayPerfil />
        {/*</DraggableBox>*/}
      </View>
    </AuthAppShell>
  );
}
