import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { useGlobal } from "@/Components/Context/globalContext";

interface TaulaAtributsProps {
  itinerari: any;
}

const TaulaAtributs = ({ itinerari }: TaulaAtributsProps) => {
  const { imatgeRecurs, textRecurs, __ } = useGlobal();

  return (
    <View style={stylesTable.container}>
      {itinerari.atributs.map((e: any, index: number) => (
        <View
          style={[
            stylesTable.row,
            index % 2 === 0 ? stylesTable.rowEven : stylesTable.rowOdd,
          ]}
          key={e.id}
        >
          <Image
            source={{
              uri: imatgeRecurs("itinerario_" + e.tipus.toLowerCase()),
            }}
            style={stylesTable.image}
          />
          <Text style={stylesTable.label}>
            {textRecurs("itinerario_" + e.tipus.toLowerCase())}
          </Text>
          <Text style={stylesTable.value}>{__(e, "valor")}</Text>
        </View>
      ))}
    </View>
  );
};

const stylesTable = StyleSheet.create({
  container: {
    borderRadius: 10,
    backgroundColor: "white",
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginBottom: 20,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
  },
  rowEven: {},
  rowOdd: {
    backgroundColor: "rgba(250, 250, 250, 1)",
  },
  label: {
    fontSize: 16,
    fontWeight: "bold",
  },
  image: {
    width: 16,
    height: 16,
    marginRight: 8, // Add margin to separate image from the label
  },
  value: {
    fontSize: 16,
    marginLeft: "auto",
  },
});

export default TaulaAtributs;
