import React, { useRef, useState } from "react";
import { Dimensions, Image, Pressable, ScrollView, View } from "react-native";
import style from "../Rutes/CarouselImageStyle";
import constants from "@/config/constants";
import { useGlobal } from "@/Components/Context/globalContext";

export default function CarouselImage(itinerari: any) {
  const { projecte } = useGlobal();

  const [activeButtonScroll, setActiveButtonScroll] = useState(0);
  const scrollViewRef = useRef(null);

  const handleScroll = (event: any) => {
    const contentOffset = event.nativeEvent.contentOffset.x;
    const viewSize = event.nativeEvent.layoutMeasurement.width;
    const pageIndex = Math.floor(contentOffset / viewSize);
    setActiveButtonScroll(pageIndex);
  };

  const scrollToPage = (index: number) => {
    const screenWidth = Dimensions.get("window").width;
    // @ts-ignore
    scrollViewRef.current.scrollTo({
      x: index * screenWidth,
      animated: true,
    });
  };

  const renderPaginationButtons = () => {
    return Array.from({ length: itinerari.galeria.length }, (_, index) => {
      const isHighlighted = index === activeButtonScroll;
      return (
        <Pressable
          key={index}
          style={[
            isHighlighted ? style.buttonScrollHighlighted : style.buttonScroll,
            {
              backgroundColor: projecte.color_secundari,
            },
          ]}
          onPress={() => scrollToPage(index)}
        />
      );
    });
  };

  const renderCarousel = () => {
    return itinerari.galeria.map((item: any, index: number) => {
      return (
        <View style={style.logoScroll} key={index}>
          <View style={style.cardContainer}>
            <View style={style.scrollView}>
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Image
                  source={{ uri: item.url }}
                  style={{
                    width: constants.windowWidth,
                    height: constants.windowWidth,
                  }}
                />
              </View>
            </View>
          </View>
        </View>
      );
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={style.carouselContainer}>
        <ScrollView
          ref={scrollViewRef}
          pagingEnabled
          horizontal
          onScroll={handleScroll}
          showsHorizontalScrollIndicator={false}
          style={style.scroll}
        >
          {renderCarousel()}
        </ScrollView>
      </View>

      <View style={style.containerPagination}>{renderPaginationButtons()}</View>
    </View>
  );
}
