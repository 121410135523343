import React, { useEffect, useState } from "react";
import {
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import constants from "@/config/constants";
import { ScrollView } from "react-native-gesture-handler";
import Alert from "@/Components/Components/Alert";
import { useNavigation } from "@react-navigation/native";
import { useGlobal } from "../../Context/globalContext";
import { useAuth } from "../../Context/authContext";
import * as ImagePicker from "expo-image-picker";
import HeaderComponent from "@/Components/Components/HeaderComponent";
import AuthAppShell from "@/Components/Components/AuthAppShell";
import Constants from "expo-constants";
import { API_URL } from "@/config/config";

export default function EditarPerfil(RouterData: any) {
  const { textRecurs, imatgeRecurs, projecte } = useGlobal();

  const { user, savePerfil, token } = useAuth();
  const navigation = useNavigation();

  const [userForm, setUserForm] = useState({
    nom: user.nom ?? "",
    cognoms: user.cognoms ?? "",
    telefon: user.telefon ?? "",
    data_naixement: user.data_naixement ?? "",
    provincia: user.provincia ?? "",
    municipi: user.municipi ?? "",
    avatarUrl: user.avatar || imatgeRecurs("perfil_default"),
    avatar: undefined,
  });

  useEffect(() => {
    setUserForm({
      nom: user.nom ?? "",
      cognoms: user.cognoms ?? "",
      telefon: user.telefon ?? "",
      data_naixement: user.data_naixement ?? "",
      provincia: user.provincia ?? "",
      municipi: user.municipi ?? "",
      avatarUrl: user.avatar || imatgeRecurs("perfil_default"),
      avatar: undefined,
    });
  }, [user]);

  const handleFieldChange = (field: string, value: string) => {
    setUserForm({ ...userForm, [field]: value });
  };

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0,
    });

    if (!result.canceled) {
      const image = await uploadImageAsync(result.assets[0].uri);

      if (image) {
        setUserForm({
          ...userForm,
          avatarUrl: API_URL + image.url,
          avatar: image.id,
        });
      }
    }
  };

  const uploadImageAsync = async (uri: string) => {
    const formData = new FormData();
    const type = uri.substring(uri.lastIndexOf(".") + 1);

    formData.append("files", {
      uri,
      name: `${Constants.expoConfig?.slug} ${user.id}`,
      type: `image/${type}`,
    } as any);

    try {
      const uploadResponse = await fetch(`${API_URL}/api/upload`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (!uploadResponse.ok) {
        return Alert(
          textRecurs("general_alerta_titulo"),
          uploadResponse.statusText,
          [{ text: "OK" }],
        );
      }

      const data = await uploadResponse.json();

      return data[0];
    } catch (error: any) {
      console.log(error);
    }
  };

  const handlePress = async () => {
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d{2}$/;

    if (!userForm.data_naixement || !regex.test(userForm.data_naixement)) {
      delete userForm.data_naixement;
    }

    console.log(userForm);
    await savePerfil(userForm);

    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  };

  return (
    <AuthAppShell>
      <HeaderComponent
        title={textRecurs("perfil_editar")}
        showBack={true}
        showSettings={false}
      />

      <View>
        <TouchableOpacity style={style.perfilShadow} onPress={pickImage}>
          <Image
            source={{
              uri: userForm.avatarUrl,
            }}
            style={style.perfilFoto}
          />
          <View style={style.editarPerfil}>
            <Image
              source={{ uri: imatgeRecurs("perfil_editar") }}
              style={{
                width: 15,
                height: 15,
              }}
            />
          </View>
        </TouchableOpacity>
      </View>
      <ScrollView style={{ flex: 1, padding: 40 }}>
        <View>
          <Text style={{ ...style.label, color: projecte.color_secundari }}>
            {textRecurs("acceso_nombre")}
          </Text>
          <TextInput
            onChangeText={(value: string) => handleFieldChange("nom", value)}
            value={userForm.nom}
            placeholder={textRecurs("acceso_nombre")}
            style={style.input}
            placeholderTextColor={"rgba(0, 73, 31, 0.23)"}
          />
        </View>
        <View>
          <Text style={{ ...style.label, color: projecte.color_secundari }}>
            {textRecurs("perfil_apellidos")}
          </Text>
          <TextInput
            onChangeText={(value: string) =>
              handleFieldChange("cognoms", value)
            }
            value={userForm.cognoms}
            placeholder={textRecurs("perfil_apellidos")}
            keyboardType="ascii-capable"
            style={style.input}
            placeholderTextColor={"rgba(0, 73, 31, 0.23)"}
          />
        </View>
        <View>
          <Text style={{ ...style.label, color: projecte.color_secundari }}>
            {textRecurs("perfil_telefono")}
          </Text>
          <TextInput
            onChangeText={(value: string) =>
              handleFieldChange("telefon", value)
            }
            value={userForm.telefon}
            placeholder="666 666 666"
            keyboardType="numeric"
            style={style.input}
            placeholderTextColor={"rgba(0, 73, 31, 0.23)"}
          />
        </View>
        <View>
          <Text style={{ ...style.label, color: projecte.color_secundari }}>
            {textRecurs("perfil_nacimiento")}
          </Text>
          <TextInput
            onChangeText={(value: string) =>
              handleFieldChange("data_naixement", value)
            }
            value={userForm.data_naixement}
            placeholder="01/01/2000"
            keyboardType="numbers-and-punctuation"
            style={style.input}
            placeholderTextColor={"rgba(0, 73, 31, 0.23)"}
          />
        </View>
        <View>
          <Text style={{ ...style.label, color: projecte.color_secundari }}>
            {textRecurs("perfil_provincia")}
          </Text>
          <TextInput
            onChangeText={(value: string) =>
              handleFieldChange("provincia", value)
            }
            value={userForm.provincia}
            placeholder={textRecurs("perfil_provincia")}
            keyboardType="ascii-capable"
            style={style.input}
            placeholderTextColor={"rgba(0, 73, 31, 0.23)"}
          />
        </View>
        <View>
          <Text style={{ ...style.label, color: projecte.color_secundari }}>
            {textRecurs("perfil_municipio")}
          </Text>
          <TextInput
            onChangeText={(value: string) =>
              handleFieldChange("municipi", value)
            }
            value={userForm.municipi}
            placeholder={textRecurs("perfil_municipio")}
            keyboardType="ascii-capable"
            style={style.input}
            placeholderTextColor={"rgba(0, 73, 31, 0.23)"}
          />
        </View>

        <TouchableOpacity
          style={{
            ...style.btnLogin,
            backgroundColor: projecte.color_principal,
          }}
          onPress={handlePress}
        >
          <Text style={style.btnLoginText}>{textRecurs("perfil_guardar")}</Text>
        </TouchableOpacity>
      </ScrollView>
    </AuthAppShell>
  );
}

const style = StyleSheet.create({
  //TODO: Add FontFamilys!
  //TODO: labelStyles green not working!
  //TODO: Keyboard style!
  //TODO: showPasswordImageStyles={{}}
  //TODO: on click: change borderColor
  //TODO: Linkings margins

  mainContainer: {
    flex: 0,
    paddingTop: 70,
    paddingHorizontal: 24,
    backgroundColor: constants.colorBgLogin,
  },
  perfilShadow: {
    flex: 1,
    marginHorizontal: 40,
    zIndex: 1,
  },
  perfilFoto: {
    borderRadius: 100,
    borderWidth: 2,
    borderColor: "white",
    width: 96,
    height: 96,
    position: "absolute",
    top: -50,
    right: 0,
  },
  editarPerfil: {
    backgroundColor: "rgba(255, 255 ,255, 0.8)",
    borderRadius: 100,
    alignItems: "center",
    borderWidth: 1,
    padding: 5,
    borderColor: "white",
    position: "absolute",
    top: 0,
    right: 0,
  },

  mainTitle: {
    fontFamily: "Poppins",
    flex: 0,
    fontSize: 32,
    fontWeight: "700",
    color: constants.colorGreenDark,
    lineHeight: 38,
    letterSpacing: 0.64,
    // backgroundColor:'blue'
  },
  label: {
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: 13,
    lineHeight: 12,
    letterSpacing: 0.64,
    textAlign: "left",
    paddingLeft: 23,
    paddingTop: 12,
  },
  input: {
    flex: 0,
    // width: 327,
    // height: 47,
    marginTop: 1,
    // marginHorizontal:24,
    paddingLeft: 24,
    paddingTop: 5,
    borderWidth: 1,
    borderRadius: 32,
    borderColor: constants.colorGreenDarkBtn,
    backgroundColor: constants.colorWhite,
    fontSize: 16,
    color: constants.colorGreenDarkBtn,
    //height: 40,
    //margin: 12,
    padding: 10,
  },
  btnLogin: {
    // width: 327,
    height: 56,
    marginTop: 24,
    // marginHorizontal:24,
    marginBottom: 40,
    // paddingHorizontal:10,
    paddingVertical: 12,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: constants.colorGreenDarkBtn,
    borderRadius: 32,
    fontSize: 16,
  },
  btnLoginText: {
    color: constants.colorWhite,
    letterSpacing: 0.8,
    fontSize: 16,
    fontWeight: "700",
    textAlign: "center",
    justifyContent: "center",
  },
});
