import { StyleSheet } from "react-native";

const style = StyleSheet.create({
  quizzAnswerBackground: {
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: "#F9F7E8",
    border: "0.5vh solid #00491F",
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    flex: 1,
  },

  answersContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 12,
    alignItems: "center",
    justifyContent: "center",
    flex: 3,
    width: "100%",
  },

  answerResponse: {
    height: "80%",
    width: "80%",
    position: "absolute",
  },

  answerButton: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 24,
    backgroundColor: "#F9F7E8",
    width: "45%",
    height: "45%",
  },

  answerButtonCorrect: {
    backgroundColor: "rgba(124, 196, 0, 0.2)",
    borderColor: "#7CC400",
    border: "0.5vh solid #00491F",
    borderRadius: 20,
    borderWidth: 4,
  },

  answerButtonWrong: {
    backgroundColor: "rgba(238, 103, 89, 0.2)",
    borderColor: "#EE6759",
    border: "0.5vh solid #EE6759",
    borderRadius: 20,
    borderWidth: 4,
  },

  botoContinuar: {
    justifyContent: "center",
    width: "90%",
    height: "20%",
    backgroundColor: "#00491F",
    borderRadius: 8,
    marginTop: 10,
    flex: 1,
  },
  textContinuar: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    flexDirection: "column",
    fontWeight: "400",
    fontSize: 20,
    color: "#FFFFFF",
    textAlign: "center",
  },
  questionImage: {
    width: "100%",
    height: "100%",
    borderRadius: 20,
  },
});

export default style;
