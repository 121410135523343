import { useState, useEffect } from "react";
import { Platform, useWindowDimensions } from "react-native";

const useIsWebAndNotMobile = () => {
  const { width } = useWindowDimensions();
  const [isNonMobileWeb, setIsNonMobileWeb] = useState(false);

  useEffect(() => {
    const checkIfNonMobileWeb = () => {
      return Platform.OS === "web" && width > 768; // Adjust this threshold as necessary
    };

    setIsNonMobileWeb(checkIfNonMobileWeb());
  }, [width]);

  return isNonMobileWeb;
};

export default useIsWebAndNotMobile;
