import React, { useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { useGlobal } from "@/Components/Context/globalContext";
import { useAuth } from "@/Components/Context/authContext";
import { useNavigation } from "@react-navigation/native";
import constants from "@/config/constants";
import AuthAppShell from "@/Components/Components/AuthAppShell";
import ModalNouComentari from "@/Components/Screens/Modals/ModalNouComentari";
import { Rating } from "react-native-ratings";
import { FloatingLabelInput } from "react-native-floating-label-input";
import axios from "axios";
import { API_URL, PROJECTE_ID } from "@/config/config";
import { useQuery } from "@tanstack/react-query";
import useAsyncStorage from "@/Components/Hooks/useAsyncStorage";
import HeaderComponent from "@/Components/Components/HeaderComponent";

export default function Valoracions(RouterData: any) {
  const { textRecurs, imatgeRecurs, projecte } = useGlobal();
  const { token, user } = useAuth();
  const navigation = useNavigation<any>();
  const [haVotat, setHaVotat] = useAsyncStorage("haVotat", false);

  const [modalNouComentari, setModalNouComentari] = useState(false);
  const [comentari, setComentari] = useState<string>("");
  const [valoracio, setValoracio] = useState<number>(5);

  const fetchData = async () => {
    const result = await axios.get(
      API_URL +
        `/api/users-permissions/users/valoracions?projecte=${PROJECTE_ID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const myValoracio = result.data.find((item: any) => item.id === user.id);

    setValoracio(myValoracio?.valoracio ?? 5);
    setComentari(myValoracio?.comentari);
    setHaVotat(!!myValoracio);

    return result.data;
  };

  const {
    data: valoracions,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["valoracions"],
    queryFn: fetchData,
  });

  const handleEnviaComentari = async () => {
    await axios.post(
      `${API_URL}/api/users-permissions/users/valoracions?projecte=${PROJECTE_ID}`,
      {
        comentari,
        valoracio,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );

    setModalNouComentari(true);
    setComentari("");
    setHaVotat(true);

    refetch();
  };

  // Function to render stars based on rating
  const renderStars = (rating) => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      const starName = i <= rating ? "star" : "star-o";
      stars.push(
        <FontAwesome key={i} name={starName} size={20} color="#FFD700" />,
      );
    }
    return stars;
  };

  const handleClose = () => {
    if (RouterData.route.params.esFinal) {
      return navigation.replace("PuzzlePerfil", {
        ...RouterData.route.params,
      });
    }

    return navigation.goBack();
  };

  return (
    <AuthAppShell>
      <HeaderComponent
        title={textRecurs("valoracion_valoraciones")}
        showBack={true}
        showSettings={false}
        customBack={handleClose}
      />

      <View style={{ ...styles.container }}>
        <View
          style={{
            backgroundColor: "white",
            borderRadius: 16,
          }}
        >
          <Text style={styles.header}>{textRecurs("valoracion_titulo")}</Text>

          <Rating
            readonly={haVotat}
            startingValue={valoracio}
            onFinishRating={setValoracio}
            style={{
              paddingVertical: 20,
            }}
          />

          <View
            style={{
              marginHorizontal: 20,
            }}
          >
            <FloatingLabelInput
              label={""}
              staticLabel
              value={comentari}
              onChangeText={(value) => setComentari(value)}
              editable={!haVotat}
              labelStyles={{
                marginTop: 6,
              }}
            />
          </View>

          <TouchableOpacity
            style={{
              ...styles.button,
              backgroundColor: projecte.color_principal,
              opacity: haVotat ? 0.5 : 1,
            }}
            disabled={haVotat}
            onPress={handleEnviaComentari}
          >
            <Text style={styles.buttonText}>
              {textRecurs("valoracion_anade_comentario")}
            </Text>
          </TouchableOpacity>
        </View>

        <View
          style={{
            flex: 1,
            padding: 20,
            backgroundColor: "#f5f7fa",
            borderRadius: 16,
            marginVertical: 20,
          }}
        >
          <Text
            style={{
              fontSize: 18,
              borderBottomWidth: 2,
              borderColor: projecte.color_principal,
              marginBottom: 20,
              fontFamily: "Roboto",
            }}
          >
            {textRecurs("valoracion_valoraciones")}
          </Text>
          <ScrollView style={styles.reviewsContainer}>
            {valoracions?.map((review) => (
              <View key={review.id} style={styles.review}>
                <Text style={{ fontWeight: "bold" }}>{review.nom}</Text>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 16,
                  }}
                >
                  <View style={styles.stars}>
                    {renderStars(review.valoracio)}
                  </View>
                  <Text style={styles.reviewDate}>{review.createdAt}</Text>
                </View>

                <Text style={styles.reviewText}>{review.comentari}</Text>
              </View>
            ))}
          </ScrollView>
        </View>
      </View>

      {ModalNouComentari(modalNouComentari, setModalNouComentari)}
    </AuthAppShell>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    flex: 1,
  },
  mainTitle: {
    fontFamily: "Poppins",
    flex: 0,
    fontSize: 32,
    fontWeight: "700",
    lineHeight: 38,
    letterSpacing: 0.64,
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
  },
  button: {
    borderRadius: 8,
    justifyContent: "space-evenly",
    borderColor: constants.colorGreenDarkBtn,
    padding: 10,
    margin: 20,
  },
  buttonText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    color: constants.colorWhite,
    letterSpacing: 0.4,
    opacity: 1,
  },
  reviewsContainer: {
    paddingVertical: 10,
  },
  review: {
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
    paddingBottom: 10,
    marginBottom: 10,
  },
  reviewDate: {
    fontSize: 12,
    color: "#999",
  },
  stars: {
    flexDirection: "row",
  },
  reviewText: {
    fontSize: 14,
    marginTop: 5,
  },
});
