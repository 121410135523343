import { StyleSheet } from "react-native";
import constants from "@/config/constants";

/* Styles for the component HomeScreen */

const style = StyleSheet.create({
  //##Carousel Styles##

  carouselContainer: {
    flex: 10,
    backgroundColor: "white",
    marginVertical: 70,
  },
  scroll: {
    flex: 1,
    //margin:24,
  },
  logoScroll: {
    width: constants.windowWidth,
    resizeMode: "contain",
    alignItems: "center",
  },
  cardContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 12,
    // width: "85%",
    // height: "100%",
    // backgroundColor: constants.colorCardBg,
    // marginTop: 32,
    // gap:24,
  },
  scrollView: {
    flex: 1,
    alignItems: "center",
    //justifyContent: "center",
  },
  containerPagination: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 20,
    justifyContent: "center",
  },
  buttonScroll: {
    height: 20,
    width: 20,
    fontSize: 25,
    borderRadius: 20 / 2,
    backgroundColor: "rgba(124, 196, 0, 1)",
    opacity: 0.5,
    margin: 5,
  },
  buttonScrollHighlighted: {
    width: 20,
    height: 20,
    fontSize: 25,
    borderRadius: 20 / 2,
    borderWidth: 2,
    borderColor: constants.colorWhite,
    backgroundColor: constants.colorGreenClear,
    margin: 5,
  },
});

export default style;
