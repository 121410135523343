import React from "react";
import { ImageBackground, StyleSheet, View } from "react-native";
import { Image } from "expo-image";
import globalstyle from "@/Styles/Globals/globals";
import ButtonLanguage from "@/Components/Components/Main/ButtonLanguage";
import { useGlobal } from "@/Components/Context/globalContext";

export default function IdiomaScreen(RouterData: any) {
  const { guardarIdioma, imatgeRecurs, projecte } = useGlobal();

  const seleccionarIdioma = async (idioma: string) => {
    await guardarIdioma(idioma);

    RouterData.navigation.navigate("OnBoarding", {
      ...RouterData.route.params,
    });
  };

  return (
    <View style={style.containerBg}>
      <ImageBackground
        source={{ uri: imatgeRecurs("idioma_fons") }}
        style={globalstyle.backgroundImatges}
      >
        <View style={style.logoContainer}>
          {imatgeRecurs("idioma_logo") && (
            <Image
              style={style.logo}
              source={{ uri: imatgeRecurs("idioma_logo") }}
            />
          )}
        </View>

        <View style={style.containerButton}>
          {projecte.idiomes.map((idioma: string) => (
            <ButtonLanguage
              key={idioma}
              title={idioma}
              onPress={() => seleccionarIdioma(idioma)}
            />
          ))}
        </View>
      </ImageBackground>
    </View>
  );
}

const style = StyleSheet.create({
  logoContainer: {
    flex: 6,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  logo: {
    contentFit: "contain",
    width: "50%",
    height: "100%",
  },
  containerBg: {
    flex: 1,
    height: "100%",
    width: "100%",
    position: "relative",
  },
  containerButton: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center",
  },
});
