import React, { useState } from "react";
import { View } from "react-native";
import { checkErrors } from "@/Components/Functions/MapFunctions";

export default function JocCheckIn(RouterData: any) {
  const [errors, setErrors] = useState(0);

  const navigateToReward = () => {
    RouterData.navigation.replace("Reward", {
      ...RouterData.route.params,
      punts: checkErrors(errors),
    });
  };

  navigateToReward();

  return <View></View>;
}
