import React, { useState } from "react";
import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useGlobal } from "@/Components/Context/globalContext";
import ModalBase from "@/Components/Screens/Modals/ModalBase";

const HintModal = ({
  modalVisible,
  setModalVisible,
  pistes,
  errors,
  setErrors,
}: {
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
  pistes: string[];
  errors: number;
  setErrors: (errors: number) => void;
}) => {
  const { textRecurs, imatgeRecurs, projecte } = useGlobal();
  const [hintsUsed, setHintsUsed] = useState(0);
  const [pistaActual, setPistaActual] = useState(0);
  const [pista, setPista] = useState<string>("");

  const totalHints = pistes?.length;

  const handleShowPista = () => {
    setHintsUsed(hintsUsed + 1);
    setPistaActual(pistaActual + 1);
    setErrors(errors + 1);
  };

  if (!modalVisible) {
    return;
  }

  const renderHintList = () => {
    return (
      <ScrollView>
        {pistes.map((hint, index) => {
          const isUnlocked = index < hintsUsed;

          return (
            <View key={index} style={styles.boxHintList}>
              <Text style={styles.textHintList}>
                {textRecurs("poi_pista")} {index + 1}
              </Text>

              {isUnlocked ? (
                <TouchableOpacity
                  style={{
                    ...styles.buttonHint,
                    backgroundColor: projecte.color_principal,
                  }}
                  onPress={() => setPista(hint)}
                >
                  <Text style={styles.textStyle}>
                    {textRecurs("general_ver")}
                  </Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  disabled={index > pistaActual}
                  style={[
                    [styles.buttonHint, styles.buttonHintDisabled],
                    index > pistaActual && { opacity: 0.5 },
                  ]}
                  onPress={handleShowPista}
                >
                  <Text style={styles.textStyle}>
                    {textRecurs("poi_desbloquear_pista")}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          );
        })}
      </ScrollView>
    );
  };

  const renderHint = () => {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView style={styles.boxHint}>
          <Text style={styles.textHint}>{pista}</Text>
        </ScrollView>
        <Pressable
          style={[
            styles.buttonHint,
            {
              marginTop: "auto",
              width: "100%",
              backgroundColor: projecte.color_principal,
              flexGrow: 0.2,
            },
          ]}
          onPress={() => setPista("")}
        >
          <Text style={styles.textStyle}>{textRecurs("general_cerrar")}</Text>
        </Pressable>
      </View>
    );
  };

  return (
    <ModalBase
      setModalVisible={setModalVisible}
      title={textRecurs("poi_pista")}
    >
      <Text style={styles.modalSecondText}>
        {textRecurs("perfil_has_utilizado", [hintsUsed, totalHints])}
      </Text>
      {pista ? renderHint() : renderHintList()}
    </ModalBase>
  );
};

const styles = StyleSheet.create({
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalSecondText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
  },
  boxHintList: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 10,
    alignItems: "center",
    alignSelf: "center",
    paddingVertical: 5,
    backgroundColor: "#E2E8D6",
    borderColor: "#E2E8D6",
    borderRadius: 12,
    width: "100%",
    gap: 10,
    marginVertical: 10,
  },
  boxHint: {
    paddingHorizontal: 15,
    paddingVertical: 5,
    backgroundColor: "#E2E8D6",
    borderColor: "#E2E8D6",
    borderRadius: 12,
    width: "100%",
    gap: 10,
    marginBottom: 10,
    flex: 1,
  },
  textHint: {
    fontFamily: "Georgia",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 24,
    gap: 10,
    justifyContent: "center",
  },
  textHintList: {
    fontFamily: "Georgia",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 24,
    gap: 10,
    textTransform: "capitalize",
    flex: 1,
  },
  buttonHint: {
    borderRadius: 12,
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    marginLeft: "auto",
    flexGrow: 1,
    flex: 1,
  },
  buttonHintDisabled: {
    backgroundColor: "gray",
  },
});

export default HintModal;
