import React from "react";
import {
  ActivityIndicator,
  Image,
  Pressable,
  ScrollView,
  Text,
  View,
} from "react-native";
import style from "./PuzzleGranCardStyle";
import api_urls from "@/config/api_urls";
import { PicturePuzzle, PuzzlePieces } from "react-native-picture-puzzle";
import { useGlobal } from "../../Context/globalContext";
import { useAuth } from "../../Context/authContext";
import Alert from "@/Components/Components/Alert";

export default function PuzzleGranCard() {
  const { __, textRecurs, projecte } = useGlobal();

  const [hidden, setHidden] = React.useState<number | null>(4); // La peca que desapareix
  const [pieces, setPieces] = React.useState<PuzzlePieces>([
    8, 7, 6, 5, 4, 3, 2, 1, 0,
  ]);

  const { firebaseData, saveFirebaseProfile } = useAuth();

  // firebaseData.puzzleCompletat = true;

  const itinerarisTotals = projecte.itineraris.length;
  const itinerarisPassats = projecte.itineraris.filter(
    (itinerari: any) =>
      firebaseData.itineraris[itinerari.id]?.pois?.length ===
      itinerari.pois.length,
  ).length;

  const setPuzzleCompleted = async () => {
    await saveFirebaseProfile({
      puzzleCompletat: true,
    });

    Alert(
      textRecurs("puzzle_guanyar_titol"),
      textRecurs("puzzle_guanyar_text"),
    );
  };

  const renderLoading = React.useCallback(
    (): JSX.Element => <ActivityIndicator />,
    [],
  );

  const onChangePiezaPuzzle = React.useCallback(
    (nextPieces: PuzzlePieces, nextHidden: number | null): void => {
      setPieces(nextPieces);
      setHidden(nextHidden);
      if (nextPieces.every((e, i) => e === i)) {
        setPuzzleCompleted();
      }
    },
    [],
  );

  const source =
    api_urls.api_url +
    projecte.multimedies.find((e: any) => e.tipus == "puzzle_final")[
      "multimedia"
    ].url;

  const imatge =
    api_urls.api_url +
    projecte.recursos.find((e: any) => e.tipus == "puzzle_bloquejat")[
      "multimedia"
    ].url;

  const mostraMissatgePendent = () => {
    Alert("", textRecurs("puzzle_click_no_resolt"));
  };

  const textHeader1 = () => {
    if (firebaseData.puzzleCompletat) {
      return textRecurs("puzzle_guanyar_text");
    }

    return itinerarisTotals === itinerarisPassats
      ? ""
      : textRecurs("puzzle_subheader_1");
  };

  const textHeader2 = () => {
    if (firebaseData.puzzleCompletat) {
      return "";
    }

    return itinerarisTotals === itinerarisPassats
      ? textRecurs("puzzle_incomplet")
      : textRecurs("puzzle_subheader_2");
  };

  return (
    <View style={{ flex: 6 }}>
      <View style={style.carouselContainer}>
        <ScrollView
          pagingEnabled
          horizontal
          showsHorizontalScrollIndicator={false}
          style={style.scroll}
        >
          <View style={style.logoScroll}>
            <View style={style.cardContainer}>
              <View style={style.scrollView}>
                <View style={{ flex: 1 }}>
                  <View
                    style={{
                      flex: 3,
                      flexDirection: "row",
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontFamily: "Georgia",
                          fontWeight: "700",
                          fontSize: 24,
                          lineHeight: 24,
                          color: "rgba(72, 72, 72, 1)",
                          marginBottom: 20,
                        }}
                      >
                        {textRecurs("puzzle_header")}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: 13,
                          lineHeight: 16.8,
                          color: "rgba(72, 72, 72, 1)",
                        }}
                      >
                        {textHeader1()}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: 13,
                          lineHeight: 16.8,
                          color: "rgba(72, 72, 72, 1)",
                        }}
                      >
                        {textHeader2()}
                      </Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    flex: 3,
                    alignItems: "center",
                    borderTopRightRadius: 64,
                    borderTopLeftRadius: 64,
                    backgroundColor: "rgba(226, 232, 214, 1)",
                    gap: 80,
                    justifyContent: "center",
                    paddingHorizontal: 80,
                  }}
                >
                  {/* <View style={{ flex: 2 }}> */}
                  {itinerarisTotals === itinerarisPassats &&
                  firebaseData.puzzleCompletat ? (
                    <Image
                      source={{}}
                      style={{
                        width: 430.34,
                        height: 440.18,
                        marginVertical: 20,
                      }}
                    />
                  ) : itinerarisTotals === itinerarisPassats &&
                    !firebaseData.puzzleCompletat ? (
                    <PicturePuzzle
                      size={380}
                      pieces={pieces}
                      hidden={hidden}
                      onChange={onChangePiezaPuzzle}
                      source={{ uri: source }}
                      renderLoading={renderLoading}
                    />
                  ) : (
                    <Pressable onPress={mostraMissatgePendent}>
                      <Image
                        source={{ uri: imatge }}
                        style={{ width: 380, height: 380 }}
                      />
                    </Pressable>
                  )}
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
}
