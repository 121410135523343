import { StyleSheet } from "react-native";

/* Styles for the component Main */

const style = StyleSheet.create({
  descriptionContainer: {
    width: "95%",
    textAlign: "center",
    alignSelf: "center",
    fontFamily: "OpenSans",
    fontSize: 26,
    // fontWeight: "bold",
    // color: "white"
  },
  backgroundImatges: {
    flex: 1,
    resizeMode: "cover",
    // justifyContent: "center",
  },

  textPremi: {
    fontSize: 26,
    color: "#009499",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Leckerli One",
    // textShadow: "-0.5vh -0.5vh 0.5vh #f2eed7, 0.5vh -0.5vh 0.5vh #f2eed7, -0.5vh 0.5vh 0.5vh #f2eed7, 0.5vh 0.5vh 0.5vh #f2eed7"
  },
  punts: {
    flex: 40,
    width: "80%",
    textAlign: "center",
    alignSelf: "center",
    fontFamily: "OpenSans",
    fontSize: 28,
    fontWeight: "bold",
    // color: "#3d342a"
  },

  continueRewardBackground: {
    // flexDirection: "row",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 20,
    gap: 12,
    width: "100%",
    height: "15%",
    backgroundColor: "#F9F7E8",
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    order: "0",
    flexGrow: 0,
    position: "absolute",
    bottom: 0,
    left: 0,
  },

  botoContinuar: {
    justifyContent: "center",
    width: "90%",
    height: "80%",
    borderRadius: 8,
  },
  textContinuar: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    flexDirection: "column",
    fontWeight: "400",
    fontSize: 20,
    // lineHeight: 100,
    color: "#FFFFFF",
    textAlign: "center",
  },
  textPistes: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    textAlign: "center",
    color: "#00491F",
    marginBottom: 10,
  },

  rewardHeader: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "60%",
    paddingTop: "20%",
  },
  titolReward: {
    fontSize: 26,
    fontWeight: "bold",
    color: "#FFF",
    textAlign: "center",
    paddingTop: "2%",
  },
  textReward: {
    fontSize: 20,
    fontWeight: "400",
    color: "#FFF",
    textAlign: "center",
    paddingTop: "2%",
    width: "90%",
    alignSelf: "center",
  },
});

export default style;
