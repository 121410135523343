import React from "react";
import { Image, Modal, Pressable, StyleSheet, Text, View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { useGlobal } from "../../Context/globalContext";

const ModalPuntInici = (
  modalVisible: boolean,
  setModalVisible: any,
  itinerari: any,
) => {
  const { textRecurs, imatgeRecurs, __ } = useGlobal();

  const titol =
    __(itinerari, "popup_inici_titol") || textRecurs("poi_empezar_titulo");
  const text =
    __(itinerari, "popup_inici_text") || textRecurs("poi_empezar_texto");

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => setModalVisible(false)}
    >
      <View style={styles.centeredView}>
        <LinearGradient
          colors={["#FFFFFF", "#e5e9e5", itinerari.color_fons1]}
          style={styles.modalView}
        >
          <View style={styles.closeButton}>
            <Pressable onPress={() => setModalVisible(false)}>
              <Image
                source={{ uri: imatgeRecurs("boton_cerrar") }}
                style={{ width: 16, height: 16 }}
              />
            </Pressable>
          </View>

          <Image
            source={{
              uri: imatgeRecurs("poi_inici"),
            }}
            style={{
              width: "20%",
              height: "16%",
            }}
          />
          <View>
            <Text style={styles.modalText}>{titol}</Text>
            <Text style={styles.modalSecondText}>{text}</Text>
          </View>
          <Pressable
            style={{
              ...styles.buttonStartAdventure,
              backgroundColor: itinerari.color_fons2,
            }}
            onPress={() => setModalVisible(false)}
          >
            <Text
              style={{
                ...styles.buttonStartAdventureText,
                color: itinerari.color_text1,
              }}
            >
              {textRecurs("poi_empezar_boton")}
            </Text>
          </Pressable>
        </LinearGradient>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 22,
    gap: 24,
    isolation: "isolate",
    width: "80%",
    height: "60%",
    backgroundColor:
      "linear-gradient(180deg, #FFFFFF 100%, rgba(255, 255, 255, 1) 100%), #BBE0C4",
    border: "1px solid #BBE0C4",
    borderRadius: 24,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    // fontFamily: 'Poppins'
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 24,
    textAlign: "center",
    color: "#484848",
  },
  modalSecondText: {
    // fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    textAlign: "center",
    color: "#484848",
  },
  buttonStartAdventure: {
    backgroundColor: "#2A6438",
    borderRadius: 32,

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    gap: 10,
    width: "100%",
    height: "15%",
  },
  buttonStartAdventureText: {
    // fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    color: "#FFFFFF",
    letterSpacing: 0.4,
  },
  goBack: {
    // fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    textAlign: "center",
    color: "#484848",
    marginBottom: "10%",
  },
  closeButton: {
    position: "absolute",
    right: 23,
    top: 15.5,
  },
});

export default ModalPuntInici;
