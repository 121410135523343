import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Home from "../../Screens/Home/HomeScreen";
import Recursos from "@/Components/Screens/Recursos/RecursosScreen";
import Tours360 from "../../Screens/Tours360/Tours360";

const Stack = createStackNavigator();

export default function HomeNavigation() {
  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={{ headerShown: false }}
    >
      {/*<Stack.Screen name="Home" component={Home} />*/}
      <Stack.Screen name="Home2" component={Home} />
      <Stack.Screen name="Recursos" component={Recursos} />
      <Stack.Screen name="Tours360" component={Tours360} />
    </Stack.Navigator>
  );
}
