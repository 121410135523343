import useProjectData from "./useProjectData";
import Recursos from "@/Components/Screens/Recursos/RecursosScreen";
import Tours360 from "../Screens/Tours360/Tours360";
import HomeNavigation from "../Components/Home/HomeNavigation";
import PerfilNavigation from "../Components/Perfil/PerfilNavigation";
import { useGlobal } from "../Context/globalContext";
import MapScreen from "@/Components/Screens/Map/Map";

const findResource = ({ projecte, tipus }: any) =>
  projecte.multimedies.find((e: any) => e.tipus === tipus);

const tabs = [
  { name: "menu_home", component: HomeNavigation },
  // { name: "menu_rutes", component: RutesNavigation },
  { name: "menu_rutes", component: MapScreen },
  // { name: "menu_360", component: Tours360 },
  // { name: "menu_perfil", component: PerfilNavigation },
  { name: "menu_recursos", component: Recursos },
];

export default function useTabsConfiguration() {
  const { projecte } = useProjectData();
  const { textRecurs } = useGlobal();

  const tabsConfiguration = tabs.map(({ name, component }) => {
    const resource = findResource({ projecte, tipus: name });

    return {
      name,
      title: textRecurs(name),
      component,
      imageURL: resource?.multimedia?.url,
    };
  });

  return { tabsConfiguration };
}
