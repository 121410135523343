import React, { useEffect } from "react";
import {
  Modal,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Image } from "expo-image";
import { useGlobal } from "../../Context/globalContext";
import { getDestination, loadSound } from "@/Components/Functions/MapFunctions";
import { useNavigation } from "@react-navigation/native";
import {
  MODAL_NO_VISIBLE,
  MODAL_TORNAT_OBRIR,
  MODAL_VISIBLE,
} from "@/config/constants";
import MediaDisplay from "@/Components/Components/MediaDisplay";

const ModalEtsAprop = ({
  modalVisible,
  setModalVisible,
  itinerari,
  poi,
  setStartGame,
  setGhostStartTime,
}) => {
  const { textRecurs, __, imatgeRecurs } = useGlobal();
  const [sound, setSound] = React.useState<any>();
  const [status, setStatus] = React.useState<any>();
  const navigation = useNavigation<any>();

  useEffect(() => {
    if (!__(poi, "popup_so")?.url) return;

    if (modalVisible !== MODAL_NO_VISIBLE) {
      loadSound(__(poi, "popup_so")?.url, setSound, setStatus);
    }
  }, [modalVisible]);

  const startTimer = () => {
    setStartGame(true);

    if (modalVisible !== MODAL_TORNAT_OBRIR) {
      setGhostStartTime(Date.now());
    }
  };

  const navigateTo = () => {
    handleCloseModal();

    const destination = getDestination(poi.tipus);

    navigation.navigate(destination, {
      itinerari,
      poi,
    });
  };

  const handleJugar = () => {
    if (poi.tipus === "PuntFantasma") {
      handleCloseModal();

      return startTimer();
    }

    return navigateTo();
  };

  const handleCloseModal = () => {
    if (status?.isLoaded) {
      sound?.stopAsync();
      sound?.unloadAsync();
    }

    setModalVisible(MODAL_NO_VISIBLE);
  };

  if (modalVisible === MODAL_NO_VISIBLE) {
    return null;
  }

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible > MODAL_NO_VISIBLE}
      onRequestClose={handleCloseModal}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <View style={styles.closeButton}>
            <Pressable onPress={handleCloseModal}>
              <Image
                source={{ uri: imatgeRecurs("boton_cerrar") }}
                style={{ width: 13, height: 13, marginRight: 10 }}
              />
            </Pressable>
          </View>
          <MediaDisplay source={__(poi, "popup_multimedia").url} />
          <View
            style={{
              marginTop: 20,
              paddingHorizontal: 20,
              flexGrow: 1,
            }}
          >
            {__(poi, "popup_titol") && (
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  fontSize: 20,
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                {__(poi, "popup_titol")}
              </Text>
            )}

            <View>
              <Text style={{ ...styles.modalText }}>
                {__(poi, "popup_text")}
              </Text>
            </View>

            {modalVisible === MODAL_VISIBLE && (
              <TouchableOpacity
                style={{
                  ...styles.buttonStartAdventure,
                  backgroundColor: itinerari?.color_fons2,
                }}
                onPress={handleJugar}
              >
                <Text
                  style={{
                    ...styles.buttonStartAdventureText,
                    color: itinerari?.color_text1,
                  }}
                >
                  {textRecurs(
                    itinerari?.tipus === "Track"
                      ? "onboarding_continuar"
                      : "poi_jugar",
                  )}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 5,
  },
  modalView: {
    flexDirection: "column",
    isolation: "isolate",
    width: "90%",
    height: "90%",
    backgroundColor:
      "linear-gradient(180deg, #FFFFFF 100%, rgba(255, 255, 255, 1) 100%), #BBE0C4",
    border: "1px solid #BBE0C4",
    borderRadius: 24,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    // fontFamily: 'Poppins'
    fontStyle: "normal",
    fontSize: 16,
    textAlign: "center",
    color: "#484848",
    marginTop: 20,
  },
  modalSecondText: {
    // fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    textAlign: "center",
    color: "#484848",
    paddingVertical: 3,
  },
  buttonStartAdventure: {
    backgroundColor: "#2A6438",
    borderRadius: 32,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    width: "100%",
    marginTop: "auto",
    marginBottom: 20,
  },
  buttonStartAdventureText: {
    // fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    color: "#FFFFFF",
    letterSpacing: 0.4,
  },
  goBack: {
    // fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    textAlign: "center",
    color: "#484848",
    marginTop: 5,
    marginBottom: "5%",
  },
  closeButton: {
    position: "absolute",
    right: 23,
    top: 15.5,
    zIndex: 2,
  },
});

export default ModalEtsAprop;
