import React from "react";
import {
  Modal,
  Pressable,
  SafeAreaView,
  ScrollView,
  Text,
  View,
} from "react-native";
import constants from "@/config/constants";
import styles from "./legalTermsStyle";
import { useGlobal } from "@/Components/Context/globalContext";
import Markdown from "react-native-markdown-display";

const ModalNouComentari = (
  modalNouComentari: boolean,
  setModalNouComentari: any,
) => {
  const { textRecurs, projecte, __ } = useGlobal();

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalNouComentari}
      onRequestClose={() => setModalNouComentari(false)}
    >
      <View style={styles.modalContainer}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View>
              <Text
                style={{
                  color: constants.colorText,
                  fontWeight: "700",
                  fontSize: 24,
                  lineHeight: 28.8,
                }}
              >
                {textRecurs("valoracion_gracias_titulo")}
              </Text>
            </View>
            <View style={styles.containerScrollView}>
              <Text style={styles.scrollView}>
                {textRecurs("valoracion_gracias_texto")}
              </Text>
            </View>
            <Pressable
              style={[
                styles.btnDarkGreen,
                { backgroundColor: projecte.color_principal },
              ]}
              onPress={() => setModalNouComentari(false)}
            >
              <Text style={styles.btnDarkGreenText}>
                {textRecurs("onboarding_aceptar")}
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ModalNouComentari;
