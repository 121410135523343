import { Dimensions, StyleSheet } from "react-native";
import constants from "@/config/constants";

const { width } = Dimensions.get("window");
const { height } = Dimensions.get("window");

export const perfilStyles = StyleSheet.create({
  cardContainer: {
    alignItems: "center",
    borderRadius: 12,
    backgroundColor: constants.colorCardBg,
    marginTop: 65,
    marginHorizontal: 16,
  },
  overlaySelector: {
    backgroundColor: "#BBB",
    width: 100,
    height: 14,
    marginVertical: 10,
    borderRadius: 20,
  },
  cardMunicipioContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
    width: "85%",
    height: "35%",
    backgroundColor: constants.colorCardBg,
    marginTop: 50,
    paddingTop: 10,
    // gap:24,
  },
  cardCanjearGuadsContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
    width: "85%",
    height: "25%",
    backgroundColor: constants.colorCardBg,
    paddingTop: 10,
    // gap:24,
  },
  card: {
    alignItems: "center",
    flex: 1,
  },
  scrollView: {
    flex: 4.5,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
    width: "85%",
    height: "100%",
    // backgroundColor: constants.colorCardBg,
    // marginTop: 32,
    // gap:24,
    // paddingTop: 32,
    // paddingBottom:24,
  },
  perfilShadow: {
    flex: 1,
    zIndex: 1,
    alignItems: "center",
  },
  perfilFoto: {
    borderRadius: 100,
    borderWidth: 2,
    borderColor: "white",
    width: 96,
    height: 96,
    marginTop: -50,
  },
  editarPerfil: {
    backgroundColor: "rgba(255, 255 ,255, 0.8)",
    borderRadius: 100,
    alignItems: "center",
    borderWidth: 1,
    padding: 5,
    borderColor: "white",
    position: "absolute",
    top: 0,
    right: "28%",
  },
  nomPerfil: {
    fontFamily: "GeorgiaBold",
    fontSize: 28,
    color: "white",
    textAlign: "center",
    marginTop: 10,
  },
  titleCard: {
    //TODO: FontFamily: Georgia,
    marginTop: 3,
    marginBottom: 2,
    fontSize: 20,
    lineHeight: 24,
    color: constants.colorBlackText,
    fontWeight: "700",
    letterSpacing: 0.64,
  },
  textCard: {
    //TODO: FontFamily: Roboto,
    fontSize: 13,
    lineHeight: 14,
    color: constants.colorBlackText,
    fontWeight: "400",
    letterSpacing: 0.32,
  },
  textBtn: {
    color: constants.colorWhite,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: "700",
    textTransform: "lowercase",
  },
  smallContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "49%",
    borderRadius: 5,
  },
  marginContainer: {
    marginRight: 8,
  },
  guadsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 5,
    paddingHorizontal: 12,
    paddingVertical: 5,
  },
  containerBtnOrange: {
    color: constants.colorWhite,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingHorizontal: 10,
    borderRadius: 12,
    borderColor: "white",
    borderWidth: 1,

    paddingVertical: 5,
    gap: 10,
  },
  scroll: {
    width: constants.windowWidth,
    height: constants.windowWidth * 0.5, //60%
  },
  perfilFont: { fontFamily: "Roboto", fontSize: 14, color: "white" },
  overlayContainer: {
    backgroundColor: "#eee",
    marginTop: "auto",
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    borderWidth: 1,
    borderColor: "#CCC",
    alignItems: "center",
  },
  overlayHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 20,
  },
  pobleContainer: {
    flexDirection: "row",
    borderWidth: 1,
    backgroundColor: "#F9F7E8",
    borderRadius: 12,
    marginLeft: 32,
    marginRight: 32,
    justifyContent: "space-between",
    alignItems: "center",
    padding: 6,
    marginBottom: 10,
  },
  //Modal info
  modalContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    flex: 1,
  },
  modalCardContainer: {
    flexDirection: "column",
    borderRadius: 12,
    backgroundColor: "white",
    marginTop: 10,
    alignItems: "flex-start",
    width: "92%",
    height: "90%",
  },
  infoRowModal: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  tituloInfoModal: {
    fontFamily: "GeorgiaBold",
    fontSize: 32,
    display: "flex",
    alignItems: "center",
    letterSpacing: 0.02,
    color: "#3D3935",
  },
  nombreInfoModal: {
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: 13,
    color: "#6D6D6D",
  },
  infoInfoModal: {
    fontFamily: "Roboto",
    fontSize: 13,
    color: "#6D6D6D",
  },
  seccionInfoModal: {
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: 16,
    marginBottom: 20,
  },
  separadorBlanco: {
    borderBottomColor: "white",
    borderBottomWidth: 1,
    width: 350,
    marginVertical: 10,
  },
  creditosText: {
    fontFamily: "Poppins",
    fontSize: 13,
  },
  //Canjear Guads Screen
  separadorVerde: {
    borderBottomColor: "#7CC400",
    borderBottomWidth: 1,
    marginHorizontal: 30,
    marginVertical: 4,
  },
  guadsPorMunicipio: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 30,
  },
  guadsNombreMunicipio: {
    fontFamily: "Roboto",
    fontSize: 16,
    color: "#484848",
  },
  guadsCantidadPorMunicipio: {
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: 13,
    color: "#484848",
  },
  botonGuadsContainer: {
    borderColor: "#7CC400",
    borderWidth: 1,
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginTop: 4,
    marginRight: 3,
  },
  botonGuadsFont: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: "900",
  },
  overlayScrollContainer: {
    backgroundColor: "#F9F7E8",
    width: width,
    marginTop: 20,
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    paddingBottom: 250,
  },
  //QR Modal
  modalQRCardContainer: {
    flexDirection: "column",
    borderRadius: 12,
    backgroundColor: "#E2E8D6",
    marginTop: 10,
    alignItems: "flex-start",
  },
  tituloQRModal: {
    fontFamily: "GeorgiaBold",
    fontSize: 24,
    display: "flex",
    alignItems: "center",
    letterSpacing: 0.02,
    color: "#00491F",
  },
  infoQRModal: {
    fontFamily: "Roboto",
    fontSize: 16,
    color: "#6D6D6D",
  },
  buttonVerRecompensa: {
    fontFamily: "Roboto",
    height: 56,
    paddingVertical: 12,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 8,
    fontSize: 16,
    marginVertical: 12,
  },
});
