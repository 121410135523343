import React, { useCallback, useEffect, useState } from "react";
import {
  Linking,
  Modal,
  Pressable,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Image } from "expo-image";
import { complicesStyles } from "./RecursosStyle";
import AuthAppShell from "../../Components/AuthAppShell";
import HeaderComponent from "../../Components/HeaderComponent";
import { FlatList } from "react-native-gesture-handler";
import ModalFiltres from "../Modals/ModalFiltres";
import ModalMunicipis from "../Modals/ModalMunicipis";
import { useGlobal } from "../../Context/globalContext";
import { useAuth } from "../../Context/authContext";

export default function Recursos(RouterData: any) {
  const { textRecurs, projecte, imatgeRecurs, __ } = useGlobal();
  const { user, savePerfil } = useAuth();
  const { filtre } = RouterData.route.params ?? { filtre: "" };

  const establiments = projecte.establiments;
  const [filtreActiu, setFiltreActiu] = useState(filtre);
  const [municipiActiu, setMunicipiActiu] = useState("");
  const [municipiActiuIndex, setMunicipiActiuIndex] = useState(0);
  const [modalFiltresVisible, setModalFiltresVisible] = useState(false);
  const [modalMunicipisVisible, setModalMunicipisVisible] = useState(false);
  const [modalCompliceVisible, setModalCompliceVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const isFavorite = user.progres.favoritos.recursos.includes(selectedItem?.id);

  //
  // useEffect(() => {
  //   const isFavorite = user.progres.favoritos.recursos.includes(item.id);
  // }, []);

  const handleToggleFavorites = useCallback(
    async (itemId: any) => {
      const index = user.progres.favoritos.recursos.indexOf(itemId);

      if (index > -1) {
        user.progres.favoritos.recursos.splice(index, 1);
      } else {
        user.progres.favoritos.recursos.push(itemId);
      }

      await savePerfil({
        progres: user.progres,
      });
    },
    [savePerfil, user.progres],
  );

  const BarraFiltos = () => {
    return (
      <ScrollView showsHorizontalScrollIndicator={false}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: 20,
            gap: 10,
            zIndex: 100,
          }}
        >
          {/*<View*/}
          {/*  style={complicesStyles.categoriaFiltre}*/}
          {/*  onTouchEnd={() => setModalMunicipisVisible(true)}*/}
          {/*>*/}
          {/*  <Text style={complicesStyles.fontCategoriaFiltre}>*/}
          {/*    {textRecurs("filtros_seleccionar_itinerario")}*/}
          {/*  </Text>*/}
          {/*</View>*/}
          <View
            style={[complicesStyles.categoriaFiltre]}
            onTouchEnd={() => setModalFiltresVisible(true)}
          >
            <Text style={[complicesStyles.fontCategoriaFiltre]}>
              {textRecurs("filtros_filtrar")}
            </Text>
          </View>
        </View>
      </ScrollView>
    );
  };

  const Recurs = (item: any) => {
    return (
      <Pressable
        style={complicesStyles.cardContainer}
        onPress={() => {
          setSelectedItem(item);
          setModalCompliceVisible(true);
        }}
      >
        <Image
          source={{
            uri: item.multimedia?.url,
          }}
          placeholder={require("@/assets/Images/loading.gif")}
          style={complicesStyles.imagenComplice}
        />
        <View style={{ paddingLeft: 12, flex: 8 }}>
          <Text style={complicesStyles.nombreComplice}>{__(item, "nom")}</Text>
          {item.tipus_patrimonial && (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {imatgeRecurs("general_tipus_patrimonial") && (
                <Image
                  style={complicesStyles.cardPhoneVector}
                  source={{ uri: imatgeRecurs("general_tipus_patrimonial") }}
                />
              )}
              <Text style={complicesStyles.cardFont}>
                {textRecurs(item.tipus_patrimonial.toLowerCase())}{" "}
                {item.segle ? ` - ${item.segle}` : ""}
              </Text>
            </View>
          )}
          {item.tipus_activitat && (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {imatgeRecurs("general_tipus_activitat") && (
                <Image
                  style={complicesStyles.cardPhoneVector}
                  source={{ uri: imatgeRecurs("general_tipus_activitat") }}
                />
              )}
              <Text style={complicesStyles.cardFont}>
                {item.tipus_activitat}
              </Text>
            </View>
          )}
          {item.telefon && (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Image
                style={complicesStyles.cardPhoneVector}
                source={{ uri: imatgeRecurs("general_telefon") }}
              />
              <Text style={complicesStyles.cardFont}>{item.telefon}</Text>
            </View>
          )}
          <Text
            style={{
              ...complicesStyles.modalFont,
              flexWrap: "wrap",
            }}
            numberOfLines={1}
          >
            {item.web}
          </Text>
          {(item.direccio || item.poblacio || item.codi_postal) && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Image
                style={complicesStyles.cardLocationVector}
                source={{ uri: imatgeRecurs("general_posicio") }}
              />
              <Text style={complicesStyles.cardFont} numberOfLines={2}>
                {item.direccio}{" "}
                {item.poblacio && <Text>- {item.poblacio}</Text>}
                {item.codi_postal && <Text>({item.codi_postal})</Text>}
              </Text>
            </View>
          )}
        </View>
      </Pressable>
    );
  };

  const establimentsVisibles = establiments.filter(
    (establiment: { tipus: string; poblacio: string }) =>
      (filtreActiu === "" || establiment.tipus === filtreActiu) &&
      (!municipiActiu || establiment.poblacio === municipiActiu),
  );

  return (
    <AuthAppShell>
      <HeaderComponent title={textRecurs("general_recursos")} />
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <BarraFiltos />
      </View>
      <FlatList
        data={establimentsVisibles}
        renderItem={({ item }) => <Recurs {...item} />}
        keyExtractor={(item) => item.id}
        style={{
          height: 0,
        }}
      />
      <View style={{ height: 30 }}></View>
      {ModalFiltres(
        modalFiltresVisible,
        setModalFiltresVisible,
        filtreActiu,
        setFiltreActiu,
      )}
      {ModalMunicipis(
        modalMunicipisVisible,
        setModalMunicipisVisible,
        setMunicipiActiu,
        setMunicipiActiuIndex,
        municipiActiuIndex,
      )}
      {selectedItem && (
        <Modal transparent={true} visible={modalCompliceVisible}>
          <View style={complicesStyles.modalContainer}>
            <View style={complicesStyles.modalCardContainer}>
              <Image
                source={{ uri: selectedItem.multimedia?.url }}
                placeholder={require("@/assets/Images/loading.gif")}
                style={complicesStyles.imagenModalComplice}
              />
              <TouchableOpacity
                onPress={() => handleToggleFavorites(selectedItem.id)}
                style={{
                  padding: 12,
                  margin: 12,
                  backgroundColor: "white",
                  position: "absolute",
                  top: 8,
                  left: 2,
                  borderRadius: 30,
                }}
              >
                <Image
                  source={{
                    uri: isFavorite
                      ? imatgeRecurs("filtros_favoritos_on")
                      : imatgeRecurs("filtros_favoritos_off"),
                  }}
                  style={{
                    width: 18,
                    height: 18,
                    backgroundColor: "white",
                  }}
                />
              </TouchableOpacity>

              <Pressable
                onPress={() => setModalCompliceVisible(false)}
                style={{
                  padding: 12,
                  margin: 12,
                  backgroundColor: "white",
                  position: "absolute",
                  top: 12,
                  right: 2,
                  borderRadius: 30,
                }}
              >
                <Image
                  source={{ uri: imatgeRecurs("boton_cerrar") }}
                  style={{
                    width: 18,
                    height: 18,
                    backgroundColor: "white",
                    tintColor: "gray",
                  }}
                />
              </Pressable>

              <View
                style={{
                  paddingHorizontal: 16,
                  paddingTop: 16,
                  paddingBottom: 24,
                }}
              >
                <Text style={complicesStyles.nombreCompliceModal}>
                  {__(selectedItem, "nom")}
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingTop: 12,
                  }}
                >
                  {selectedItem.telefon && (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Image
                        source={{ uri: imatgeRecurs("general_telefon") }}
                        style={complicesStyles.cardPhoneVector}
                      />
                      <Text style={complicesStyles.modalFont}>
                        {selectedItem.telefon}
                      </Text>
                    </View>
                  )}
                  {selectedItem.tipus_patrimonial && (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      {imatgeRecurs("general_tipus_patrimonial") && (
                        <Image
                          source={{
                            uri: imatgeRecurs("general_tipus_patrimonial"),
                          }}
                          style={complicesStyles.cardPhoneVector}
                        />
                      )}
                      <Text style={complicesStyles.modalFont}>
                        {textRecurs(
                          selectedItem.tipus_patrimonial.toLowerCase(),
                        )}
                        {selectedItem.segle ? ` - ${selectedItem.segle}` : ""}
                      </Text>
                    </View>
                  )}
                  {selectedItem.tipus_activitat && (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Image
                        source={{
                          uri: imatgeRecurs("general_tipus_activitat"),
                        }}
                        style={complicesStyles.cardPhoneVector}
                      />
                      <Text style={complicesStyles.modalFont}>
                        {selectedItem.tipus_activitat}
                      </Text>
                    </View>
                  )}
                </View>
                {(selectedItem.direccio ||
                  selectedItem.poblacio ||
                  selectedItem.codi_postal) && (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      paddingTop: 8,
                    }}
                  >
                    <Image
                      style={complicesStyles.cardLocationVector}
                      source={{ uri: imatgeRecurs("general_posicio") }}
                    />
                    <View>
                      <Text
                        style={{
                          ...complicesStyles.modalFont,
                          flexWrap: "wrap",
                        }}
                        numberOfLines={2}
                      >
                        {selectedItem.direccio}{" "}
                        {selectedItem.poblacio && (
                          <Text>- {selectedItem.poblacio}</Text>
                        )}
                        {selectedItem.codi_postal && (
                          <Text>({selectedItem.codi_postal})</Text>
                        )}
                      </Text>
                    </View>
                  </View>
                )}
                <Text
                  style={{
                    ...complicesStyles.modalFont,
                    flexWrap: "wrap",
                  }}
                  numberOfLines={1}
                  onPress={() =>
                    selectedItem.web &&
                    Linking.openURL(
                      !selectedItem.web.startsWith("http")
                        ? "http://" + selectedItem.web
                        : selectedItem.web,
                    )
                  }
                >
                  {selectedItem.web}
                </Text>
                <Text
                  style={{
                    ...complicesStyles.modalFont,
                    flexWrap: "wrap",
                    marginTop: 10,
                  }}
                  numberOfLines={5}
                >
                  {__(selectedItem, "descripcio")}
                </Text>
              </View>
            </View>
          </View>
        </Modal>
      )}
    </AuthAppShell>
  );
}
