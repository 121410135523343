import React from "react";
import { Modal, StyleSheet, Text, Pressable, View, Image } from "react-native";
import { useGlobal } from "../../Context/globalContext";

const ModalFiltres = (
  modalVisible: boolean,
  setModalVisible: any,
  filtreActiu: string | null,
  setFiltreActiu: any,
) => {
  const { textRecurs, imatgeRecurs, projecte } = useGlobal();

  const navigateTo = () => {
    setModalVisible(false);
  };

  const handleFiltreActiu = (filtre: string) => {
    if (filtreActiu === filtre) {
      setFiltreActiu("");
    } else {
      setFiltreActiu(filtre);
    }
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible);
      }}
    >
      <View style={styles.modalContainer}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={{ marginVertical: 10, paddingTop: 15 }}>
              <Text
                style={{
                  fontFamily: "GeorgiaBold",
                  fontSize: 24,
                }}
              >
                {textRecurs("filtros_filtrar")}
              </Text>
            </View>
            {projecte.tipusRecursos.map((tipus) => (
              <Pressable
                key={tipus}
                style={[
                  styles.buttonFiltres,
                  filtreActiu === tipus && styles.buttonFiltresActive,
                ]}
                onPress={() => handleFiltreActiu(tipus)}
              >
                <Text style={styles.fontFiltre}>
                  {textRecurs(
                    "recursos_" + tipus.toLowerCase().replaceAll(" ", "_"),
                  )}
                </Text>
                {filtreActiu === tipus && (
                  <Image
                    style={{ height: 25, width: 25 }}
                    source={{ uri: imatgeRecurs("filtros_seleccionat") }}
                  />
                )}
              </Pressable>
            ))}
            <Pressable
              style={[
                styles.buttonVerResultados,
                { backgroundColor: projecte.color_principal },
              ]}
              onPress={() => navigateTo()}
            >
              <Text style={styles.buttonStartAdventureText}>
                {textRecurs("filtros_ver_resultados")}
              </Text>
            </Pressable>
            <Pressable onPress={() => setModalVisible(false)}>
              <Text style={styles.goBack}>
                {textRecurs("general_cancelar")}
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalView: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 25,
    gap: 8,
    isolation: "isolate",
    width: "80%",
    height: "60%",
    backgroundColor: "#E2E8D6",
    border: "1px solid #BBE0C4",
    borderRadius: 24,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  fontFiltre: {
    fontFamily: "Roboto",
    color: "#3D3935",
    fontSize: 16,
  },
  buttonFiltres: {
    backgroundColor: "#F9F7E8",
    borderColor: "#00491F",
    borderWidth: 1,
    borderRadius: 32,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingHorizontal: 20,
    paddingVertical: 10,
    gap: 10,
    width: "100%",
  },
  buttonFiltresActive: {
    backgroundColor: "#F9F7E8",
    borderColor: "#7CC400",
    borderWidth: 2,
    borderRadius: 32,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    gap: 10,
    width: "100%",
  },

  buttonVerResultados: {
    backgroundColor: "#2A6438",
    borderRadius: 6,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    gap: 10,
    marginVertical: 10,
    width: "100%",
    height: "15%",
  },
  buttonStartAdventureText: {
    // fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    color: "#FFFFFF",
    letterSpacing: 0.4,
  },
  goBack: {
    // fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    textAlign: "center",
    color: "#484848",
    marginBottom: "10%",
  },
});

export default ModalFiltres;
