import React from "react";
import { Modal, Pressable, View, Text, Image, ScrollView } from "react-native";
import { perfilStyles } from "../Perfil/PerfilStyle";
import { useGlobal } from "../../Context/globalContext";
import Markdown from "react-native-markdown-display";

const InfoModal = (modalInfoVisible: boolean, setModalInfoVisible: any) => {
  const { textRecurs, imatgeRecurs, projecte, __ } = useGlobal();

  return (
    <Modal
      transparent={true}
      visible={modalInfoVisible}
      onRequestClose={() => setModalInfoVisible(false)}
    >
      <Pressable style={perfilStyles.modalContainer}>
        <View style={perfilStyles.modalCardContainer}>
          <View
            style={{ paddingHorizontal: 16, paddingTop: 16, paddingBottom: 24 }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "95%",
              }}
            >
              <Text style={perfilStyles.tituloInfoModal}>
                {textRecurs("general_informacion")}
              </Text>
              <Pressable onPress={() => setModalInfoVisible(false)}>
                <Image
                  source={{ uri: imatgeRecurs("boton_cerrar") }}
                  style={{ width: 13, height: 13 }}
                />
              </Pressable>
            </View>
            <View
              style={{
                flexDirection: "column",
                width: "95%",
                marginTop: 40,
              }}
            >
              <ScrollView>
                {projecte.info1 && (
                  <>
                    <Markdown>{projecte.info1}</Markdown>
                    <View style={perfilStyles.separadorBlanco} />
                  </>
                )}

                {projecte.info2 && (
                  <>
                    <Markdown>{projecte.info2}</Markdown>
                    <View style={perfilStyles.separadorBlanco} />
                  </>
                )}

                {__(projecte, "credits") && (
                  <>
                    <Markdown>{__(projecte, "credits")}</Markdown>
                    <View style={perfilStyles.separadorBlanco} />
                  </>
                )}
              </ScrollView>
            </View>
          </View>
        </View>
      </Pressable>
    </Modal>
  );
};

export default InfoModal;
