import { StyleSheet } from "react-native";
import constants from "@/config/constants";

/* Styles for the component HomeScreen */

const style = StyleSheet.create({
  cardContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    borderRadius: 12,
    width: "85%",
    height: "100%",
    backgroundColor: constants.colorCardBg,
    // marginTop: 32,
    // gap:24,
    paddingBottom: 10,
  },
  card: {
    alignItems: "center",
    flex: 1,
    //backgroundColor: "red",
  },
  scrollView: {
    flex: 3,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
    // backgroundColor: constants.colorCardBg,
    // marginTop: 32,
    // gap:24,
    // paddingTop: 32,
    // paddingBottom:24,
  },
  titleCard: {
    //TODO: FontFamily: Georgia,
    marginTop: 3,
    marginBottom: 2,
    fontSize: 20,
    lineHeight: 24,
    color: constants.colorBlackText,
    fontWeight: "700",
    letterSpacing: 0.64,
  },
  textCard: {
    //TODO: FontFamily: Roboto,
    fontSize: 13,
    lineHeight: 14,
    color: constants.colorBlackText,
    fontWeight: "400",
    letterSpacing: 0.32,
  },
  containerBtn: {
    flex: 0.4,
    width: "60%",
    height: "80%",
    marginTop: 16,
    color: constants.colorWhite,
    justifyContent: "center",
    borderRadius: 6,
    backgroundColor: constants.colorGreenDarkBtn,
  },
  textBtn: {
    color: constants.colorWhite,
    textAlign: "center",
    fontSize: 13,
    fontWeight: "700",
    letterSpacing: 0.8,
    lineHeight: 12,
  },
  textBtnJugar: {
    color: constants.colorWhite,
    textAlign: "center",
    fontSize: 16,
    fontWeight: "700",
  },
  poisProperties: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 5,
    width: "100%",
  },
  smallContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "45%",
    height: "85%",
    borderRadius: 5,
  },
  marginContainer: {
    marginRight: 8,
  },
  guadsContainer: {
    marginTop: 8,
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 5,
    paddingLeft: 12,
    paddingRight: 12,
    width: "100%",
  },
  containerBtnOrange: {
    flex: 0.5,
    height: "70%",
    width: "10%",
    color: constants.colorWhite,
    alignItems: "center",
    justifyContent: "center",
    // paddingVertical: 15,
    // paddingHorizontal: 81,
    borderRadius: 12,
    backgroundColor: constants.colorOrange,
  },

  //##Carousel Styles##

  carouselContainer: {
    flexGrow: 1,
    width: constants.windowWidth,
    paddingBottom: 15,
    //backgroundColor: "purple",
  },
  scroll: {
    alignSelf: "center",
    //backgroundColor: "yellow",
  },
  logoScroll: {
    width: constants.windowWidth,
    height: constants.windowWidth * 0.8, //60%
    resizeMode: "contain",
  },
  containerPagination: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'red',
    paddingHorizontal: 70,
    marginBottom: 20,
  },
  buttonScroll: {
    height: 20,
    width: 20,
    fontSize: 25,
    borderRadius: 20 / 2,
    backgroundColor: "rgba(124, 196, 0, 1)",
    opacity: 0.5,
    margin: 5,
  },
  buttonScrollHighlighted: {
    width: 20,
    height: 20,
    fontSize: 25,
    borderRadius: 20 / 2,
    borderWidth: 2,
    borderColor: constants.colorWhite,
    backgroundColor: constants.colorGreenClear,
    margin: 5,
  },
  // pagination: {

  //     flex:0.1,
  //     width:'50%',
  //     height:'10%',
  //     marginTop:30,
  //     flexDirection: 'row',
  //     justifyContent:'space-between'
  // },
});

export default style;
